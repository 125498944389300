import React, { useEffect, useMemo, useState } from 'react';
import {useDropzone} from 'react-dropzone';
import {Alert, IconButton, List, ListItem, Typography} from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Div from '@jumbo/shared/Div';
import _, { set } from 'lodash';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};
  
const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const FileUploader = ({
        accept="",
        acceptFamily="image",
        maxFiles=1,
        maxSize=3*1000000, // 3MB
        errorMsg="",
        onFileSelection=()=>{}
    }) => {


    const [selFiles, setSelFiles] = useState([]);
    const [showInvalidFile, setShowInvalidFile] = useState(false);
    const [acceptHint, setAcceptHint] = useState("");

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        acceptedFiles,
    } = useDropzone({
        accept: accept,
        maxFiles: maxFiles,
        multiple: maxFiles > 1,
        onDrop: acceptedFiles => {
            var newFiles = _.uniq([...selFiles, ...acceptedFiles])

            if (newFiles.length > maxFiles) {
                var offset = newFiles.length - maxFiles
                newFiles = newFiles.slice(offset)
            }

            setSelFiles(newFiles)
        },
    });
    
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject,
    ]);

    const removeFile = file => () => {
        const newFiles = [...selFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setSelFiles(newFiles)
    }
    
    const removeAll = () => {
        setSelFiles([])
    }

    useEffect(() => {
        if (acceptFamily === "image") {
            accept = "image/*"
            setAcceptHint("imagen")
        } else if (acceptFamily === "video") {
            accept = "video/*"
            setAcceptHint("video")
        } else if (acceptFamily === "audio") {
            accept = "audio/*"
            setAcceptHint("audio")
        } else if (acceptFamily === "spreadsheet") {
            accept = "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            setAcceptHint("hoja de cálculo")
        } else if (acceptFamily === "document") {
            accept = "application/pdf"
            setAcceptHint("PDF")
        } else {
            setAcceptHint("")
        }
    }, [acceptFamily])
    
    useEffect(() => {
        onFileSelection(selFiles)
    }, [selFiles])

    useEffect(() => {
        if (isDragReject && !showInvalidFile) {
            setShowInvalidFile(true);
            // Show message for 3 seconds
            setTimeout(() => {
                setShowInvalidFile(false);
            }, 3000);
        }
    }, [isDragReject])

    const files = selFiles.map(file => (
        <ListItem
            selected
            key={file.path}
            sx={{width: 'auto', mr: 0}}
            secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={removeFile(file)}>
                  <ClearOutlinedIcon />
                </IconButton>
            }>
            {file.path} - {_.round(file.size/1000000.0,2)} MB
        </ListItem>
    ));

    return (
        <div className="container">
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                <p>Suelta aquí tu archivo o haz click para seleccionarlos</p>
            </div>
            { showInvalidFile && <Alert severity="error" sx={{mt: 1}}>Debes cargar máximo {maxFiles} archivo(s) y debe(n) ser de tipo {acceptHint}</Alert> }
            { errorMsg && <Alert severity="error" sx={{mt: 1}}>{errorMsg}</Alert> }
            <List disablePadding sx={{mt: 1}}>
                {files}
            </List>
        </div>
    );
};

export default FileUploader;