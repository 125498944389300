import React, { useEffect } from 'react';
import _ from "lodash";
import { Typography } from '@mui/material';

const OrderTracking = () => {
    return (
        <Typography variant="h1">Seguimiento de mi orden</Typography>
    );
};

export default OrderTracking;