import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {styled} from "@mui/material";

export const StyledDatePicker = styled(DesktopDatePicker)(({ theme }) => ({
    boxShadow: theme.shadows[25],
    background: 'white',
    borderRadius: 4,
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 0, 1, 1),
        transition: theme.transitions.create('width'),
        width: '100%',
        height: 24
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0
    }
}));