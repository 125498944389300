import React, { useCallback, useEffect, useRef, useState } from 'react';
import {ButtonGroup, CircularProgress, Grid, IconButton, List, ListItem, ListItemText, Menu, MenuItem, Pagination, Stack, TextField, Typography} from "@mui/material";
import _, { set } from "lodash";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES, GOOGLE_MAPS_API_KEY } from 'app/utils/constants/paths';
import { DrawingManager, DrawingManagerF, GoogleMap, Marker, OverlayView, Polygon, Polyline, useLoadScript, In, InfoWindow} from "@react-google-maps/api";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { geoCoords, getAssetPath } from 'app/utils/appHelpers';
import deliveryZoneServices from 'app/services/delivery-zone-services';

const GOOGLE_MAPS_LIBS = ["visualization", "places", "drawing"]

const ZonesMapEdit = props => {
    const {zones, currentZone, loading, mapCenter, drawingMode, routesPins, routes=[], hiddenRoutes=[], centres=[], showZones, preview} = props;
    const [currPin, setCurrPin] = useState(null);

    const [center, setCenter] = useState(mapCenter);

    // const isLoaded = false
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        libraries: GOOGLE_MAPS_LIBS
    });
    const mapRef = useRef(null);
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    useEffect(() => {
        if(mapCenter) {
            setCenter(mapCenter)
        }
    }, [mapCenter])

    useEffect(() => {
        if(preview) {
            if(preview.lat && preview.lng) {
                setCenter({ lat: _.toNumber(preview.lat), lng: _.toNumber(preview.lng) })
            }
        } else {
            setCenter(mapCenter)
        }
    }, [preview])

    const optionsPoly = {   
        strokeOpacity: 0.6,
        strokeWeight: 4,
        fillOpacity: 1,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: 30000,
        zIndex: 1
    };

    // useEffect(() => {
    //     window.addEventListener('keydown', ev => { handleKeyPress(ev) });
    // }, [])

    const handleKeyPress = (event) => {
        if(event.key === 'Escape'){
            if(drawingMode?.mode === 'polygon') {
                props.onDrawCancel()
            }
        }
    }

    const onMapDrag = () => {
        const newCenter = mapRef.current.getCenter();
        setCenter({
            lat: newCenter.lat(),
            lng: newCenter.lng()
        });
    };

    const renderZonesPolygons = () => {
        if(showZones) {
            return _.map(zones, (dz, i) => {
                return (
                    <Polygon
                        path={geoCoords(dz.location?.path)}
                        key={`dz_${i}`}
                        onClick={() => props.onPolygonClick(dz)}
                        options={{
                            strokeColor: dz == currentZone ? "#FF0000" : "#0000FF",
                            strokeOpacity: 0.8,
                            strokeWeight: 1,
                            fillColor: dz == currentZone ? "#FF0000" : "#0000FF",
                            fillOpacity: 0.1
                        }}
                    />
                )
            })
        }
    }

    const renderCentresPins = () => {
        return centres.map((centre, i) => {
            if(centre) {
                return (
                    <Marker
                        key={`centre_${i}`}
                        position={{ lat: _.toNumber(centre.location?.lat), lng: _.toNumber(centre.location?.lng) }}
                        icon={getAssetPath(`${ASSET_IMAGES}/map-markers/base.png`)}
                        onClick={() => setCurrPin({ id: `centre_${i}` })}
                        // onMouseOver={() => setCurrPin({ id: `${i}_${j}` })}
                        // onMouseOut={() => setCurrPin(null)}
                    >
                        { (currPin && currPin.id === `centre_${i}`) &&
                            <InfoWindow onCloseClick={() => setCurrPin(null)}>
                                <Stack direction="column" spacing={0.5}>
                                    <Typography variant="body2" color="text.secondary" sx={{fontSize: 10}}>{centre.name}</Typography>
                                </Stack>
                            </InfoWindow>
                        }
                    </Marker>
                )
            }
        })
    }

    const renderPins = () => {
        return (
            routesPins.map((route, i) => {
                return route.pins.map((pin, j) => {
                    return (
                        <Marker
                            key={`pin_${i}_${j}`}
                            label={{
                                text: `${j + 1}`,
                                color: 'white',
                                fontSize: '12px'
                            }}
                            position={{ lat: _.toNumber(pin.lat), lng: _.toNumber(pin.lng) }}
                            icon={{
                                path: "M0 -20C-3.87 -20 -7 -16.87 -7 -13c0 5.25 7 13 7 13s7 -7.75 7 -13c0 -3.87 -3.13 -7 -7 -7z",//window.google.maps.SymbolPath.CIRCLE,
                                strokeColor: route.color,
                                fillOpacity: 1,
                                fillColor: route.color,
                                scale: 1.3,
                                labelOrigin: new window.google.maps.Point(0, -12),
                            }}
                            onClick={() => setCurrPin({ id: `${i}_${j}` })}
                            // onMouseOver={() => setCurrPin({ id: `${i}_${j}` })}
                            // onMouseOut={() => setCurrPin(null)}
                        >
                            { (currPin && currPin.id === `${i}_${j}`) &&
                                <InfoWindow onCloseClick={() => setCurrPin(null)}>
                                    <Stack direction="column" spacing={0.5}>
                                        <Typography variant="body2" color="text.secondary" sx={{fontSize: 10}}>{pin.info}</Typography>
                                    </Stack>
                                </InfoWindow>
                            }
                        </Marker>
                    )
                })
            })
        )
    }

    const renderPreviewPin = () => {
        if(preview && preview?.lat && preview?.lng) {
            return (
                <Marker
                    key={`preview_pin`}
                    label={{
                        text: `i`,
                        color: 'white',
                        fontSize: '14px'
                    }}
                    position={{ lat: _.toNumber(preview.lat), lng: _.toNumber(preview.lng) }}
                    icon={{
                        path: "M0 -20C-3.87 -20 -7 -16.87 -7 -13c0 5.25 7 13 7 13s7 -7.75 7 -13c0 -3.87 -3.13 -7 -7 -7z",//window.google.maps.SymbolPath.CIRCLE,
                        strokeColor: 'blue',
                        fillOpacity: 1,
                        fillColor: 'blue',
                        scale: 1.3,
                        labelOrigin: new window.google.maps.Point(0, -12),
                    }}
                >
                    <InfoWindow>
                        <Stack direction="column" spacing={0.5}>
                            <Typography variant="body2" color="text.secondary" sx={{fontSize: 10}}>{preview.title}</Typography>
                        </Stack>
                    </InfoWindow>
                </Marker>
            )
        }
    }

    const renderRoutes = () => {
        return (
            routes.map((route, i) => {
                if(hiddenRoutes.includes(i)) return null
                return (
                    route.paths.map((path, j) => {
                        if(!routesPins[i]?.color) return null
                        return (
                            <Polyline
                                // onLoad={onLoadPoly}
                                key={`route_${i}_${j}`}
                                path={path}
                                options={{...optionsPoly, strokeColor: routesPins[i]?.color}}
                            />
                        )
                    })
                )
            })
        )
    }

    const renderInfoWindow = () => {
        if(currentZone && currentZone.location?.path) {
            var fp = currentZone.location.path.split(';')[0]?.split(',')
            if(fp.length > 0) {
                return (
                    <InfoWindow
                        position={{ lat: _.toNumber(fp[0]), lng: _.toNumber(fp[1]) }}
                        onCloseClick={props.onCloseInfoWindow}
                    >
                        <Stack direction="row" spacing={1}>
                            <TextField
                                fullWidth
                                error={false}
                                variant="standard"
                                value={currentZone.name}
                                onChange={props.onZoneNameChange}
                            />
                            <ButtonGroup variant="outlined" size="small">
                                <IconButton onClick={props.onSaveZoneClick} color="success"><SaveOutlinedIcon/></IconButton>
                                <IconButton onClick={props.onDeleteZoneClick} color="error"><DeleteOutlineOutlinedIcon/></IconButton>
                            </ButtonGroup>
                        </Stack>
                    </InfoWindow>
                )
            }
        }
    }

    return (
        <React.Fragment>
            { isLoaded ?
                <GoogleMap mapContainerStyle={{ width: '100%', height: "100%", borderRadius: 12 }}
                    center={center}
                    onLoad={onMapLoad}
                    onDragEnd={onMapDrag}
                    zoom={10}>
                    {
                        <React.Fragment>
                            {renderCentresPins()}
                            {renderRoutes()}
                            {renderPins()}
                            {renderZonesPolygons()}
                            {renderInfoWindow()}
                            {renderPreviewPin()}
                            <DrawingManager
                                onOverlayComplete={props.onOverlayComplete}
                                options={{
                                    drawingMode: drawingMode?.mode,
                                    drawingControl: true,
                                    drawingControlOptions: {
                                        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                                        position: window.google.maps.ControlPosition.TOP_CENTER,
                                        drawingModes: [
                                            window.google.maps.drawing.OverlayType.POLYGON
                                        ]
                                    },
                                    polygonOptions: {
                                        fillColor: "#199ee0",
                                        fillOpacity: 0.2,
                                        strokeWeight: 2,
                                        strokeColor: "#113460",
                                        clickable: true,
                                        editable: true,
                                        geodesic: false,
                                        visible: true,
                                        zIndex: 1
                                    }
                                }}
                            />
                        </React.Fragment>
                    }
                </GoogleMap>

                :
                <Div sx={{textAlign: 'center', my: 5}}>
                    <CircularProgress color="warning"/>
                </Div>
            }
        </React.Fragment>
    )
}

export default ZonesMapEdit;