import React from 'react';
import _ from "lodash";
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import EditCompany from './EditCompany';


const CompanySettings = () => {
    const {authUser} = useJumboAuth();

    return (
        <div>
            { authUser && <EditCompany company_id={authUser?.current_company?.id} /> }
        </div>
    );
};

export default CompanySettings;