import TripListCard from 'app/pages/trips/TripListCard';
import React, { useEffect, useState } from 'react';
import {CircularProgress, Grid, IconButton, Menu, MenuItem, Pagination, Stack, TextField, Typography} from "@mui/material";
import _ from "lodash";
import Div from "@jumbo/shared/Div";
import RouteBuilder from "./RouteBuilder";
import DeliveryDetails from "app/pages/trips/DeliveryDetails";
import DriverForm from "app/pages/drivers/DriverForm";
import tripServices from "app/services/trip-services";
import StateBadge from 'app/widgets/Shared/StateBadge/StateBadge';
import {Search, SearchIconWrapper, StyledInputBase} from "../../shared/SearchGlobal/style";
import SearchIcon from "@mui/icons-material/Search";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import DeliveryScheduleForm from 'app/pages/trips/DeliveryScheduleForm';
import CustomPagination from 'app/widgets/Shared/Pagination/CustomPagination';
import deliveryServices from 'app/services/delivery-services';
import ImageViewer from 'app/widgets/Images/ImageViewer';
import { StyledDatePicker } from 'app/widgets/Shared/StyledDatePicker/StyledDatePicker';
import { downloadBuffer } from 'app/utils/appHelpers';


const TripsIndex = () => {    
    const [tripList, setTrips] = useState([])
    const [listLoading, setListLoading] = useState(false)
    const [currentTrip, setCurrentTrip] = useState(null)
    const [currentDelivery, setCurrentDelivery] = useState(null)
    const [currentDeliveryDocsImgs, setCurrentDeliveryDocsImgs] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [routeBuilderIsOpen, setRouteBuilderIsOpen] = useState(false)
    const [deliveryDetailsIsOpen, setDeliveryDetailsIsOpen] = useState(false)
    const [deliveryDocsImgIsOpen, setDeliveryDocsImgIsOpen] = useState(false)
    const [driverFormIsOpen, setDriverFormIsOpen] = useState(false)
    const [deliveryScheduleFormIsOpen, setDeliveryScheduleFormIsOpen] = useState(false)
    const [viewMoreId, setViewMoreId] = useState(null)
    const [filterState, setFilterState] = useState(null)
    const [searchQuery, setSearchQuery] = useState("")
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [fileDownloadExt, setFileDownloadExt] = useState(null);
    const [anchorFilterMenu, setAnchorFilterMenu] = useState(null);
    const filterMenuOpen = Boolean(anchorFilterMenu);
    
    useEffect(() => {
        if(listLoading){
            loadTrips()
        }
    }, [listLoading])

    useEffect(() => {
        setListLoading(true)
    }, [])

    const loadTrips = () => {
        tripServices.getTrips(buildTripParams(), fileDownloadExt ? true : false)
            .then(data => {
                if(fileDownloadExt === 'xlsx') {
                    downloadBuffer(data, 'ejemplo.xlsx')
                    setFileDownloadExt(null)
                } else {
                    setTotalPages(data.meta.total_pages)
                    setCurrentPage(data.meta.current_page)
                    setTrips(data.data)
                }
                setListLoading(false)
            })
            .catch((err) => {
                setListLoading(false)
                setFileDownloadExt(null)
            });
    }

    const buildTripParams = () => {
        let par = []
        if(filterState) {
            par.push(`q%5Bstate_eq%5D=${filterState}`)
        }
        if(searchQuery && searchQuery !== 0) {
            par.push(`q%5Bcode_or_deliveries_code_or_deliveries_order_code_or_deliveries_delivery_details_product_or_deliveries_locatable_of_Customer_type_name_or_deliveries_locatable_of_Place_type_name_or_vehicle_transport_unit1_ppu_or_vehicle_transport_unit2_ppu_or_driver_first_name_or_driver_last_name_or_vehicle_transport_unit1_company_name_cont%5D=${searchQuery}`)
        }
        if(startDate) {
            par.push(`q%5Bship_date_gteq%5D=${startDate}`)
        }
        if(endDate) {
            par.push(`q%5Bship_date_lteq%5D=${endDate}`)
        }
        if(fileDownloadExt) {
            par.push(`format=${fileDownloadExt}`)
        }
        par.push(`page=${currentPage}`)
        par.push(`include=driver,vehicle.company,vehicle.transport_unit1,vehicle.transport_unit2,vehicle.centre.main_centre_zone,deliveries.receipt,deliveries.survey_answers.survey_question,deliveries.order,deliveries.docs_images_attachments,deliveries.locatable.location,deliveries.delivery_details.images_attachments,deliveries.delivery_details.order_detail,deliveries.delivery_docs.shipping_doc.shipping_doc_kind`)
        par = _.join(par, '&')

        return `?${par}`;
    }

    const handleOnCloseRouteBuilder = (changesApplied) => {
        if(changesApplied) {
            setListLoading(true)
        }
        setRouteBuilderIsOpen(false)
        setCurrentTrip(null)
    }

    const handleOnCloseDeliveryDetails = () => {
        setDeliveryDetailsIsOpen(false)
    }

    const handleOnCloseDriverForm = () => {
        setDriverFormIsOpen(false)
        setCurrentTrip(null)
    }

    const handleOnSaveDriverForm = (driver) => {
        const newTripList = _.map(tripList, (t) => {
            if(t.driver.id === driver.id) {
                t.driver = driver
            }
            return t;
        });
        setTrips(newTripList);
    }

    const handleFilterState = (newState) => {
        var finalSt = newState === filterState ? null : newState
        setFilterState(finalSt);
        setListLoading(true);
    };

    const handleSearchQueryChange = (newVal) => {
        setSearchQuery(newVal)
        setListLoading(true)
    }

    const handleDateChanges = (kind, newVal) => {
        if(kind === 'start') {
            setStartDate(newVal)
        } else {
            setEndDate(newVal)
        }
        setListLoading(true)
    }

    const handleFileDownload = () => {
        setAnchorFilterMenu(null)
        setFileDownloadExt('xlsx')
        setListLoading(true)
    }

    const debounceSearchQueryChange = _.debounce(handleSearchQueryChange, 450)

    const renderFilters = () => {
        const statesList = [
            { name: 'planned',      stateName: 'Planificado'},
            { name: 'pending',      stateName: 'Pendiente'},
            { name: 'in_progress',  stateName: 'En progreso'},
            { name: 'finished',     stateName: 'Finalizado'}
        ]
        return (
            <Grid container sx={{mb: 2}} spacing={1}>
                <Grid item xs={12} md={6}>
                    {_.map(statesList, (st, i) => {
                        return (
                            <StateBadge
                                state={st.name}
                                stateName={st.stateName}
                                clickable={true}
                                key={`filter_state_${i}`}
                                sx={{mr: 1, mb: 1}}
                                variant={filterState === st.name ? 'filled' : 'outlined'}
                                onClick={() => { handleFilterState(st.name) }} />
                        )
                    })}
                </Grid>
                
                <Grid item xs={12} md={3} sx={{mb: 1}}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}>
                        <LocalizationProvider size="small" dateAdapter={AdapterDateFns}>
                            <StyledDatePicker
                                label="Desde"
                                format="dd/MM/yyyy"
                                sx={{ background: 'white' }}
                                value={startDate}
                                maxDate={endDate}
                                closeOnSelect={false}
                                slotProps={{
                                    actionBar: {
                                        actions: ['clear', 'accept'],
                                    },
                                }}
                                onAccept={(newValue) => handleDateChanges('start', newValue)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <StyledDatePicker
                                label="Hasta"
                                format="dd/MM/yyyy"
                                sx={{ background: 'white' }}
                                value={endDate}
                                minDate={startDate}
                                closeOnSelect={false}
                                slotProps={{
                                    actionBar: {
                                        actions: ['clear', 'accept'],
                                    },
                                }}
                                onAccept={(newValue) => handleDateChanges('end', newValue)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Stack>
                </Grid>

                <Grid item xs={12} md={3}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}>
                        <Search sx={{maxWidth: { sm: 200, md: 350 }}}>
                            <SearchIconWrapper>
                                <SearchIcon/>
                            </SearchIconWrapper>

                            <StyledInputBase
                                placeholder="Buscar"
                                inputProps={{'aria-label': 'search'}}
                                sx={{background: 'white'}}
                                onChange={(e) => {
                                    debounceSearchQueryChange(e.target.value)
                                }}
                            />
                        </Search>
                        <Div>
                            <IconButton
                                id="basic-button"
                                aria-controls={filterMenuOpen ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={filterMenuOpen ? 'true' : undefined}
                                onClick={(event) => setAnchorFilterMenu(event.currentTarget)}
                            >
                                <FileDownloadOutlinedIcon />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorFilterMenu}
                                open={filterMenuOpen}
                                onClose={() => setAnchorFilterMenu(null)}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={() => handleFileDownload()}>Descargar .xlsx</MenuItem>
                            </Menu>
                        </Div>
                    </Stack>
                </Grid>
            </Grid>
        )
    }

    const loadDeliveryDocsImgs = (delivery) => {
        setCurrentDeliveryDocsImgs([])
        deliveryServices.getDocsImages(delivery?.id)
            .then(data => {
                setCurrentDeliveryDocsImgs(data)
                setDeliveryDocsImgIsOpen(true)
            })
    }

    const handleTripChange = (trip) => {
        const newTripList = _.map(tripList, (t) => {
            if(t.id === trip.id) {
                t.driver = trip.driver
                t.vehicle = trip.vehicle
                t.transportation_company = trip.transportation_company
            }
            return t;
        });
        setTrips(newTripList);
    }

    const renderTripList = () => {
        return (
            <div>
                {_.map(tripList, (t) => {
                    return (
                        <TripListCard
                            key={t.id}
                            trip={t}
                            viewMoreActive={viewMoreId === t.id}
                            onTripFocus={(trip) => { setCurrentTrip(trip); setRouteBuilderIsOpen(true); }}
                            onViewMore={(trip) => { setViewMoreId(viewMoreId === trip.id ? null : trip.id) }}
                            onDeliveryFocus={(delivery) => { setCurrentDelivery(delivery); setDeliveryDetailsIsOpen(true); }}
                            onDriverEdit={(trip) => { setCurrentTrip(trip); setDriverFormIsOpen(true); }}
                            onScheduledTimeEdit={(delivery) => { setCurrentDelivery(delivery); setDeliveryScheduleFormIsOpen(true); }}
                            onDocsImagesView={(delivery) => { loadDeliveryDocsImgs(delivery);  }}
                            onTripChange={handleTripChange}/>
                    )
                })}
            </div>
        )
    }
    
    return (
        <div>
            <Typography variant="h2">Viajes</Typography>
            {renderFilters()}
            <RouteBuilder key={`rb-${currentTrip?.id}`} trip={currentTrip} isOpen={routeBuilderIsOpen} onClose={(changesApplied) => { handleOnCloseRouteBuilder(changesApplied)} } />
            <DeliveryDetails key={currentDelivery?.id} delivery={currentDelivery} isOpen={deliveryDetailsIsOpen} onClose={handleOnCloseDeliveryDetails} />
            <DriverForm key={currentTrip?.driver} trip={currentTrip} isOpen={driverFormIsOpen} onClose={handleOnCloseDriverForm} onSave={handleOnSaveDriverForm} />
            <DeliveryScheduleForm
                key={`dsf-${currentDelivery?.id}`}
                delivery={currentDelivery}
                isOpen={deliveryScheduleFormIsOpen}
                onSave={()=> { setDeliveryScheduleFormIsOpen(false) }}
                onClose={()=> { setDeliveryScheduleFormIsOpen(false) }} />
            <ImageViewer
                isOpen={deliveryDocsImgIsOpen}
                onClose={() => setDeliveryDocsImgIsOpen(false)}
                images={currentDeliveryDocsImgs} />
            <Div sx={{display: listLoading ? 'block' : 'none', textAlign: 'center', my: 5}}>
                <CircularProgress color="warning"/>
            </Div>
            {renderTripList()}
            <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageSelect={(page) => {setCurrentPage(page); setListLoading(true)}}/>
        </div>
    );
};

export default TripsIndex;