import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, ButtonGroup, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, List, Slider, TextField, Typography } from "@mui/material";
import _ from "lodash";
import { geoCoords } from "app/utils/appHelpers";
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Div from "@jumbo/shared/Div";
import { Stack } from "@mui/system";
import { Autocomplete, DrawingManager, GoogleMap, Polygon, Marker, OverlayView, useLoadScript } from "@react-google-maps/api";
import ConfirmableItemList from "app/shared/widgets/ConfirmableItemList";
import CustomPagination from 'app/widgets/Shared/Pagination/CustomPagination';
import customerServices from 'app/services/customer-services';
import {Search, SearchIconWrapper, StyledInputBase} from "../../shared/SearchGlobal/style";
import SearchIcon from "@mui/icons-material/Search";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import LocationOffOutlinedIcon from '@mui/icons-material/LocationOffOutlined';
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import { GOOGLE_MAPS_API_KEY } from 'app/utils/constants/paths';
import moment from 'moment';

const GOOGLE_MAPS_LIBS = ["visualization", "places", "drawing"]

const CustomersIndex = () => {    
    const [mapCenter, setMapCenter] = useState({
        lat: -33.5136445,
        lng: -70.7323127,
    })
    const [customersList, setCustomersList] = useState([])
    const [listLoading, setListLoading] = useState(false)
    const [customerLoading, setCustomerLoading] = useState(false)
    const [openLocDialog, setOpenLocDialog] = useState(false)
    const [openContDialog, setOpenContDialog] = useState(false)
    const [currentCustomer, setCurrentCustomer] = useState(null)
    const [currCustomerDetails, setCurrCustomerDetails] = useState(null)
    const [currCustomerRecipients, setCurrCustomerRecipients] = useState([])
    const [currCustomerGeofence, setCurrCustomerGeofence] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [canSave, setCanSave] = useState(false)
    const [confiabilityRange, setConfiabilityRange] = useState([0, 100])
    const [searchQuery, setSearchQuery] = useState("")
    const [searchResult, setSearchResult] = useState("")
    const [showPins, setShowPins] = useState(true)
    const [receiptsPins, setReceiptsPins] = useState([])
    const [tempPin, setTempPin] = useState(null)

    const newRecipients = useRef('');
    
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        libraries: GOOGLE_MAPS_LIBS
    });

    useEffect(() => {
        if(listLoading){
            loadCustomers()
        }
    }, [listLoading])

    useEffect(() => {
        setListLoading(true)
    }, [])

    const buildCustomersParams = () => {
        let par = []
        let pos = 0
        par.push(encodeURI(`q[m]=and`))
        if(searchQuery && searchQuery !== 0) {
            par.push(encodeURI(`q[g][${pos}][name_or_code_cont]=${searchQuery}`))
            pos += 1
        }
        if(confiabilityRange && confiabilityRange.length === 2) {
            // par.push(`q%5B1%5D%5Blocation_query_score_not_lt%5D=${confiabilityRange[0]/100.0}`)
            // par.push(`q%5B1%5D%5Blocation_query_score_not_lt%5D=${confiabilityRange[0]/100.0}`)
            let pos_i = 0
            par.push(encodeURI(`q[g][${pos}][m]=or`))
            if(confiabilityRange[0] <= 0){
                par.push(encodeURI(`q[g][${pos}][g][${pos_i}][location_query_score_null]=1`))
                pos_i += 1
            }
            par.push(encodeURI(`q[g][${pos}][g][${pos_i}][m]=and`))
            par.push(encodeURI(`q[g][${pos}][g][${pos_i}][location_query_score_gteq]=${confiabilityRange[0]/100.0}`))
            par.push(encodeURI(`q[g][${pos}][g][${pos_i}][location_query_score_lteq]=${confiabilityRange[1]/100.0}`))
            pos += 1
        }

        par.push(`page=${currentPage}`)
        par.push(`include=location,recipients`)

        par = _.join(par, '&')

        return `?${par}`;
    }

    function onLoad(autocomplete) {
        setSearchResult(autocomplete);
    }

    const handleSearchQueryChange = (newVal) => {
        setCurrentPage(1)
        setSearchQuery(newVal)
        setListLoading(true)
    }

    function onPlaceChanged() {
        if (searchResult != null) {
          const place = searchResult.getPlace();
          if(place?.geometry?.location?.lat()) {
            var latlng = {
                lat: place?.geometry?.location?.lat(),
                lng: place?.geometry?.location?.lng(),
            }
            setTempPin(latlng)
            setMapCenter(latlng)
          }
        } else {
          alert("Please enter text");
        }
    }

    const debounceSearchQueryChange = _.debounce(handleSearchQueryChange, 450)
    
    const loadCustomers = () => {
        customerServices.getCustomers(buildCustomersParams())
            .then(data => {
                setCustomersList(data.data)
                setTotalPages(data.meta.total_pages)
            })
            .catch(function (error) {})
            .finally(() => {
                setListLoading(false)
            });
    }

    const getCustomerDetails = (customer) => {
        if(currentCustomer?.id != customer?.id) {
            setCanSave(false)
            setCurrCustomerDetails(null)
            setCurrCustomerRecipients([])
            setCustomerLoading(true)

            customerServices.getCustomer(customer?.id, "include=location,recipients")
                .then(response => {
                    setCurrCustomerDetails(response)
                    setCurrCustomerRecipients(_.map(response.recipients, 'email'))
                    setCurrCustomerGeofence(response.location?.path)
                    
                    if(response.location?.path) {
                        var gc = geoCoords(response.location?.path)
                        setMapCenter(gc[0])
                    } else {
                        setMapCenter({
                            lat: -33.5136445,
                            lng: -70.7323127,
                        })
                    }

                    customerServices.getReceiptsPins(customer?.id)
                        .then(resp => {
                            setReceiptsPins(resp)
                        })
                        .finally(() => {
                            setCustomerLoading(false)
                        });
                })
                .catch(function (error) {})
                .finally(() => {
                    setCustomerLoading(false)
                });
        }
    }

    const saveCustomerRecipients = (customer, recipients) => {
        setCustomerLoading(true)
        customerServices.saveRecipients(customer.id, { recipients: recipients, include: "location,recipients" })
            .then(response => {
                setCurrCustomerDetails(response)
                setCurrCustomerRecipients(_.map(response.recipients, 'email'))
                loadCustomers()
            })
            .catch(function (error) {})
            .finally(() => {
                setCustomerLoading(false)
            });
    }

    const saveCustomerGeofence = (customer, strGeo) => {
        setCustomerLoading(true)
        customerServices.saveCustomerGeofence(customer.id, { geoPath: strGeo })
            .then(response => {
                setCurrCustomerDetails(response.data)
                loadCustomers()
            })
            .catch(function (error) {})
            .finally(() => {
                setCustomerLoading(false)
                setOpenLocDialog(false)
            })
    }

    const onPolygonComplete = polygon => {
        if(polygon.type == 'polygon') {
            var coordStr = "";
            for (var i = 0; i < polygon.overlay.getPath().getLength(); i++) {
                coordStr += polygon.overlay.getPath().getAt(i).toUrlValue(6) + ";";
            }
            setCurrCustomerGeofence(coordStr)
            setCanSave(true)
        }
        polygon.overlay.setMap(null)
    }

    const setCentreOnCustomer = () => {
        if(currCustomerGeofence) {
            var firstPoint = _.split(currCustomerGeofence, ';')[0]
            if(firstPoint) {
                var newCenter = {
                    lat: _.toNumber(_.split(firstPoint, ',')[0]),
                    lng: _.toNumber(_.split(firstPoint, ',')[1])
                }
                setMapCenter(newCenter)
            }
        }
    }

    const renderLocationDialog = () => {
        return(
            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={openLocDialog}
                onClose={() => setOpenLocDialog(false)}>
                <DialogTitle>
                    <Stack
                        direction="column"
                        justifyContent="start"
                        alignItems="left"
                        spacing={0}>
                        <Typography variant={"overline"} color="primary">{currentCustomer?.code}</Typography>
                        <Typography variant={"button"} color="primary">{currentCustomer?.name}</Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    { isLoaded &&
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={8}>
                                <Div sx={{display: !isLoaded ? 'block' : 'none', textAlign: 'center', my: 5}}>
                                    <CircularProgress color="warning"/>
                                </Div>
                                <GoogleMap
                                    mapContainerStyle={{ width: '100%', height: "400px" }}
                                    center={mapCenter}
                                    zoom={16}>
                                    {
                                        <React.Fragment>
                                            {currCustomerGeofence &&
                                                <Polygon
                                                    path={geoCoords(currCustomerGeofence)}
                                                    key={currCustomerGeofence}
                                                    onRightClick={(el)=> { return setCurrCustomerGeofence(null) }}
                                                    options={{
                                                        strokeColor: "#FF0000",
                                                        strokeOpacity: 0.8,
                                                        strokeWeight: 2,
                                                        fillColor: "#FF0000",
                                                        fillOpacity: 0.35
                                                    }}
                                                />
                                            }
                                            {(receiptsPins && showPins) &&
                                                <React.Fragment>
                                                    {_.map(receiptsPins, (p) => {
                                                        return(
                                                            <React.Fragment>
                                                                <Marker position={{lat: _.toNumber(p.lat), lng: _.toNumber(p.lng)}}></Marker>
                                                                <OverlayView
                                                                    key='mwl'
                                                                    position={{lat: _.toNumber(p.lat), lng: _.toNumber(p.lng)}}
                                                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                                                    >
                                                                        <div style={{
                                                                            background: `#203254`,
                                                                            padding: `4px 8px`,
                                                                            fontSize: '12px',
                                                                            color: `white`,
                                                                            borderRadius: '4px',
                                                                            }}>
                                                                            {moment(p.completed_at).format('DD MMM HH:mm')}
                                                                        </div>
                                                                </OverlayView>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                    { tempPin && <Marker
                                                        key={`preview_pin`}
                                                        label={{
                                                            text: `i`,
                                                            color: 'white',
                                                            fontSize: '14px'
                                                        }}
                                                        position={tempPin}
                                                        icon={{
                                                            path: "M0 -20C-3.87 -20 -7 -16.87 -7 -13c0 5.25 7 13 7 13s7 -7.75 7 -13c0 -3.87 -3.13 -7 -7 -7z",//window.google.maps.SymbolPath.CIRCLE,
                                                            strokeColor: 'blue',
                                                            fillOpacity: 1,
                                                            fillColor: 'blue',
                                                            scale: 1.3,
                                                            labelOrigin: new window.google.maps.Point(0, -12),
                                                        }}
                                                    ></Marker>}
                                                </React.Fragment>
                                            }
                                            {!currCustomerGeofence &&
                                                <DrawingManager
                                                    defaultDrawingMode='polygon'
                                                    drawingMode='polygon'
                                                    // onLoad={onLoad}
                                                    onOverlayComplete={onPolygonComplete}
                                                    defaultOptions={{
                                                        drawingControl: true,
                                                        drawingControlOptions: {
                                                            position: 'TOP_RIGHT',
                                                            drawingModes: [
                                                                // window.google.maps.drawing.OverlayType.CIRCLE,
                                                                window.google.maps.drawing.OverlayType.POLYGON,
                                                                // window.google.maps.drawing.OverlayType.POLYLINE,
                                                                // window.google.maps.drawing.OverlayType.RECTANGLE
                                                            ]
                                                        },
                                                        polygonOptions: {
                                                            editable:true
                                                        }
                                                    }}
                                                />
                                            }
                                        </React.Fragment>
                                    }
                                </GoogleMap>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <ButtonGroup orientation="vertical" size="small" sx={{width: '100%', mb: 2}}>
                                    <Button
                                        color={currCustomerGeofence ? 'primary' : 'primary'}
                                        disabled={receiptsPins.length == 0}
                                        onClick={() => { setShowPins(!showPins) }}>
                                        { (showPins && receiptsPins.length > 0) ?
                                            <React.Fragment><LocationOffOutlinedIcon /> Esconder pins ({receiptsPins.length})</React.Fragment>
                                        :
                                            <React.Fragment><PlaceOutlinedIcon /> Mostrar pins ({receiptsPins.length})</React.Fragment>
                                        }
                                    </Button>
                                    <Button
                                        color={currCustomerGeofence ? 'primary' : 'primary'}
                                        disabled={!currCustomerGeofence}
                                        onClick={() => { setCentreOnCustomer(); }}>
                                        <MyLocationOutlinedIcon /> Centrar geocerca
                                    </Button>
                                    <Button
                                        color={currCustomerGeofence ? 'error' : 'primary'}
                                        disabled={!currCustomerGeofence}
                                        onClick={() => { setCurrCustomerGeofence(null) }}>
                                        <ClearOutlinedIcon /> Eliminar geocerca
                                    </Button>
                                </ButtonGroup>

                                <Typography sx={{mb: 1}}>Buscar ubicación:</Typography>
                                <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
                                    <input
                                        type="text"
                                        placeholder="Ingresa una dirección o lugar"
                                        style={{
                                            boxSizing: `border-box`,
                                            border: `1px solid transparent`,
                                            width: `240px`,
                                            height: `32px`,
                                            padding: `0 12px`,
                                            borderRadius: `3px`,
                                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                            fontSize: `14px`,
                                            outline: `none`,
                                            textOverflow: `ellipsis`,
                                            width: '100%',
                                        }}
                                    />
                                </Autocomplete>
                            </Grid>
                        </Grid>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setCanSave(false); setOpenLocDialog(false)} }>Cerrar</Button>
                    <Button variant="contained" color={ canSave ? 'success' : 'primary' } onClick={() => saveCustomerGeofence(currentCustomer, currCustomerGeofence)} >Guardar</Button>
                </DialogActions>
            </Dialog>
        )

    }

    const renderRecipientsList = () => {
        if(currCustomerRecipients?.length <= 0) {
            return <Typography>No hay registros</Typography>
        } else {
            return (
                <React.Fragment>
                    <List dense={true}>
                        {_.map(currCustomerRecipients, (recipient) => {
                            return <ConfirmableItemList key={recipient} item={recipient} onTrash={() => removeRecipients(recipient) } />
                        })}
                    </List>
                </React.Fragment>
            )
        }
    }

    const addRecipients = () => {
        setCurrCustomerRecipients(_.union(currCustomerRecipients, _.split(newRecipients.current.value, ';')))
        newRecipients.current.value = []
    }

    const removeRecipients = (el) => {
        setCurrCustomerRecipients(_.without(currCustomerRecipients, el))
    }

    const renderRecipientsDialog = () => {
        return(
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={openContDialog}
                onClose={() => { setOpenContDialog(false); setCurrCustomerDetails(null); setCurrCustomerRecipients([]); }}>
                <DialogTitle>
                    <Stack
                        direction="column"
                        justifyContent="start"
                        alignItems="left"
                        spacing={0}>
                        <Typography variant={"overline"} color="primary">{currentCustomer?.code}</Typography>
                        <Typography variant={"button"} color="primary">{currentCustomer?.name}</Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Seleccione los usuarios que desea que reciban notificaciones relacionadas a este cliente. Para agregar múltiples destinatarios, ingrese los correos separados con punto y coma (';'). Ej: "correo1@dominio.cl; correo2@dominio.cl".
                    </DialogContentText>
                    <Grid item xs={12}>
                        <Typography sx={{mt: 4, mb: 2}} variant="h5" component="div">
                            Recipientes
                        </Typography>
                        <Div sx={{display: customerLoading ? 'block' : 'none', textAlign: 'center', my: 5}}>
                            <CircularProgress color="warning"/>
                        </Div>
                        {currCustomerRecipients && renderRecipientsList()}
                        <Stack
                            direction="row"
                            justifyContent="start"
                            alignItems="bottom"
                            spacing={0}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Agregar nuevo(s) correo(s)"
                                type="email"
                                fullWidth
                                variant="standard"
                                inputRef={newRecipients}
                                sx={{mt: 2}} />
                            <Button onClick={() => addRecipients() }>Agregar</Button>
                        </Stack>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenContDialog(false)}>Cerrar</Button>
                    <Button onClick={() => saveCustomerRecipients(currentCustomer, currCustomerRecipients)}>Guardar</Button>
                </DialogActions>
            </Dialog>
        )

    }

    const renderCustomersList = () => {
        if(customersList){
            return (
                <React.Fragment>
                    {renderLocationDialog()}
                    {renderRecipientsDialog()}
                    <List disablePadding>
                        {_.map(customersList, (c, i) => {
                            return (
                                <Card sx={{mb: 2}} key={c.id}>
                                    <CardContent sx={{display: 'flex'}}>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} md={4}>
                                                <Stack
                                                    direction="column"
                                                    justifyContent="start"
                                                    alignItems="left"
                                                    spacing={0.5}>
                                                    <Typography variant={"overline"} color="primary">{c.code}</Typography>
                                                    <Typography variant={"button"} color="primary">{c.name}</Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Stack
                                                    direction="column"
                                                    justifyContent="start"
                                                    alignItems="left"
                                                    spacing={0.5}>

                                                    <Typography variant={"overline"}>Ubicación {c.location?.query_score ? `(${_.round(c.location.query_score * 100)}%)` : ''}</Typography>
                                                    <Typography variant={"body"}>{c.location?.short_address}</Typography>
                                                    <Typography variant={"body"}>{c.location?.short_zone}</Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={4} style={{height: '100%'}}>
                                                <Stack
                                                    direction="row"
                                                    justifyContent="end"
                                                    alignItems="center"
                                                    spacing={0.5}
                                                    style={{height: '100%'}}>
                                                        <Typography>
                                                            Nº Desp: {c.deliveries_count}
                                                        </Typography>
                                                        <IconButton color={c.location?.path ? 'success' : 'error'} onClick={() => { setCurrentCustomer(c); getCustomerDetails(c); setOpenLocDialog(true); setTempPin(null); }}>
                                                            <PlaceOutlinedIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => { setCurrentCustomer(c); getCustomerDetails(c); setOpenContDialog(true); }}>
                                                            <Badge badgeContent={c.recipients?.length || 0} color="primary" showZero max={9}>
                                                                <EmailOutlinedIcon color="action" />
                                                            </Badge>
                                                        </IconButton>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            )
                        })}
                    </List>
                </React.Fragment>
            )
        } else {
            return(
                <Typography>
                    No se encontraron datos
                </Typography>
            )
        }
    }

    const confiabilityText = (value) => {
        return `${value}%`;
    };
    
    return (
        <div>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={3}
                sx={{mb: 2}}>
                <Typography variant="h2">Clientes</Typography>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={3}>
                    <Div sx={{ width: 300, maxWidth: 300 }}>
                        <Typography id="confiability-slider" sx={{mb: 0}} >
                            Confiabilidad ubicación
                        </Typography>
                        <Slider
                            size="small"
                            aria-labelledby="confiability-slider"
                            value={confiabilityRange}
                            onChange={(ev, val) => { setConfiabilityRange(val) }}
                            onChangeCommitted={(ev, val) => { setCurrentPage(1); setConfiabilityRange(val); setListLoading(true); }}
                            getAriaValueText={confiabilityText}
                            step={1}
                            marks={_.map([0,20,40,60,80,100], (v) => { return { value: v, label: confiabilityText(v) }})}
                            valueLabelDisplay="auto"
                        />
                    </Div>
                    
                    <Search sx={{maxWidth: { sm: 200, md: 350 }}}>
                        <SearchIconWrapper>
                            <SearchIcon/>
                        </SearchIconWrapper>

                        <StyledInputBase
                            placeholder="Buscar"
                            inputProps={{'aria-label': 'search'}}
                            sx={{background: 'white'}}
                            onChange={(e) => {
                                debounceSearchQueryChange(e.target.value)
                            }}
                            onKeyDown={(e) => {
                                if (e.key == 'Enter') {
                                    handleSearchQueryChange(e.target.value)
                                }
                            }}
                        />
                    </Search>
                </Stack>
            </Stack>

            <Div sx={{display: listLoading ? 'block' : 'none', textAlign: 'center', my: 5}}>
                <CircularProgress color="warning"/>
            </Div>
            <Grid container spacing={3.75}>
                <Grid item xs={12}>
                    {renderCustomersList()}
                </Grid>
            </Grid>
            <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageSelect={(page) => { setCurrentPage(page); setListLoading(true)}}/>
        </div>
    );
};

export default CustomersIndex;