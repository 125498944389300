import React, { useState } from 'react';
import { Marker } from "@react-google-maps/api";
import { getAssetPath } from 'app/utils/appHelpers';
import { ASSET_IMAGES } from 'app/utils/constants/paths';

const CustomMarker = (props) => {
    const [dialogPosition, setDialogPosition] = useState({top: 0, left: 0})
    const {id, lat, lng, kind} = props;

    // const MarkerDetails = () => {
    //     return (
    //         <Div sx={{display: 'absolute', width: '100px', top: dialogPosition.top, left: dialogPosition.left}}>
                
    //         </Div>
    //     )
    // }

    const markerImg = () => {
        if(kind === 'base') {
            return `base.png`
        } else if(kind === 'in_progress') {
            return `delivery-in-progress.png`
        } else if(kind === 'finished') {
            return `delivery-ended.png`
        } else {
            return `delivery-pending.png`
        }
    }

    // const onMarkerClick = (evt) => {
    // };
    const onMarkerOver = (evt) => {
    };

    return (
        <React.Fragment>
            <Marker
                // onClick={onMarkerClick}
                icon={getAssetPath(`${ASSET_IMAGES}/map-markers/${markerImg()}`)}
                position={{ lat: lat, lng: lng }}
                onMouseOver={onMarkerOver}
                {...props}
            />
        </React.Fragment>
    );
};

export default CustomMarker;