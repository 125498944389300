import React from 'react';
import {Button, ButtonGroup, IconButton, Pagination, Stack, Tooltip} from "@mui/material";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import _ from 'lodash';

const CustomPagination = props => {
    if(props.compact){
        return(
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center">
                    <Tooltip title={'Anterior'}>
                        <IconButton
                            color='primary'
                            disabled={ props.currentPage === 1 }
                            onClick={(e) => { props.onPageSelect(_.max([props.currentPage - 1, 0])) }}>
                            <ArrowBackIosNewOutlinedIcon fontSize='8'/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Siguiente'}>
                        <IconButton
                            color='primary'
                            disabled={ props.currentPage === props.totalPages }
                            onClick={(e) => { props.onPageSelect(_.min([props.currentPage + 1, props.totalPages])) }}>
                            <ArrowBackIosNewOutlinedIcon fontSize='8' sx={{transform: 'rotate(180deg)'}}/>
                        </IconButton>
                    </Tooltip>
            </Stack>
        )
    } else {
        return(
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{my: 3}}>
                <Pagination
                    count={props.totalPages}
                    color='primary'
                    page={props.currentPage}
                    onChange={(event, value) => { props.onPageSelect(value) }}
                />
            </Stack>
        )
    }
}

export default CustomPagination;