import React from "react";
import Page from "@jumbo/shared/Page";
import OrderTracking from "app/pages/order_tracking/OrderTracking";

const publicRoutes = [
    {
        path: "/order_tracking/:trackingId",
        element: <Page component={OrderTracking} layout="solo-page" />
    },
];

export default publicRoutes;