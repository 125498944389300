import jwtAuthAxios from "./auth/jwtAuth";

const filesServices = {};

filesServices.getImages = async (params, xlsxFormat = false) => {
    // if(xlsxFormat) { jwtAuthAxios.defaults.headers.common['Accept'] = 'application/zip'; }
    const {data} = await jwtAuthAxios.post(`/files/download`, params, {responseType: 'arraybuffer'} );
    // if(xlsxFormat) { jwtAuthAxios.defaults.headers.common['Accept'] = 'application/json'; }
    return data;
};

export default filesServices;