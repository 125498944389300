import React from 'react';
import {Avatar, Button, ButtonGroup, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import _ from "lodash";
import { LoadingButton } from '@mui/lab';


const PluginListItem = (props) => {
    return (
        <ListItem sx={{p: theme => theme.spacing(1, 0)}}>
            <ListItemAvatar>
                <Avatar sizes={"small"} sx={{boxShadow: 25, cursor: 'pointer'}}>PL</Avatar>
            </ListItemAvatar>
            
            <ListItemText
                primary={<Typography variant='h5' mb={.5}>{props.plugin.name}</Typography>}
                // secondary={"@" + item.user.username}
            />
            <ButtonGroup>
                {props.enableConfigButton === true &&
                    <Button
                        variant='contained'
                        size='small'
                        color='primary'
                        onClick={() => props.onConfig(props.plugin)}>
                        Configurar
                    </Button>
                }
                <LoadingButton
                    loading={props.inLoading}
                    variant="contained"
                    color={props.enabled ? "success" : "warning" }
                    size='small'
                    sx={{minWidth: 140}}
                    loadingPosition="end"
                    disabled={props.inLoading}
                    onClick={() => props.onToggle(props.plugin)}>
                        {props.enabled ? props.onText : props.offText}
                </LoadingButton>
            </ButtonGroup>
        </ListItem>
    );
};

export default PluginListItem;