import React from 'react';
import { TextField } from '@mui/material';
import { NumericFormat, PatternFormat } from 'react-number-format';

const PrettyPatternInput = (props) => {
    switch (props.kind) {
        case 'number':
            return (
                <NumericFormat
                    thousandSeparator="."
                    decimalSeparator=","
                    customInput={TextField}
                    {...props}
                />
            )
        case 'phone':
            return (
                <PatternFormat
                    format="## # #### ####"
                    customInput={TextField}
                    {...props}
                />
            )
        default:
            return (
                <NumericFormat
                    thousandSeparator="."
                    decimalSeparator=","
                    customInput={TextField}
                    {...props}
                />
            )
    }
}

export default PrettyPatternInput;