import React, { useState } from 'react';
import { IconButton, Stack } from "@mui/material";
import filesServices from 'app/services/files-services';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import _ from "lodash";

const ImagesDownloadBtn = props => {
    const [isDownloading, setIsDownloading] = useState(false)

    const downloadFiles = () => {
        filesServices.getImages({ field_identifiers: props.fieldIdentifiers, resource_ids: props.resourceIds })
            .then(data => {
                setIsDownloading(false)

                const fileUrl = URL.createObjectURL(new Blob(
                    [ data ],
                    { type: 'application/zip' }
                ))

                window.open(fileUrl)
            })
            .catch((err) => {
                setIsDownloading(false)
            });
    }
    return (
        <IconButton disabled={props.disabled} edge="end" aria-label="Imagenes" onClick={(event) => downloadFiles()}>
            <PermMediaOutlinedIcon fontSize="small" color={props.disabled ? 'disabled' : 'success'}/>
        </IconButton>
    )
}

export default ImagesDownloadBtn;