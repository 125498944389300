import React, { useEffect, useRef } from 'react';
import Card from "@mui/material/Card";
import {CardContent, Typography, LinearProgress, Grid, Stack, List, ListItem, ListItemText, IconButton, ButtonGroup, Divider, Button, Alert, Autocomplete, TextField} from "@mui/material";
import Div from "@jumbo/shared/Div";
import _ from 'lodash';
import moment from 'moment';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';
import UnfoldLessOutlinedIcon from '@mui/icons-material/UnfoldLessOutlined';

import StateBadge from '../../widgets/Shared/StateBadge/StateBadge';
import { stateInfo } from '../../widgets/Shared/StateBadge/stateInfo';
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import StoreTwoToneIcon from '@mui/icons-material/StoreTwoTone';
import EmojiTransportationTwoToneIcon from '@mui/icons-material/EmojiTransportationTwoTone';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import PhonelinkEraseOutlinedIcon from '@mui/icons-material/PhonelinkEraseOutlined';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import MoreTimeOutlinedIcon from '@mui/icons-material/MoreTimeOutlined';
import LockClockOutlinedIcon from '@mui/icons-material/LockClockOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import PlaceIcon from '@mui/icons-material/Place';
import ImagesDownloadBtn from '../../widgets/Images/ImagesDownloadBtn';
import centreServices from 'app/services/centre-services';
import tripServices from 'app/services/trip-services';

const TripListCard = ({trip, onTripFocus, viewMoreActive, onViewMore, onDeliveryFocus, onDriverEdit, onScheduledTimeEdit, onDocsImagesView, onTripChange}) => {
    const [vehicles, setVehicles] = React.useState([])
    const [drivers, setDrivers] = React.useState([])
    const [editingDriver, setEditingDriver] = React.useState(false)
    const [editingVehicle, setEditingVehicle] = React.useState(false)
    const [selDriver, setSelDriver] = React.useState(trip.driver)
    const [selVehicle, setSelVehicle] = React.useState(trip.vehicle)
    let inputDriverRef;
    let inputVehicleRef;

    useEffect(() => {
        moment.locale('es')
    }, [])

    useEffect(() => {
        if(editingVehicle) {
            inputVehicleRef.focus()
            if(vehicles.length <= 0) {
                updateOptions()
            }
        } else {
            if(selVehicle && selVehicle.id !== trip.vehicle?.id) {
                updateTrip(trip.id, {vehicle_id: selVehicle.id})
            }
        }
    }, [editingVehicle])

    useEffect(() => {
        if(editingDriver) {
            inputDriverRef.focus()
            if(drivers.length <= 0) {
                updateOptions()
            }
        } else {
            if(selDriver && selDriver.id !== trip.driver?.id) {
                updateTrip(trip.id, {driver_id: selDriver.id})
            }
        }
    }, [editingDriver])

    const updateOptions = () => {
        if(trip.centre?.id) {
            centreServices.getCentreOptions(trip.centre?.id)
                .then(data => {
                    setDrivers(data?.data?.drivers)
                    setVehicles(data?.data?.vehicles)
                });
        }
    }

    const updateTrip = (trip_id, params) => {
        tripServices.updateTrip(trip_id, "?include=driver,vehicle.company,vehicle.transport_unit1,vehicle.transport_unit2", params)
            .then(data => {
                onTripChange(data)
            })
    }

    const printTimeDiff = (_start, _end) => {
        _start = moment(_start)
        _end = moment(_end)
        var _diffMins = _start > _end ? _start.diff(_end, 'minutes') : _end.diff(_start, 'minutes')
        var hhs = _.floor(_diffMins/60)
        var mms = _diffMins % 60
        return `${_start > _end ? '-' : ''}${hhs > 0 ? `${hhs}h y ` : ''}${mms}min`
    }

    const renderTimeWindow = (del) => {
        if(del.preferred_arrival_start || del.preferred_arrival_end) {
            return(
                <Button variant="outlined" disabled={ del.state === 'finished' } size="small" color={ del.meets_preferred_time_window === true ? 'success' : 'error'} sx={{p: 0}} onClick={() => onScheduledTimeEdit(del)} >
                    <LockClockOutlinedIcon fontSize='small'/>{del.preferred_arrival_start}-{del.preferred_arrival_end}
                </Button>
            )
        } else {
            if(del.state !== 'finished') {
                return(
                    <Button size="small" sx={{p: 0}} onClick={() => onScheduledTimeEdit(del)}>
                        <MoreTimeOutlinedIcon fontSize='small' />
                    </Button>
                )
            }
        }
    }

    const renderDeliveries = () => {                    
        if(trip.deliveries) {
            return (
                <List
                    dense={true}
                    style={{maxHeight: viewMoreActive ? '500px' : '160px', overflow: 'auto', transitionDuration: '0.3s'}}
                    sx={{ padding: 0 }}>
                    {_.map(trip.deliveries, (d) => {
                        return (
                            <ListItem
                                key={d.id}
                                alignItems="flex-start"
                                id={d.id}>
                                    
                                <ListItemText id={d.id} 
                                    primary={
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                            sx={{ maxWidth: 400 }}>
                                            <Typography
                                                sx={{ display: 'inline'}}
                                                component="span">
                                                    <PlaceIcon color={ d.locatable.has_location ? 'success' : 'error' } sx={{ fontSize: 14 }} />
                                                    {d.code} {d.locatable.name}
                                            </Typography>
                                            { d.reception_msg ? <WarningAmberRoundedIcon color={d.reception_msg.kind} /> : null }
                                        </Stack>
                                    }
                                    secondary={
                                        <React.Fragment>
                                            <Stack
                                                direction="row"
                                                divider={<Divider orientation="vertical" flexItem />}
                                                spacing={1}>
                                                    <Typography variant="body2" sx={{textAlign: 'left'}} color={"text.secondary"}>Ped: {d.order?.code}</Typography>
                                                    {_.map(d.delivery_docs, (dd) => {
                                                        return (
                                                            <Typography variant="body2" sx={{textAlign: 'left'}} color={"text.secondary"}>{dd.shipping_doc?.shipping_doc_kind?.slug === 'delivery-receipt' ? 'Guía: ' : 'Fact: '}{dd.shipping_doc?.customer_code}</Typography>
                                                        )
                                                    })}
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                spacing={1}>
                                                <Typography variant="body2" color={"text.secondary"}>
                                                    { (d.planned_start && d.planned_end) ?
                                                        `P: ${moment(d.planned_end).format('DD MMM HH:mm')} (${printTimeDiff(d.planned_start, d.planned_end)})${d.final_end ? ` - R: ${moment(d.final_end).format('DD MMM HH:mm')}` : ''}`
                                                        : 'Sin planificación'
                                                    }
                                                </Typography>
                                                {renderTimeWindow(d)}
                                            </Stack>
                                            {d.order?.comment &&
                                                <Alert severity="info" icon={<CommentOutlinedIcon fontSize="small" />} sx={{['&.MuiAlert-root']: { display: 'inline-flex', px: 0.5, py: 0, fontSize: 11 } }}>
                                                    {d.order.comment}
                                                </Alert>
                                            }
                                        </React.Fragment>
                                    }
                                />
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    spacing={0.5}>
                                    <StateBadge state={d.state} stateName={d.state_name} withIcon={false}/>
                                    <ButtonGroup variant="outlined" aria-label="outlined button group">
                                        <IconButton edge="end" aria-label="Documentos tributarios" onClick={() => onDocsImagesView(d)} disabled={d.docs_images_count <= 0 }>
                                            <FilePresentOutlinedIcon fontSize="small" color={ d.docs_images_count > 0 ? 'success' : 'disabled' }/>
                                        </IconButton>
                                        <IconButton edge="end" aria-label="Detalle de entregas" onClick={() => onDeliveryFocus(d)}>
                                            <FactCheckOutlinedIcon fontSize="small" color={ d.receipt ? 'success' : 'disabled' }/>
                                        </IconButton>
                                    </ButtonGroup>
                                </Stack>
                            </ListItem>
                        )
                    })}
                </List>
            )
        }
    }
    return (
        <Card key={trip.id} sx={{mb: 2 }}>
            <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3} py={0}>
                        <Typography variant="h4" color="primary">Nº {trip.code}</Typography>
                        <Div sx={{display: 'flex', alignItems: 'center', mb: 0}}>
                            <CalendarMonthTwoToneIcon fontSize="small" color={"primary"} />
                            <Typography variant="body2" color={"text.secondary"} ml={1}>{moment(trip.ship_date).utc().format('DD MMM')}</Typography>
                        </Div>
                        <Div sx={{display: 'flex', alignItems: 'center', mb: 0}}>
                            <StoreTwoToneIcon fontSize="small" color={"primary"} />
                            <Typography variant="body2" color={"text.secondary"} ml={1}>{trip.centre?.code}{trip.centre?.name && ` - ${trip.centre?.name}`}</Typography>
                        </Div>
                        <Div sx={{display: 'flex', alignItems: 'center', mb: 0}}>
                            <LocalShippingTwoToneIcon fontSize="small" color={"primary"} />
                            { editingVehicle ?
                                <Autocomplete
                                    id="centre-select"
                                    size="small"
                                    freeSolo
                                    blurOnSelect
                                    fullWidth
                                    openOnFocus
                                    sx={{ pl: 1, maxWidth: 200 }}
                                    options={vehicles}
                                    getOptionLabel={(option) => `${option.code ? option.code : "S/C"} - ${option.transport_unit1?.ppu}${option.transport_unit2 ? `/${option.transport_unit2.ppu}` : ''}`}
                                    value={trip.vehicle}
                                    onChange={(e, v) => { setSelVehicle(v) }}
                                    onBlur={(e, v) => { setEditingVehicle(false) }}
                                    renderInput={(params) => <TextField {...params} variant="standard" label="Vehículo" sx={{background: 'white'}} inputRef={input => { inputVehicleRef = input; }} />}
                                />
                                :
                                <React.Fragment>
                                    <Typography variant="body2" color={"text.secondary"} ml={1}>{trip.vehicle?.transport_unit1?.ppu} {trip.vehicle?.transport_unit2 && `/ ${trip.vehicle?.transport_unit2?.ppu}`}</Typography>
                                    { _.includes(['planned', 'pending'], trip.state) &&
                                        <IconButton edge="end" sx={{p: 0}} onClick={() => { setEditingVehicle(true); }}>
                                            <EditOutlinedIcon fontSize="small" color={"disabled"} />
                                        </IconButton>
                                    }
                                </React.Fragment>
                            }
                        </Div>
                        <Div sx={{display: 'flex', alignItems: 'start', mb: 0}}>
                            <PersonOutlineTwoToneIcon fontSize="small" color={"primary"} />
                            { editingDriver ?
                                <Autocomplete
                                    id="centre-select"
                                    size="small"
                                    freeSolo
                                    blurOnSelect
                                    fullWidth
                                    openOnFocus
                                    sx={{ pl: 1, maxWidth: 200 }}
                                    options={drivers}
                                    getOptionLabel={(option) => `${option.code ? option.code : "S/C"} - ${option.first_name}${option.last_name ? ` ${option.last_name}` : ''}`}
                                    value={trip.driver}
                                    onChange={(e, v) => { setSelDriver(v) }}
                                    onBlur={(e, v) => { setEditingDriver(false) }}
                                    renderInput={(params) => <TextField {...params} variant="standard" label="Conductor" sx={{background: 'white'}} inputRef={input => { inputDriverRef = input; }} />}
                                />
                                :
                                <React.Fragment>
                                    <Typography variant="body2" color={"text.secondary"} ml={1}>{trip.driver?.first_name}{trip.driver?.last_name && ` ${trip.driver?.last_name}`}</Typography>
                                    { _.includes(['planned', 'pending'], trip.state) &&
                                        <IconButton edge="end" sx={{p: 0}} onClick={() => { setEditingDriver(true); }}>
                                            <EditOutlinedIcon fontSize="small" color={"disabled"} />
                                        </IconButton>
                                    }
                                </React.Fragment>
                            }
                        </Div>
                        <Div sx={{display: 'flex', alignItems: 'start', mb: 0}}>
                            <EmojiTransportationTwoToneIcon fontSize="small" color={"primary"} />
                            <Typography variant="body2" color={"text.secondary"} ml={1}>{trip.transportation_company}</Typography>
                        </Div>
                        <Div sx={{mt: 1}}>
                            <StateBadge state={trip.state} stateName={trip.state_name} withIcon={true}/>
                        </Div>
                        <Div sx={{display: 'flex', alignItems: 'center'}}>
                            <Div sx={{width: '100%', mr: 1}}>
                                <LinearProgress variant="determinate" color={stateInfo(trip.state).color} value={(trip.deliveries_ended/trip.deliveries_total)*100}/>
                            </Div>
                            <Div sx={{minWidth: 35}}>
                                <Typography variant="body2" color="text.secondary">{`${trip.deliveries_ended}/${trip.deliveries_total}`}</Typography>
                            </Div>
                        </Div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        {renderDeliveries()}
                    </Grid>

                    <Grid item xs={12} md={3} sx={{ textAlign: 'right' }} pr={1}>
                        <IconButton edge="end" onClick={() => onTripFocus(trip)} disabled={trip.state === 'planned'}>
                            <MapOutlinedIcon color={ _.get(trip, 'deliveries[0].has_route') === true ? 'success' : 'disabled' }/>
                        </IconButton>
                        <IconButton edge="end" onClick={() => onDriverEdit(trip)}>
                            { (trip?.driver?.phone_primary || trip?.driver?.phone_seondary) ?
                                <PhoneIphoneOutlinedIcon color='success'/>
                            :
                                <PhonelinkEraseOutlinedIcon color='error'/>
                            }
                        </IconButton>
                        <ImagesDownloadBtn fieldIdentifiers="trip.deliveries.delivery_details.images" resourceIds={trip.id} disabled={_.sumBy(trip.deliveries, (d) => { return (d.docs_images_count + d.images_count) }) <= 0} />
                        <IconButton edge="end" onClick={() => onViewMore(trip)}>
                            {viewMoreActive ? <UnfoldLessOutlinedIcon /> : <UnfoldMoreOutlinedIcon />}
                        </IconButton>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default TripListCard;