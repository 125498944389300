import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TextField, Radio, InputAdornment, Grid, FormLabel, FormGroup, FormControlLabel, Checkbox, CircularProgress } from "@mui/material";
import _ from "lodash";
import Div from "@jumbo/shared/Div";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { LoadingButton } from '@mui/lab';
import userServices from 'app/services/user-services';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PrettyPatternInput from 'app/widgets/Shared/PrettyPatternInput';

const UserEditModal = props => {
    const {userID, isOpen, onClose=()=>{}, onUserChange=()=>{}} = props;

    const [currentUser, setCurrentUser] = useState(null)
    const [changed, setChanged] = useState(false)
    const [editOptions, setEditOptions] = useState(null)
    const [saving, setSaving] = useState(false)

    useEffect(() => {
        if(userID){
            setChanged(false)
            loadUser(userID)
        }
        if(!editOptions){
            userServices.getEditOptions().then(resp => { setEditOptions(resp) });
        }
    }, [userID])

    const loadUser = (id) => {
        setCurrentUser(null)
        userServices.getUser(id, '?include=profiles')
            .then(resp => {
                setCurrentUser(resp)
            })
            .catch(function (error) {});
    }

    const saveUser = () => {
        setSaving(true)
        userServices.saveUser(currentUser.id, "?include=companies", { user: currentUser })
            .then(resp => {
                onUserChange(resp)
                onClose()
                setSaving(false)
            })
            .catch(function (error) {
                setSaving(false)
            });
    }

    const handleProfileSelect = (event, profile_name) => {
        if(event.target.checked){
            setCurrentUser({ ...currentUser, profiles: _.concat(currentUser.profiles, {company_id: null, name: profile_name }) })
        } else {
            if(currentUser?.profiles?.length > 1){
                setCurrentUser({ ...currentUser, profiles: _.filter(currentUser.profiles, (p) => p.name != profile_name) })
            }
        }
    }

    const validatePhone = (phone) => {
        // Validate only numbers and length between 8 and 15
        return /^[0-9]{11}$/.test(phone)        
    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={isOpen}
                onClose={onClose}>
                <DialogTitle>
                    <Typography variant={"h2"} color="primary">{currentUser ? currentUser.first_name ? `${currentUser.first_name} ${currentUser.last_name || ''}` : currentUser.email : 'Cargando' }</Typography>
                </DialogTitle>
                <DialogContent>
                    { currentUser ?
                        <React.Fragment>
                            <Typography variant={"h4"} color="primary">Datos generales</Typography>
                            <Grid container spacing={3} alignItems={"baseline"} sx={{pt: 1, mb: 3}}>
                                <Grid item xs={6} lg={4}>
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        label="Nombre"
                                        error={!currentUser?.first_name}
                                        value={currentUser?.first_name || ''}
                                        onChange={(e) => setCurrentUser({...currentUser, first_name: e.target.value})}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={4}>
                                    <TextField
                                        fullWidth
                                        label="Apellido"
                                        error={!currentUser?.last_name}
                                        value={currentUser?.last_name || ''}
                                        onChange={(e) => setCurrentUser({...currentUser, last_name: e.target.value})}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={4}>
                                    <TextField
                                        fullWidth
                                        label="Correo electrónico"
                                        error={!currentUser?.email}
                                        value={currentUser?.email || ''}
                                        onChange={(e) => setCurrentUser({...currentUser, email: e.target.value})}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={4}>
                                    <PrettyPatternInput
                                        kind="phone"
                                        fullWidth
                                        label="Teléfono primario"
                                        helperText="Ej: 56 9 1234 5678"
                                        error={currentUser.phone_primary && !validatePhone(currentUser?.phone_primary)}
                                        value={currentUser?.phone_primary || ''}
                                        onValueChange={(vals, sourceInfo) => { setCurrentUser({...currentUser, phone_primary: vals.value}) }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={4}>
                                    <PrettyPatternInput
                                        kind="phone"
                                        fullWidth
                                        label="Teléfono secundario"
                                        helperText="Ej: 56 9 1234 5678"
                                        error={currentUser.phone_secondary && !validatePhone(currentUser?.phone_secondary)}
                                        value={currentUser?.phone_secondary || ''}
                                        onValueChange={(vals, sourceInfo) => { setCurrentUser({...currentUser, phone_secondary: vals.value}) }}
                                    />
                                </Grid>
                            </Grid>

                            <Typography variant={"h4"} color="primary">Perfiles</Typography>
                            <Grid container spacing={3} alignItems={"baseline"} sx={{pt: 1, mb: 3}}>
                                { editOptions?.profile_names &&
                                    <Grid item xs={6} lg={4}>
                                        <FormGroup>
                                            {_.map(editOptions.profile_names, (p, i) => {
                                                return (
                                                    <FormControlLabel label={p.human_name} control={
                                                        <Checkbox color="success" checked={currentUser?.profiles && _.filter(currentUser?.profiles, { name: p.name } ).length > 0} icon={<CircleOutlinedIcon/>} checkedIcon={<CheckCircleIcon/>} onChange={(e) => handleProfileSelect(e, p.name)}/>
                                                    } />
                                                )
                                            })}
                                        </FormGroup>
                                    </Grid>
                                }
                            </Grid>
                        </React.Fragment>
                    :
                        <Div sx={{ textAlign: 'center' }}>
                            <CircularProgress />
                        </Div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { onClose() } }>Cerrar</Button>
                    <LoadingButton
                        loading={saving}
                        variant="contained"
                        color="success"
                        size="small"
                        endIcon={<SaveOutlinedIcon/>}
                        loadingPosition="end"
                        onClick={saveUser}>
                            { saving ? 'Guardando' : 'Guardar' }
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default UserEditModal;