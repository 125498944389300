import jwtAuthAxios from "./auth/jwtAuth";

const centreServices = {};

centreServices.getCentres = async (params) => {
    const {data} = await jwtAuthAxios.get(`/centres${params || ''}`);
    return data;
};

centreServices.getCentreOptions = async (id) => {
    const {data} = await jwtAuthAxios.get(`/centres/${id}/options`);
    return data;
};

export default centreServices;