import jwtAuthAxios from "./auth/jwtAuth";

const tripServices = {};

tripServices.getTrips = async (params, xlsxFormat = false) => {
    if(xlsxFormat) { jwtAuthAxios.defaults.headers.common['Accept'] = 'application/xlsx'; }
    const {data} = await jwtAuthAxios.get(`/trips${params}${xlsxFormat ? '' : ''}`, xlsxFormat ? { responseType: 'arraybuffer' } : {});
    if(xlsxFormat) { jwtAuthAxios.defaults.headers.common['Accept'] = 'application/json'; }
    return data;
};

tripServices.getTripDetails = async (trip_id, params = "") => {
    const {data} = await jwtAuthAxios.get(`/trips/${trip_id}${params}`);
    return data;
};

tripServices.calculateRouteOrder = async (trip_id, params={}) => {
    const {data} = await jwtAuthAxios.post(`/trips/${trip_id}/calculate_route_order`, params);
    return data;
};

tripServices.updateTrip = async (trip_id, params, body) => {
    const {data} = await jwtAuthAxios.put(`/trips/${trip_id}${params}`, body);
    return data;
};

tripServices.addByOrder = async (trip_id, body) => {
    const {data} = await jwtAuthAxios.post(`/trips/${trip_id}/add_by_order`, body);
    return data;
};

tripServices.addByCustomer = async (trip_id, body) => {
    const {data} = await jwtAuthAxios.post(`/trips/${trip_id}/add_by_customer`, body);
    return data;
};

tripServices.addByPlace = async (trip_id, body) => {
    const {data} = await jwtAuthAxios.post(`/trips/${trip_id}/add_by_place`, body);
    return data;
};

tripServices.removeDelivery = async (trip_id, body) => {
    const {data} = await jwtAuthAxios.post(`/trips/${trip_id}/remove_delivery`, body);
    return data;
}

export default tripServices;