import React from 'react';
import {Chip} from "@mui/material";
import { stateInfo } from './stateInfo';

const StateBadge = ({state, stateName, withIcon=true, sx={}, variant='filled', clickable, onClick}) => {
    if(variant === 'outlined') {
        sx['backgroundColor'] = 'white';
    }
    return (
        <Chip
            size={"small"}
            variant={variant}
            color={stateInfo(state).color}
            label={stateName || state}
            icon={withIcon ? stateInfo(state).icon : null}
            sx={sx}
            clickable={clickable}
            onClick={onClick} />
    );
};

export default StateBadge;