import React, { useEffect, useRef, useState } from 'react';
import { Card, CardContent, CircularProgress, Grid, Typography, useTheme, List, Stack, IconButton, Button } from "@mui/material";
import { Search, SearchIconWrapper, StyledInputBase } from "app/shared/SearchGlobal/style";
import _ from "lodash";
import Div from "@jumbo/shared/Div";
import SearchIcon from "@mui/icons-material/Search";
import CustomPagination from 'app/widgets/Shared/Pagination/CustomPagination';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import StoreTwoToneIcon from '@mui/icons-material/StoreTwoTone';
import Crop32OutlinedIcon from '@mui/icons-material/Crop32Outlined';
import VehicleEditModal from './VehicleEditModal';
import vehicleServices from 'app/services/vehicle-services';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';


const UsersIndex = () => {
    // const Swal = useSwalWrapper();
    // const theme = useTheme();
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [itemsList, setItemsList] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [listLoading, setListLoading] = useState(false)
    const [currentRecordId, setCurrentRecordId] = useState(null)
    const [openRecordEditModal, setOpenRecordEditModal] = useState(false)

    useEffect(() => {
        if(listLoading){
            loadItems()
        }
    }, [listLoading])

    useEffect(() => {
        setListLoading(true)
    }, [])

    const buildItemsParams = () => {
        let par = []

        if(searchQuery) {
            par.push(encodeURI(`q[code_cont]=${searchQuery}`))
        }

        par.push(`page=${currentPage}`)
        par.push(`include=centre,transport_unit1,transport_unit2`)
        par.push(`${encodeURIComponent(`q[s]`)}=${encodeURIComponent('code asc')}`)

        par = _.join(par, '&')

        return `?${par}`;
    }

    const loadItems = () => {
        vehicleServices.getVehicles(buildItemsParams())
            .then(resp => {
                setItemsList(resp.data)
                setTotalPages(resp.meta.total_pages)
            })
            .catch(function (error) {})
            .finally(() => {
                setListLoading(false)
            });
    }

    const handleSearchQueryChange = (newVal) => {
        setCurrentPage(1)
        setSearchQuery(newVal)
        setListLoading(true)
    }

    const handleRecordChange = (rec) => {
        setItemsList(_.map(itemsList, (u) => { return u.id == rec.id ? rec : u }))
    }

    const handleRecordAdd = (rec) => {
        setItemsList(_.concat(itemsList, rec))
    }

    const debounceSearchQueryChange = _.debounce(handleSearchQueryChange, 450)

    const renderItemsList = () => {
        if(itemsList){
            return (
                <React.Fragment>
                    <VehicleEditModal
                        recordID={currentRecordId}
                        isOpen={openRecordEditModal}
                        onClose={(changed, userOutput) => { setOpenRecordEditModal(false) }}
                        onRecordChange={handleRecordChange}
                        onRecordAdd={handleRecordAdd} />
                    <List disablePadding>
                        {_.map(itemsList, (u, i) => {
                            return (
                                <React.Fragment key={u.id}>
                                    <Card sx={{mb: 2}}>
                                        <CardContent sx={{display: 'flex'}}>
                                            <Grid container spacing={5}>
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="h4" color="primary">Nº {u.code}</Typography>
                                                    <Div sx={{display: 'flex', alignItems: 'center', mb: 0}}>
                                                        <StoreTwoToneIcon fontSize="small" color={"primary"} />
                                                        <Typography variant="body2" color={"text.secondary"} ml={1}>{u.centre?.code}{u.centre?.name && ` - ${u.centre?.name}`}</Typography>
                                                    </Div>
                                                    <Div sx={{display: 'flex', alignItems: 'center', mb: 0}}>
                                                        <Crop32OutlinedIcon fontSize="small" color={"primary"} />
                                                        <Typography variant="body2" color={"text.secondary"} ml={1}>{u.transport_unit1?.ppu || ""}{u.transport_unit2?.ppu ? ` | ${u.transport_unit2?.ppu}` : ""}</Typography>
                                                    </Div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    
                                                    
                                                    {/* <Stack
                                                        direction="column"
                                                        justifyContent="start"
                                                        alignItems="left"
                                                        spacing={0.5}>

                                                        <Typography variant={"overline"}>Empresas asociadas</Typography>
                                                        { u.companies?.length <= 0 && <Typography variant={"body"}>-</Typography> }
                                                        <AvatarGroup max={3} sx={{justifyContent: 'flex-end', cursor: 'pointer'}}>
                                                            { _.map(_.uniqBy(u.companies, 'id'), (c) => {
                                                                return (
                                                                    <Tooltip title={c.name} key={`${u.id}_${c.id}`}>
                                                                        { c.logo_url ?
                                                                            <Avatar src={`${HOST}${c.logo_url}`} />
                                                                            :
                                                                            <Avatar alt={c.name}>
                                                                                {getAvatarInitials(c.name)}
                                                                            </Avatar>
                                                                        }
                                                                    </Tooltip>
                                                                )
                                                            })}
                                                        </AvatarGroup>
                                                    </Stack> */}
                                                </Grid>
                                                <Grid item xs={12} md={3} style={{height: '100%'}}>
                                                </Grid>
                                                <Grid item xs={12} md={1} style={{height: '100%'}}>
                                                    <IconButton onClick={() => { setCurrentRecordId(u.id); setOpenRecordEditModal(true) }}>
                                                        <EditOutlinedIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </React.Fragment>
                            )
                        })}
                    </List>
                </React.Fragment>
            )
        } else {
            return(
                <Typography>
                    No se encontraron datos
                </Typography>
            )
        }
    }
    
    return (
        <div>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={3}
                sx={{mb: 2}}>
                <Typography variant="h2">Vehículos</Typography>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={3}>
                    <Button variant="contained" size="small" color="success" sx={{width: 100}} startIcon={<ControlPointOutlinedIcon/>} onClick={() => { setCurrentRecordId(null); setOpenRecordEditModal(true) }}>Crear</Button>                    
                    <Search sx={{maxWidth: { sm: 200, md: 350 }}}>
                        <SearchIconWrapper>
                            <SearchIcon/>
                        </SearchIconWrapper>

                        <StyledInputBase
                            placeholder="Buscar"
                            inputProps={{'aria-label': 'search'}}
                            sx={{background: 'white'}}
                            onChange={(e) => {
                                debounceSearchQueryChange(e.target.value)
                            }}
                            onKeyDown={(e) => {
                                if (e.key == 'Enter') {
                                    handleSearchQueryChange(e.target.value)
                                }
                            }}
                        />
                    </Search>
                </Stack>
            </Stack>

            <Div sx={{display: listLoading ? 'block' : 'none', textAlign: 'center', my: 5}}>
                <CircularProgress color="warning"/>
            </Div>
            <Grid container spacing={3.75}>
                <Grid item xs={12}>
                    {renderItemsList()}
                </Grid>
            </Grid>
            <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageSelect={(page) => { setCurrentPage(page); setListLoading(true)}}/>
        </div>
    );
};

export default UsersIndex;