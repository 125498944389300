import React from 'react';
import Div from "@jumbo/shared/Div";
import Link from "@mui/material/Link";
import {ASSET_IMAGES} from "../../utils/constants/paths";


const Logo = ({mini, mode, sx, href, logo_url, link_sx}) => {
    return (
        <Div sx={{display: "inline-flex", ...sx}}>
            <Link href={href} sx={link_sx}>
                {
                    logo_url ?
                        <img src={logo_url} />
                        :
                        !mini ?
                            <img src={ mode === "light" ? `${ASSET_IMAGES}/logos/logo.png` : `${ASSET_IMAGES}/logos/logo-white.png`} alt="Haulers" />
                            :
                            <img src={mode === "light" ? `${ASSET_IMAGES}/logos/logo-short.png` : `${ASSET_IMAGES}/logos/logo-short-white.png`} alt="Haulers" />
                }
            </Link>
        </Div>
    );
};

Logo.defaultProps = {
    mode: "light"
};

export default Logo;
