import jwtAuthAxios from "./auth/jwtAuth";

const userServices = {};

userServices.getUsers = async (params) => {
    const {data} = await jwtAuthAxios.get(`/users${ params ?? ""}`);
    return data;
};

userServices.getUser = async (id, params) => {
    const {data} = await jwtAuthAxios.get(`/users/${id}${ params ?? ""}`);
    return data;
};

userServices.getEditOptions = async () => {
    const {data} = await jwtAuthAxios.get(`/users/edit_options`);
    return data;
};

userServices.saveUser = async (id, params, body) => {
    const {data} = await jwtAuthAxios.put(`/users/${id}${params ?? ""}`, body);
    return data;
}

// userServices.saveRecipients = async (id, params) => {
//     const {data} = await jwtAuthAxios.post(`/customers/${id}/recipients`, params);
//     return data;
// };

userServices.setCurrentCompany = async (id) => {
    const {data} = await jwtAuthAxios.post(`/users/current_company`, { company_id: id });
    return data;
};

export default userServices;