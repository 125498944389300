import React, { useEffect, useState } from 'react';
import {Alert, Card, CardContent, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Button, IconButton, List, ListItem, ListItemText, Menu, MenuItem, Pagination, Stack, TextField, Tooltip, Typography, ButtonGroup} from "@mui/material";
import _ from "lodash";
import Div from "@jumbo/shared/Div";
import RouteBuilder from "../trips/RouteBuilder";
import DeliveryDetails from "app/pages/trips/DeliveryDetails";
import DriverForm from "app/pages/drivers/DriverForm";
import tripServices from "app/services/trip-services";
import StateBadge from 'app/widgets/Shared/StateBadge/StateBadge';
import {Search, SearchIconWrapper, StyledInputBase} from "../../shared/SearchGlobal/style";
import SearchIcon from "@mui/icons-material/Search";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {styled} from "@mui/material";
import DeliveryScheduleForm from 'app/pages/trips/DeliveryScheduleForm';
import CustomPagination from 'app/widgets/Shared/Pagination/CustomPagination';
import deliveryServices from 'app/services/delivery-services';
import ImageViewer from 'app/widgets/Images/ImageViewer';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import StoreTwoToneIcon from '@mui/icons-material/StoreTwoTone';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import moment from 'moment';
import 'moment/locale/es';
import orderServices from 'app/services/order-services';
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone';
import { LoadingButton, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator, timelineItemClasses, timelineOppositeContentClasses } from '@mui/lab';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import FileUploader from 'app/widgets/Shared/FileUploader';
import fileTaskServices from 'app/services/file-task-services';
import FileUploaderModal from 'app/widgets/Shared/FileUploader/FileUploaderModal';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { defaultSwalPreferences, hasPlugin } from 'app/utils/appHelpers';
import Swal from 'sweetalert2';
import SplitButton from 'app/widgets/Shared/SplitButton';

const OrdersList = () => {
    const {authUser} = useJumboAuth();
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const [listLoading, setListLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [searchQuery, setSearchQuery] = useState('')
    const [filterState, setFilterState] = useState('')
    const [orders, setOrders] = useState([])
    const [showFileUploaderModal, setShowFileUploaderModal] = useState(false)
    const [importing, setImporting] = useState(false)
    const [filesToImport, setFilesToImport] = useState([])
    const [importError, setImportError] = useState('')

    useEffect(() => {
        if(listLoading){
            loadOrders()
        }
    }, [listLoading])

    useEffect(() => {
        moment.locale('es-CL')
        setListLoading(true)
    }, [])

    const buildOrderParams = () => {
        let par = []
        if(filterState) {
            par.push(`q%5Bstate_eq%5D=${filterState}`)
        }
        if(searchQuery && searchQuery !== 0) {
            par.push(`q%5Bcode_or_locatable_of_Customer_type_name_or_locatable_of_Place_type_name_cont%5D=${searchQuery}`)
        }
        // if(startDate) {
        //     par.push(`q%5Bship_date_gteq%5D=${startDate}`)
        // }
        // if(endDate) {
        //     par.push(`q%5Bship_date_lteq%5D=${endDate}`)
        // }

        par.push(`page=${currentPage}`)
        par.push(`include=locatable.location,order_details.delivery_details.images_attachments,seller,centre`)
        par.push(`with_stats=true`)

        par = _.join(par, '&')

        return `?${par}`;
    }

    const loadOrders = () => {
        orderServices.getOrders(buildOrderParams())
            .then(data => {
                setTotalPages(data.meta.total_pages)
                setCurrentPage(data.meta.current_page)
                setOrders(data.data)
                setListLoading(false)
            })
            .catch((err) => {
                setListLoading(false)
            });
    }

    const importOrders = () => {
        setImporting(true)
        setImportError('')
        fileTaskServices.bulkImport('default_bulk_order_import', filesToImport)
            .then(data => {
                setImporting(false)
                setFilesToImport([])
                setShowFileUploaderModal(false)
                setListLoading(true)
                Swal.mixin(defaultSwalPreferences(Swal)).fire({
                    icon: 'success',
                    title: data.message,
                    background: 'success',
                });
            })
            .catch((err) => {
                setImportError(err.response?.data?.error)
                setImporting(false)
            });
    }

    const handleSearchQueryChange = (newVal) => {
        setSearchQuery(newVal)
        setCurrentPage(1)
        setListLoading(true)
    }

    const debounceSearchQueryChange = _.debounce(handleSearchQueryChange, 450)

    const handleFilterState = (newState) => {
        var finalSt = newState === filterState ? null : newState
        setFilterState(finalSt);
        setListLoading(true);
    };

    const renderFilters = () => {
        const statesList = [
            { name: 'pending',      stateName: 'Pendiente'},
            { name: 'invoiced',     stateName: 'Documentado'},
            { name: 'in_progress',  stateName: 'En progreso'},
            { name: 'finished',     stateName: 'Finalizado'},
            { name: 'cancelled',    stateName: 'Cancelado'},
        ]
        return (
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={3}
                sx={{mb: 2}}>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}>
                    {_.map(statesList, (st) => {
                        return (
                            <StateBadge
                                state={st.name}
                                stateName={st.stateName}
                                clickable={true}
                                variant={filterState === st.name ? 'filled' : 'outlined'}
                                onClick={() => { handleFilterState(st.name) }} />
                        )
                    })}
                </Stack>

                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={3}>
                        { hasPlugin(authUser?.current_company?.enabled_plugins, 'default-bulk-order-importer') &&
                            <SplitButton
                                btnGroupProps={{
                                    color: 'success',
                                    size: 'small',
                                    variant: 'contained',
                                }}
                                mainOpt={{
                                    text: "Importar",
                                    onClick: () => { setShowFileUploaderModal(true) }
                                }}
                                secondaryOpts={[
                                    {
                                        text: "Ver historial",
                                        onClick: () => { window.location.href = '/file_tasks' }
                                    }
                                ]}
                            />
                        }

                        <Search sx={{maxWidth: { sm: 200, md: 350 }}}>
                            <SearchIconWrapper>
                                <SearchIcon/>
                            </SearchIconWrapper>

                            <StyledInputBase
                                placeholder="Buscar"
                                inputProps={{'aria-label': 'search'}}
                                sx={{background: 'white'}}
                                onChange={(e) => {
                                    debounceSearchQueryChange(e.target.value)
                                }}
                            />
                        </Search>
                </Stack>
            </Stack>
        )
    }

    const showOrderSyncButton = () => {
        var plugs = authUser?.current_company?.enabled_plugins

        if(plugs && _.find(plugs, { slug: 'relbase-sale-notes' })) {
            return true;
        }
        return false;
    }

    const handleSyncOrder = (order) => {
        orderServices.syncRemoteOrder(order.id)
            .then(data => {
                setListLoading(true)
            })
            .catch((err) => {
                setListLoading(false)
            });
    }

    const renderOrdersList = () => {
        if(orders){
            return (
                <React.Fragment>
                    {_.map(orders, (o, i) => {
                        return (
                            <Card key={o.id} sx={{mb: 2 }}>
                                <CardContent sx={{ p: 1 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={3} py={0}>
                                            <Div sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                                                <Typography variant={"h4"} sx={{mb:0}}>Nº {o.code}</Typography>
                                                <IconButton onClick={(e) => handleSyncOrder(o)} >
                                                    { (showOrderSyncButton() && o.state === 'pending') &&
                                                        <SyncOutlinedIcon fontSize="small" color={"success"} />
                                                    }
                                                </IconButton>
                                            </Div>
                                            <Div sx={{display: 'flex', alignItems: 'center', mb: 0}}>
                                                <CalendarMonthTwoToneIcon fontSize="small" color={"primary"} />
                                                <Typography variant="body2" color={"text.secondary"} ml={1}>{moment(o.ship_date).utc().format('DD MMM')}</Typography>
                                            </Div>
                                            <Div sx={{display: 'flex', alignItems: 'center', mb: 0}}>
                                                <StoreTwoToneIcon fontSize="small" color={"primary"} />
                                                <Typography variant="body2" color={"text.secondary"} ml={1}>{o.centre?.code}{o.centre?.name && ` - ${o.centre?.name}`}</Typography>
                                            </Div>
                                            <Div sx={{display: 'flex', alignItems: 'center', mb: 0}}>
                                                <PersonOutlineTwoToneIcon fontSize="small" color={"primary"} />
                                                <Typography variant="body2" color={"text.secondary"} ml={1}>{o.locatable?.name}</Typography>
                                            </Div>
                                            {o.seller && <Div sx={{display: 'flex', alignItems: 'center', mb: 0}}>
                                                <ShoppingBagOutlinedIcon fontSize="small" color={"primary"} />
                                                <Typography variant="body2" color={"text.secondary"} ml={1}>{o.seller?.first_name} {o.seller?.last_name}</Typography>
                                            </Div>}
                                            <Div sx={{mt: 1}}>
                                                <StateBadge state={o.state} stateName={o.state_name} withIcon={true}/>
                                            </Div>
                                            {o.comment &&
                                                <Alert severity="info" icon={<CommentOutlinedIcon fontSize="small" />} sx={{mt: 1, ['&.MuiAlert-root']: { display: 'inline-flex', px: 0.5, py: 0 } }}>
                                                    {o.comment}
                                                </Alert>
                                            }
                                            <Div sx={{display: 'flex', alignItems: 'center'}}>
                                                <Div sx={{width: '100%', mr: 1}}>
                                                    {/* <LinearProgress variant="determinate" color={stateInfo(trip.state).color} value={(trip.deliveries_ended/trip.deliveries_total)*100}/> */}
                                                </Div>
                                                <Div sx={{minWidth: 35}}>
                                                    {/* <Typography variant="body2" color="text.secondary">{`${trip.deliveries_ended}/${trip.deliveries_total}`}</Typography> */}
                                                </Div>
                                            </Div>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            {renderOrderDetails(o)}
                                        </Grid>

                                        <Grid item xs={12} md={3}>
                                            {renderStats(o)}
                                            {/* <IconButton edge="end" onClick={() => onTripFocus(trip)}>
                                                <MapOutlinedIcon color={ _.get(trip, 'deliveries[0].has_route') == true ? 'success' : 'disabled' }/>
                                            </IconButton>
                                            <IconButton edge="end" onClick={() => onDriverEdit(trip)}>
                                                { (trip?.driver?.phone_primary || trip?.driver?.phone_seondary) ?
                                                    <PhoneIphoneOutlinedIcon color='success'/>
                                                :
                                                    <PhonelinkEraseOutlinedIcon color='error'/>
                                                }
                                            </IconButton>
                                            <ImagesDownloadBtn fieldIdentifiers="trip.deliveries.delivery_details.images" resourceIds={trip.id} disabled={_.sumBy(trip.deliveries, (d) => { return (d.docs_images_count + d.images_count) }) <= 0} />
                                            <IconButton edge="end" onClick={() => onViewMore(trip)}>
                                                {viewMoreActive ? <UnfoldLessOutlinedIcon /> : <UnfoldMoreOutlinedIcon />}
                                            </IconButton> */}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        )
                    })}
                </React.Fragment>
            )
        } else {
            return(
                <Typography>
                    No se encontraron datos
                </Typography>
            )
        }
    }

    const renderOrderDetails = (order) => {
        if(order?.order_details) {
            return (
                <List
                    dense={true}
                    // style={{maxHeight: viewMoreActive ? '500px' : '160px', overflow: 'auto', transitionDuration: '0.3s'}}
                    sx={{ padding: 0 }}>
                    {_.map(order.order_details, (d) => {
                        return (
                            <ListItem
                                key={d.id}
                                alignItems="flex-start"
                                id={d.id}>
                                    
                                <ListItemText id={d.id} 
                                    primary={
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            spacing={1}
                                            sx={{ maxWidth: 500 }}>
                                            <Typography>{d.product}</Typography>
                                            <Stack direction="row" alignItems="center" style={{width: 100}}>
                                                <Inventory2OutlinedIcon fontSize="small" color={"primary"} />
                                                <Tooltip title="Cantidad original / Cantidad pickeada / Cantidad entregada" arrow>
                                                    <Typography variant="overline" style={{lineHeight: 1, cursor: 'default'}}>{d.qty?.toLocaleString('es-CL')}/{_.sumBy(d.delivery_details, 'picked_qty')?.toLocaleString('es-CL')}/{_.sumBy(d.delivery_details, 'delivered_qty')?.toLocaleString('es-CL')}</Typography>
                                                </Tooltip>
                                            </Stack>
                                        </Stack>
                                    }
                                />
                            </ListItem>
                        )
                    })}
                </List>
            )
        }
    }

    const renderStats = (order) => {
        if(order?.stats) {
            return (
                <React.Fragment>
                    {order.stats?.deliveries?.length > 0 ?
                        <React.Fragment>
                            <Typography variant="h6" sx={{mt: 1, mb: 0}}>Entregas ({order.stats?.deliveries?.length}):</Typography>
                            <Timeline sx={{
                                mt: 0,
                                [`& .${timelineItemClasses.root}:before`]: {
                                    flex: 0,
                                    padding: 0,
                                },
                            }}>
                                {
                                    order?.stats?.deliveries.map((d, i) => (
                                        <TimelineItem>
                                            {/* <TimelineOppositeContent>
                                                {moment(d.ship_date).utc().format('DD MMM')}
                                            </TimelineOppositeContent> */}
                                            <TimelineSeparator>
                                                <TimelineDot>
                                                    <LocalShippingTwoToneIcon />
                                                </TimelineDot>
                                                { i < order?.stats?.deliveries.length - 1 && <TimelineConnector />}
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                    <StateBadge state={d.state} stateName={d.state_name} withIcon={false}/>
                                                </Stack>
                                                <Typography variant='body2' fontSize={13}>
                                                    {_.includes(['planned', 'pending', 'in_progress'], d.state) ?
                                                        <React.Fragment>
                                                            {moment(d.ship_date).utc().format('DD MMM')} {_.sumBy(d.delivery_details, 'qty')?.toLocaleString('es-CL')} items por despachar
                                                        </React.Fragment>
                                                    :
                                                        <React.Fragment>
                                                            {moment(d.ship_date).utc().format('DD MMM')} {_.sumBy(d.delivery_details, 'delivered_qty')?.toLocaleString('es-CL')} items entregados
                                                        </React.Fragment>
                                                    }
                                                </Typography>
                                            </TimelineContent>
                                        </TimelineItem>
                                    ))
                                }
                            </Timeline>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Typography variant="body2" color="text.secondary">Sin entregas</Typography>
                        </React.Fragment>
                    }
                </React.Fragment>
            )
        }
    }

    return (
        <div>
            <Typography variant="h2">Pedidos</Typography>
            {renderFilters()}

            <Div sx={{display: listLoading ? 'block' : 'none', textAlign: 'center', my: 5}}>
                <CircularProgress color="warning"/>
            </Div>

            {renderOrdersList()}

            <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageSelect={(page) => {setCurrentPage(page); setListLoading(true)}}/>
            
            <FileUploaderModal
                kind='default_bulk_order_import'
                open={showFileUploaderModal}
                fileUploaderOptions={{
                    errorMsg: importError,
                    onFileSelection: (acceptedFiles) => {
                        setFilesToImport(acceptedFiles)
                    }
                }}
                onClose={() => { setShowFileUploaderModal(false) }}
                onSave={importOrders}
                saving={importing}
                canSave={filesToImport.length > 0}
            />
        </div>
    );
};

export default OrdersList;