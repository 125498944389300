import jwtAuthAxios from "./auth/jwtAuth";

const planificationServices = {};

planificationServices.savePlanification = async (body) => {
    const {data} = await jwtAuthAxios.post(`/trips/save_planification`, body);
    return data;
};

planificationServices.getPlanification = async (params) => {
    const {data} = await jwtAuthAxios.get(`/trips/planification_data${params}`);
    return data;
};

planificationServices.getRoutes = async (body) => {
    const {data} = await jwtAuthAxios.post(`/trips/calculate_routes`, body);
    return data;
};

planificationServices.optimize = async (body) => {
    const {data} = await jwtAuthAxios.post(`/trips/optimize`, body);
    return data;
};

export default planificationServices;