import jwtAuthAxios from "./auth/jwtAuth";

const orderServices = {};

orderServices.getOrders = async (params) => {
    const {data} = await jwtAuthAxios.get(`/orders${params}`);
    return data;
};

orderServices.getOrder = async (id) => {
    const {data} = await jwtAuthAxios.get(`/orders/${id}`);
    return data;
};

orderServices.syncRemoteOrder = async (id) => {
    const {data} = await jwtAuthAxios.get(`/orders/${id}/sync_with_remote`);
    return data;
}

orderServices.bulkImport = async (files) => {
    const formData = new FormData();
    for (const file of files) {
        formData.append('files[]', file);
    }

    const {data} = await jwtAuthAxios.post(`/orders/bulk_import`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return data;
}
    

// orderServices.saveRecipients = async (id, params) => {
//     const {data} = await jwtAuthAxios.post(`/orders/${id}/recipients`, params);
//     return data;
// };

// orderServices.saveCustomerGeofence = async (id, params) => {
//     const {data} = await jwtAuthAxios.post(`/orders/${id}/geofence`, params);
//     return data;
// };

// orderServices.getReceiptsPins = async (id) => {
//     const {data} = await jwtAuthAxios.get(`/orders/${id}/receipts_pins`);
//     return data;
// };

export default orderServices;