import jwtAuthAxios from "./auth/jwtAuth";

const surveyServices = {};

surveyServices.getQuestions = async (params) => {
    const {data} = await jwtAuthAxios.get(`/survey_questions${ params ?? ""}`);
    return data;
};

// surveyServices.getUser = async (id, params) => {
//     const {data} = await jwtAuthAxios.get(`/users/${id}${ params ?? ""}`);
//     return data;
// };

// surveyServices.getEditOptions = async () => {
//     const {data} = await jwtAuthAxios.get(`/users/edit_options`);
//     return data;
// };

// surveyServices.saveUser = async (id, params, body) => {
//     const {data} = await jwtAuthAxios.put(`/users/${id}${params ?? ""}`, body);
//     return data;
// }

// surveyServices.saveRecipients = async (id, params) => {
//     const {data} = await jwtAuthAxios.post(`/customers/${id}/recipients`, params);
//     return data;
// };

export default surveyServices;