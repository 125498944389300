import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Box, IconButton, Stack } from "@mui/material";
import _ from "lodash";
import Div from "@jumbo/shared/Div";

import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

const ImageViewer = props => {
    const [currentImagePos, setCurrentImagePos] = useState(0)

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={props.isOpen}
                onClose={props.onClose}>
                <DialogTitle>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant={"h2"} color="primary">{props.title || 'Imágenes'}</Typography>
                        { props.images ? 
                            <Typography>{currentImagePos + 1}/{props.images.length}</Typography> : null
                        }
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Stack
                        direction="row" justifyContent="space-between"
                        sx={{
                            height: '70vh',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                        <IconButton onClick={() => { setCurrentImagePos(_.max([currentImagePos - 1, 0])) }}>
                            <ArrowCircleLeftIcon color="primary" sx={{ fontSize: 50 }} />
                        </IconButton>
                        { (props.images && currentImagePos < props.images.length) ?
                            <Div>
                                <Box
                                    component="img"
                                    sx={{
                                        maxHeight: '70vh',
                                        maxWidth: '100%',
                                        textAlign: 'center'
                                    }}
                                    src={props.images[currentImagePos]}
                                />
                            </Div>
                        : 'Sin vista previa'
                        }
                        <IconButton onClick={() => { setCurrentImagePos(_.min([currentImagePos + 1, props.images.length - 1])) }}>
                            <ArrowCircleRightIcon color="primary" sx={{ fontSize: 50 }} />
                        </IconButton>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { props.onClose() } }>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default ImageViewer;