import React, { useEffect, useState } from 'react';
import { Card, CardContent, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import _ from "lodash";
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import Div from "@jumbo/shared/Div";
import moment from 'moment';
import logsServices from "app/services/logs-services";
import CustomPagination from "app/widgets/Shared/Pagination/CustomPagination";
import { Search, SearchIconWrapper, StyledInputBase } from "app/shared/SearchGlobal/style";
import SearchIcon from "@mui/icons-material/Search";


const Customers = () => {
    const [logsList, setLogsList] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [searchQuery, setSearchQuery] = useState('')
    const [listLoading, setListLoading] = useState(false)

    useEffect(() => {
        if(listLoading){
            loadLogs()
        }
    }, [listLoading])

    useEffect(() => {
        setListLoading(true)
    }, [])

    const buildLogsParams = () => {
        let par = []
        let pos = 0
        par.push(encodeURI(`q[m]=and`))
        if(searchQuery && searchQuery !== 0) {
            par.push(encodeURI(`q[g][${pos}][data_cont]=${searchQuery}`))
            pos += 1
        }

        par.push(`page=${currentPage}`)

        par = _.join(par, '&')

        return `?${par}`;
    }

    const handleSearchQueryChange = (newVal) => {
        setCurrentPage(1)
        setSearchQuery(newVal)
        setListLoading(true)
    }

    const debounceSearchQueryChange = _.debounce(handleSearchQueryChange, 450)
    
    const loadLogs = () => {            
        logsServices.getLogs(buildLogsParams())
            .then(data => {
                setLogsList(data.data)
                setTotalPages(data.meta.total_pages)
            })
            .catch(function (error) {})
            .finally(() => {
                setListLoading(false)
            });
    }

    const renderLogsList = () => {
        if(logsList){
            return (
                <React.Fragment>
                        {_.map(logsList, (l, i) => {
                            return (
                                <Grid container spacing={2} py={1}>
                                    <Grid item xs={12} md={2}>
                                        <Div sx={{display: 'flex', alignItems: 'center'}}>
                                            <CalendarMonthRoundedIcon fontSize="small" color={"text.secondary"}/>
                                            <Typography variant="body2" color={"text.secondary"}>{moment(l.created_at).format('DD MMM HH:mm:ss')}</Typography>
                                        </Div>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Typography color={l.status == "error" ? "error" : "success.dark"}>{l.category}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <Typography>{l.data}</Typography>
                                    </Grid>
                                </Grid>
                            )
                        })}
                </React.Fragment>
            )
        }
    }
    
    return (
        <div>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={3}
                sx={{mb: 2}}>
                <Typography variant="h2">Logs</Typography>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={3}>                    
                    <Search sx={{maxWidth: { sm: 200, md: 350 }}}>
                        <SearchIconWrapper>
                            <SearchIcon/>
                        </SearchIconWrapper>

                        <StyledInputBase
                            placeholder="Buscar"
                            inputProps={{'aria-label': 'search'}}
                            sx={{background: 'white'}}
                            onChange={(e) => {
                                debounceSearchQueryChange(e.target.value)
                            }}
                            onKeyDown={(e) => {
                                if (e.key == 'Enter') {
                                    handleSearchQueryChange(e.target.value)
                                }
                            }}
                        />
                    </Search>
                </Stack>
            </Stack>

            <Div sx={{display: listLoading ? 'block' : 'none', textAlign: 'center', my: 5}}>
                <CircularProgress color="warning"/>
            </Div>

            <Card sx={{mb: 2 }}>
                <CardContent sx={{ p: 1 }}>
                    {renderLogsList()}
                </CardContent>
            </Card>

            <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageSelect={(page) => { setCurrentPage(page); setListLoading(true)}}/>
        </div>
    );
};

export default Customers;