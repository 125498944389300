import React, { useEffect, useState } from 'react';
import {CircularProgress, Grid, List, Stack, TextField, Typography} from "@mui/material";
import _ from "lodash";
import Div from "@jumbo/shared/Div";
import pluginServices from 'app/services/plugin-services';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import JumboScrollbar from '@jumbo/components/JumboScrollbar';
import companyServices from 'app/services/company-services';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import PluginListItem from './PluginListItem';
import { LoadingButton } from '@mui/lab';


const VaryingField = (props) => {
    if(props.field.type === 'string' || props.field.type === 'password') {
        return(
            <TextField
                fullWidth
                type={props.field.type === 'password' ? 'password' : 'text'}
                required={props.field.required}
                label={props.field.label}
                value={props.value || ''}
                onChange={(e) => props.onChange(e)}
                helperText={props.field.help || ''}
                variant="outlined"
                sx={{mt: 2}}/>
        )
    }
}

const PluginsIndex = () => {
    const {authUser} = useJumboAuth();

    const [pluginsList, setPluginsList] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [searchQuery, setSearchQuery] = useState('')
    const [listLoading, setListLoading] = useState(false)
    const [currentPlugin, setCurrentPlugin] = useState(null)
    const [currentCompanyPlugin, setCurrentCompanyPlugin] = useState(null)
    
    const [companyPlugins, setCompanyPlugins] = useState([])
    const [companyPluginsLoading, setCompanyPluginsLoading] = useState(false)

    const [pluginInToggleInstallation, setPluginInToggleInstallation] = useState(null)
    const [pluginInToggleActivation, setPluginInToggleActivation] = useState(null)

    const [pluginConfig, setPluginConfig] = useState({})

    const [saving, setSaving] = useState(false)

    useEffect(() => {
        if(listLoading){
            loadPlugins()
        }
        if(companyPluginsLoading){
            loadCompanyPlugins()
        }
    }, [listLoading])

    useEffect(() => {
        setCompanyPluginsLoading(true)
        setListLoading(true)
    }, [])

    useEffect(() => {
        var newPC = currentCompanyPlugin?.data_values
        _.each(newPC, (v,k) => {
            if(currentCompanyPlugin.plugin.data_fields[k].type == 'password') {
                newPC[k] = ''
            }
        })
        setPluginConfig(newPC)
        setCurrentPlugin(currentCompanyPlugin?.plugin)
    }, [currentCompanyPlugin])

    const buildParams = () => {
        let par = []
        let pos = 0
        par.push(encodeURI(`q[m]=and`))
        if(searchQuery && searchQuery !== 0) {
            par.push(encodeURI(`q[g][${pos}][data_cont]=${searchQuery}`))
            pos += 1
        }

        par.push(`page=${currentPage}`)

        par = _.join(par, '&')

        return `?${par}`;
    }

    const handleSearchQueryChange = (newVal) => {
        setCurrentPage(1)
        setSearchQuery(newVal)
        setListLoading(true)
    }

    const debounceSearchQueryChange = _.debounce(handleSearchQueryChange, 450)
    
    const loadPlugins = () => {
        pluginServices.getPlugins(buildParams())
            .then(data => {
                setPluginsList(data.data)
                setTotalPages(data.meta.total_pages)
            })
            .catch(function (error) {})
            .finally(() => {
                setListLoading(false)
            });
    }

    const loadCompanyPlugins = () => {
        if(authUser) {
            companyServices.getCompanyPlugins(authUser?.current_company?.id)
                .then(data => {
                    setCompanyPlugins(data)
                })
                .catch(function (error) {})
                .finally(() => {
                    setCompanyPluginsLoading(false)
                });
        }
    }

    const renderInstalledPluginsList = (company_plugins, _loading=false) => {
        if(company_plugins) {
            return(
                <List disablePadding>
                    {_.map(company_plugins, (cp, i) => {
                        return (
                            <PluginListItem
                                plugin={cp.plugin}
                                enabled={cp.enabled}
                                inLoading={pluginInToggleActivation}
                                enableConfigButton={cp.enabled}
                                onText="Activado"
                                offText="No activado"
                                onToggle={(plug) => { togglePluginActivation(plug) }}
                                onConfig={(plug) => { setCurrentCompanyPlugin(cp) } }/>
                        )
                    })}
                </List>
            )
        } else if(!_loading) {
            return(
                <Typography textAlign="center">No existen datos</Typography>
            )
        }
    }

    const renderPluginsList = (plugins, _loading=false) => {
        if(plugins) {
            return(
                <List disablePadding>
                    {_.map(plugins, (p, i) => {
                        return (
                            <PluginListItem
                                plugin={p}
                                enabled={_.find(companyPlugins, (cp) => { return cp.plugin.id === p.id }) ? true : false}
                                inLoading={pluginInToggleInstallation}
                                onText="Instalado"
                                offText="No instalado"
                                onToggle={(plug) => { togglePluginInstallation(plug) }}/>
                        )
                    })}
                </List>
            )
        } else if(!_loading) {
            return(
                <Typography textAlign="center">No existen datos</Typography>
            )
        }
    }

    const togglePluginInstallation = (plugin) => {
        setPluginInToggleInstallation(plugin.id)
        setCurrentCompanyPlugin(null)
        pluginServices.togglePluginInstallation(plugin.id)
            .then(data => {
                setListLoading(true)
                setCompanyPluginsLoading(true)
            })
            .catch(function (error) {})
            .finally(() => {
                setPluginInToggleInstallation(null)
            });
    }

    const togglePluginActivation = (plugin) => {
        setPluginInToggleActivation(plugin.id)
        setCurrentCompanyPlugin(null)
        pluginServices.togglePluginActivation(plugin.id)
            .then(data => {
                setListLoading(true)
                setCompanyPluginsLoading(true)
            })
            .catch(function (error) {})
            .finally(() => {
                setPluginInToggleActivation(null)
            });
    }

    const savePluginConfig = () => {
        if(currentPlugin) {
            setSaving(true)
            pluginServices.saveConfig(currentPlugin.id, { data_values: pluginConfig })
                .then(data => {
                })
                .catch(function (error) {})
                .finally(() => {
                    setSaving(false)
                });
        }
    }

    const handleFieldChange = (key, field, value) => {
        if(key) {
            var newPluginConfig = {...pluginConfig}
            newPluginConfig[key] = value
            setPluginConfig(newPluginConfig)
        }
    }

    const renderPluginConfig = (plugin) => {
        if(plugin) {
            if(_.keys(plugin.data_fields).length > 0) {
                return(
                    <React.Fragment>
                        {_.map(plugin.data_fields, (field, key) => {
                            if(field.show_in_form) {
                                return(
                                    <VaryingField field={field} value={pluginConfig[key]} help={field.help} onChange={(e) => { handleFieldChange(key, field, e.target.value) }} />
                                )
                            }
                        })}
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{mt: 2}}>
                            <LoadingButton
                                loading={saving}
                                variant="contained"
                                color="primary"
                                size='small'
                                sx={{minWidth: 140}}
                                loadingPosition="end"
                                disabled={saving}
                                onClick={() => savePluginConfig()}>
                                    {saving ? "Guardando" : "Guardar"}
                            </LoadingButton>
                        </Stack>
                    </React.Fragment>
                )
            } else {
                return(
                    <Typography sx={{mt: 2}}>Este plugin no posee parámetros para configurar</Typography>
                )
            }
        }
    }
    
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <JumboCardQuick
                    title="Instalados"
                    headerSx={{
                        pb: 1,
                        '& .MuiCardHeader-action': {
                            my: -.75
                        }
                    }}
                    sx={{mb: 2}}>
                    <JumboScrollbar
                        autoHeight
                        autoHeightMin={200}>
                        <Div sx={{display: companyPluginsLoading ? 'block' : 'none', textAlign: 'center', my: 5}}>
                            <CircularProgress color="warning"/>
                        </Div>
                        {renderInstalledPluginsList(companyPlugins, companyPluginsLoading)}
                    </JumboScrollbar>
                </JumboCardQuick>

                <JumboCardQuick
                    title="Disponibles"
                    headerSx={{
                        pb: 1,
                        '& .MuiCardHeader-action': {
                            my: -.75
                        }
                    }}
                    sx={{mb: 2}}>
                    <JumboScrollbar
                        autoHeight
                        autoHeightMin={200}>
                        <Div sx={{display: listLoading ? 'block' : 'none', textAlign: 'center', my: 5}}>
                            <CircularProgress color="warning"/>
                        </Div>
                        {renderPluginsList(pluginsList, listLoading, "Desinstalar", "Instalar", pluginInToggleInstallation, togglePluginInstallation)}
                    </JumboScrollbar>
                </JumboCardQuick>
            </Grid>
            <Grid item xs={12} md={6}>
                {currentPlugin &&
                    <JumboCardQuick
                        title="Configuración"
                        headerSx={{
                            pb: 1,
                            '& .MuiCardHeader-action': {
                                my: -.75
                            }
                        }}
                        sx={{mb: 2}}>
                        <Typography variant="h5">{currentPlugin.name}</Typography>
                        {renderPluginConfig(currentPlugin)}
                    </JumboCardQuick>
                }
            </Grid>
        </Grid>
    );
};

export default PluginsIndex;