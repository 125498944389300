import React, { useState } from 'react';
import {Card, CardContent, TextField, Typography} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
// import Stack from "@mui/material/Stack";
// import {Facebook, Google, Twitter} from "@mui/icons-material";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import {alpha} from "@mui/material/styles";
import Div from "@jumbo/shared/Div";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import * as yup from 'yup';
import {Form, Formik} from "formik";
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { LoadingButton } from '@mui/lab';
import authServices from 'app/services/auth-services';
import {useNavigate} from "react-router-dom";
import Logo from 'app/shared/Logo';

const validationSchema = yup.object({
    email: yup
        .string('Ingresa tu email')
        .email('Debes ingresar un email válido')
        .required('Debes ingresar un email'),
});

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [restorePasswordEnded, setRestorePasswordEnded] = useState(false)

    const onRestorePassword = (data, {setSubmitting}) => {
        setSubmitting(true);
        authServices.restorePassword({ email: data.email })
            .then((data) => {
                setRestorePasswordEnded(true);
                setSubmitting(false);
            })
            .catch((err) => {
                setSubmitting(false);
            });
    };

    return (
        <Div sx={{
            flex: 1,
            flexWrap: 'wrap',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: theme => theme.spacing(4),
        }}>
            <Logo sx={{mb: 3}} />
            <Card sx={{maxWidth: '100%', width: 360, mb: 4}}>
                <Div sx={{position: 'relative', height: '200px'}}>
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="200"
                        image={`${ASSET_IMAGES}/banner-1.jpg`}
                    />
                    <Div sx={{
                        flex: 1,
                        inset: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: theme => alpha(theme.palette.common.black, .5),
                        p: theme => theme.spacing(3),
                    }}>
                        <Typography
                            variant={"h2"}
                            sx={{
                                color: 'common.white',
                                fontSize: '1.5rem',
                                mb: 0
                            }}>
                            ¿Olvidaste tu contraseña?
                        </Typography>
                    </Div>
                </Div>
                <CardContent>
                    { restorePasswordEnded ?
                        <React.Fragment>
                            <Typography textAlign={"center"} variant={"body1"} mb={1}>Si es que la dirección existe, recibirás un correo en tu casilla con instrucciones para recuperar tu contraseña.</Typography>
                            <Button fullWidth variant="contained" onClick={() => { navigate("/auth/login") } }>Ir a iniciar sesión</Button>
                        </React.Fragment>

                        :

                        <Formik
                            validateOnChange={true}
                            initialValues={{
                                email: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={onRestorePassword}
                        >
                            {({isSubmitting}) => (
                                <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>
                                    <Typography textAlign={"center"} variant={"body1"} mb={3}>Ingresa tu correo y te enviaremos instrucciones para que puedas restablecer tu contraseña.</Typography>
                                    <Div sx={{mb: 3, mt: 1}}>
                                        <JumboTextField
                                            fullWidth
                                            name="email"
                                            label="Email"
                                        />
                                    </Div>
                                    <LoadingButton
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{mb: 3}}
                                        loading={isSubmitting}
                                    >Recuperar contraseña</LoadingButton>
                                </Form>
                            )}
                        </Formik>
                    }
                </CardContent>
            </Card>
        </Div>
    );
};

export default ForgotPassword;
