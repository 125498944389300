import jwtAuthAxios from "./auth/jwtAuth";

const companyServices = {};

companyServices.getCompanySettings = async (id) => {
    const {data} = await jwtAuthAxios.get(`/companies/${id}/settings`);
    return data;
};

companyServices.saveCompanySettings = async (id, params) => {
    var formData = new FormData();

    Object.keys(params).forEach((k) => {
        if(k === 'logo_url') {
        } else if(params[k] !== null && params[k] !== undefined) {
            formData.append(k, params[k]);
        } else {
            formData.append(k, '');
        }
    })
    
    const {data} = await jwtAuthAxios.post(`/companies/${id}/settings`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return data;
};

companyServices.getCompanyPlugins = async (id) => {
    const {data} = await jwtAuthAxios.get(`/companies/${id}/plugins`);
    return data;
};

export default companyServices;