import jwtAuthAxios from "./auth/jwtAuth";

const deliveryZoneServices = {};

deliveryZoneServices.getDeliveryZones = async (params=null) => {
    const {data} = await jwtAuthAxios.get(`/delivery_zones?${params || ''}`);
    return data;
};

deliveryZoneServices.getDeliveryZone = async (id, params=null) => {
    const {data} = await jwtAuthAxios.get(`/delivery_zones/${id}?${params || ''}`);
    return data;
};

deliveryZoneServices.createDeliveryZone = async (params, body) => {
    const {data} = await jwtAuthAxios.post(`/delivery_zones?${params || ''}`, body);
    return data;
};

deliveryZoneServices.updateDeliveryZone = async (id, params, body) => {
    const {data} = await jwtAuthAxios.put(`/delivery_zones/${id}?${params || ''}`, body);
    return data;
};

deliveryZoneServices.destroyDeliveryZone = async (id) => {
    const {data} = await jwtAuthAxios.delete(`/delivery_zones/${id}`);
    return data;
};

export default deliveryZoneServices;