import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import RouteRoundedIcon from '@mui/icons-material/RouteRounded';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import PendingActionsRoundedIcon from '@mui/icons-material/PendingActionsRounded';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export const stateInfo = (_state) => {
    if(_state === 'planned') {
        return { color: `state_${_state}`, icon: <CalendarMonthRoundedIcon/> }
    } else if(_state === 'pending') {
        return { color: `state_${_state}`, icon: <PendingOutlinedIcon/> }
    } else if(_state === 'invoiced') {
        return { color: `state_${_state}`, icon: <AssignmentTurnedInOutlinedIcon/> }
    } else if(_state === 'in_progress') {
        return { color: `state_${_state}`, icon: <RouteRoundedIcon/> }
    } else if(_state === 'finished') {
        return { color: `state_${_state}`, icon: <FlagRoundedIcon/> };
    } else if(_state === 'cancelled') {
        return { color: `state_${_state}`, icon: <CancelOutlinedIcon/> };
    } else if(_state === 'failed') {
        return { color: `state_${_state}`, icon: <CancelOutlinedIcon/> };
    } else {
        return { color: 'secondary', icon: <CalendarMonthRoundedIcon/> };
    }
}