import jwtAuthAxios from "./auth/jwtAuth";

const placeServices = {};

placeServices.getPlaces = async (params) => {
    const {data} = await jwtAuthAxios.get(`/places${params}`);
    return data;
};

placeServices.getPlace = async (id, params=null) => {
    const {data} = await jwtAuthAxios.get(`/places/${id}${params ? `?${params}` : ''}`);
    return data;
};

placeServices.saveGeofence = async (id, params) => {
    const {data} = await jwtAuthAxios.post(`/places/${id}/geofence`, params);
    return data;
};

placeServices.getReceiptsPins = async (id) => {
    const {data} = await jwtAuthAxios.get(`/places/${id}/receipts_pins`);
    return data;
};

export default placeServices;