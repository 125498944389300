import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TextField, Grid, CircularProgress, Paper, Autocomplete } from "@mui/material";
import _ from "lodash";
import Div from "@jumbo/shared/Div";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { LoadingButton } from '@mui/lab';
import vehicleServices from 'app/services/vehicle-services';

const VehicleEditModal = props => {
    const {recordID, isOpen, onClose=()=>{}, onRecordChange=()=>{}, onRecordAdd=()=>{}} = props;

    const [currentRecord, setCurrentRecord] = useState(null)
    const [changed, setChanged] = useState(false)
    const [editOptions, setEditOptions] = useState(null)
    const [loadingRecord, setLoadingRecord] = useState(false)
    const [saving, setSaving] = useState(false)

    useEffect(() => {
        if(recordID){
            setChanged(false)
            loadRecord(recordID)
        } else {
            vehicleServices.getNewVehicle('?include=transport_unit1,transport_unit2').then(resp => { setCurrentRecord(resp) });
        }
        if(!editOptions){
            vehicleServices.getEditOptions().then(resp => { setEditOptions(resp) });
        }
    }, [recordID])

    const loadRecord = (id) => {
        if(!id) return
        setLoadingRecord(true)
        setCurrentRecord(null)
        vehicleServices.getVehicle(id, '?include=transport_unit1,transport_unit2')
            .then(resp => {
                setCurrentRecord(resp)
                setLoadingRecord(false)
            })
            .catch(error => {
                setLoadingRecord(false)
            });
    }

    const saveRecord = () => {
        setSaving(true)
        if(currentRecord.id){
            vehicleServices.saveVehicle(currentRecord.id, "?include=transport_unit1,transport_unit2", { vehicle: currentRecord })
                .then(resp => {
                    onRecordChange(resp)
                    onClose()
                    setSaving(false)
                })
                .catch(function (error) {
                    setSaving(false)
                });
        } else {
            vehicleServices.createVehicle("?include=transport_unit1,transport_unit2", { vehicle: currentRecord })
                .then(resp => {
                    onRecordAdd(resp)
                    onClose()
                    setSaving(false)
                })
                .catch(function (error) {
                    setSaving(false)
                });
        }
    }

    const renderTransportHeaderFields = () => {
        return (
            <Grid container spacing={1} alignItems={"baseline"} sx={{pt: 1, mb: 3}}>
                <Grid item xs={6} lg={4}>
                    <TextField
                        autoFocus
                        fullWidth
                        label="Código"
                        size="small"
                        required
                        error={!currentRecord?.code}
                        value={currentRecord?.code || ''}
                        onChange={(e) => setCurrentRecord({...currentRecord, code: e.target.value})}
                    />
                </Grid>
                <Grid item xs={6} lg={4}>
                    <Autocomplete
                        size="small"
                        freeSolo
                        blurOnSelect
                        options={editOptions?.vehicle_kinds || []}
                        getOptionLabel={(option) => `${option[2]} - ${option[0]}`}
                        value={_.find(editOptions?.vehicle_kinds, ['1', currentRecord?.kind]) }
                        onChange={(e, v) => { if(_.get(v, '1')) { setCurrentRecord({ ...currentRecord, kind: v[1] }) } }}
                        renderInput={(params) => <TextField {...params} label="Tipo *" sx={{background: 'white'}} />}
                    />
                </Grid>
                <Grid item xs={6} lg={4}>
                    <Autocomplete
                        size="small"
                        freeSolo
                        blurOnSelect
                        options={editOptions?.centres || []}
                        getOptionLabel={(option) => `${option.code} - ${option.name}`}
                        value={_.find(editOptions?.centres, ['id', currentRecord?.centre_id]) }
                        onChange={(e, v) => { setCurrentRecord({ ...currentRecord, centre_id: v?.id }) }}
                        renderInput={(params) => <TextField {...params} label="Centro *" sx={{background: 'white'}} />}
                    />
                </Grid>
            </Grid>
        )
    }
    
    const renderTransportUnitFields = (unit) => {
        return (

            <Paper variant="outlined" sx={{mt:1, p: 2}}>
                <Grid container spacing={1} alignItems={"baseline"}>
                    <Grid item xs={6} lg={4}>
                        <TextField
                            autoFocus
                            fullWidth
                            label="Placa patente"
                            size="small"
                            error={!_.get(currentRecord, `transport_unit${unit}.ppu`)}
                            value={_.get(currentRecord, `transport_unit${unit}.ppu`) || ''}
                            onChange={(e) => {
                                var newObj = _.set(currentRecord, `transport_unit${unit}.ppu`, e.target.value);
                                setCurrentRecord({...newObj})
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <Autocomplete
                            size="small"
                            freeSolo
                            blurOnSelect
                            options={editOptions?.transport_unit_kinds || []}
                            getOptionLabel={(option) => `${option[2]} - ${option[0]}`}
                            value={_.find(editOptions?.transport_unit_kinds, ['1', _.get(currentRecord, `transport_unit${unit}.kind`)]) }
                            onChange={(e, v) => {
                                if(_.get(v, '1')) {
                                    var newObj = _.set(currentRecord, `transport_unit${unit}.kind`, v[1]);
                                    setCurrentRecord({...newObj})
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="Tipo" sx={{background: 'white'}} />}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems={"baseline"} sx={{pt: 1}}>
                    <Grid item xs={6} lg={3}>
                        <TextField
                            autoFocus
                            fullWidth
                            label="Marca"
                            size="small"
                            value={_.get(currentRecord, `transport_unit${unit}.brand`) || ''}
                            onChange={(e) => {
                                var newObj = _.set(currentRecord, `transport_unit${unit}.brand`, e.target.value);
                                setCurrentRecord({...newObj})
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <TextField
                            autoFocus
                            fullWidth
                            label="Modelo"
                            size="small"
                            value={_.get(currentRecord, `transport_unit${unit}._model`) || ''}
                            onChange={(e) => {
                                var newObj = _.set(currentRecord, `transport_unit${unit}._model`, e.target.value);
                                setCurrentRecord({...newObj})
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <TextField
                            autoFocus
                            fullWidth
                            label="Versión"
                            size="small"
                            value={_.get(currentRecord, `transport_unit${unit}._version`) || ''}
                            onChange={(e) => {
                                var newObj = _.set(currentRecord, `transport_unit${unit}._version`, e.target.value);
                                setCurrentRecord({...newObj})
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <TextField
                            autoFocus
                            fullWidth
                            label="Año"
                            size="small"
                            value={_.get(currentRecord, `transport_unit${unit}.year`) || ''}
                            onChange={(e) => {
                                var newObj = _.set(currentRecord, `transport_unit${unit}.year`, e.target.value);
                                setCurrentRecord({...newObj})
                            }}
                        />
                    </Grid>

                    <Grid item xs={6} lg={3}>
                        <TextField
                            autoFocus
                            fullWidth
                            label="Número de ejes"
                            size="small"
                            value={_.get(currentRecord, `transport_unit${unit}.axes_number`) || ''}
                            onChange={(e) => {
                                var newObj = _.set(currentRecord, `transport_unit${unit}.axes_number`, e.target.value);
                                setCurrentRecord({...newObj})
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <TextField
                            autoFocus
                            fullWidth
                            label="Tara"
                            size="small"
                            value={_.get(currentRecord, `transport_unit${unit}.tare`) || ''}
                            onChange={(e) => {
                                var newObj = _.set(currentRecord, `transport_unit${unit}.tare`, e.target.value);
                                setCurrentRecord({...newObj})
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <TextField
                            autoFocus
                            fullWidth
                            label="Carga máxima (kg)"
                            size="small"
                            value={_.get(currentRecord, `transport_unit${unit}.max_load_weight`) || ''}
                            onChange={(e) => {
                                var newObj = _.set(currentRecord, `transport_unit${unit}.max_load_weight`, e.target.value);
                                setCurrentRecord({...newObj})
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <TextField
                            autoFocus
                            fullWidth
                            label="Volumen máximo (M3)"
                            size="small"
                            value={_.get(currentRecord, `transport_unit${unit}.max_load_volume`) || ''}
                            onChange={(e) => {
                                var newObj = _.set(currentRecord, `transport_unit${unit}.max_load_volume`, e.target.value);
                                setCurrentRecord({...newObj})
                            }}
                        />
                    </Grid>
                    
                </Grid>
            </Paper>
        )
    }

    const modalTitle = () => {
        if(currentRecord){
            return currentRecord.code
        } else {
            return loadingRecord ? 'Cargando' : 'Nuevo vehículo'
        }
    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={isOpen}
                onClose={onClose}>
                <DialogTitle>
                    <Typography variant={"h2"} color="primary">{modalTitle}</Typography>
                </DialogTitle>
                <DialogContent>
                    { loadingRecord ?
                        <Div sx={{ textAlign: 'center' }}>
                            <CircularProgress />
                        </Div>
                    :
                        <React.Fragment>
                            <Typography variant={"h4"} color="primary">Datos generales</Typography>
                            { renderTransportHeaderFields() }

                            <Typography variant={"h4"} color="primary">Unidades de transporte</Typography>
                            { renderTransportUnitFields(1) }
                            { currentRecord?.kind == 'composed' && renderTransportUnitFields(2) }

                            {/* { true ?
                                renderTransportUnitFields(2)
                                :
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    sx={{mt: 1}}
                                    startIcon={<ControlPointOutlinedIcon/>}
                                    onClick={() => setCurrentRecord({...currentRecord, transport_unit2: {}})}>
                                        Agregar unidad de transporte
                                </Button>
                            } */}
                        </React.Fragment>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { onClose() } }>Cerrar</Button>
                    <LoadingButton
                        loading={saving}
                        variant="contained"
                        color="success"
                        size="small"
                        endIcon={<SaveOutlinedIcon/>}
                        loadingPosition="end"
                        onClick={saveRecord}>
                            { saving ? 'Guardando' : 'Guardar' }
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default VehicleEditModal;