import Div from '@jumbo/shared/Div/Div';
import Span from '@jumbo/shared/Span/Span';
import _ from 'lodash';
import React, { useEffect } from 'react';

const SignatureCanvas = ({ signatureData, height=300, width=300, margin_x=10, margin_y=10, caption=null }) => {
  useEffect(() => {
    const canvas = document.getElementById('signatureCanvas');
    const ctx = canvas.getContext('2d');

    ctx.strokeStyle = 'black';
    ctx.lineWidth = 2;

    // Example of signatureData:
    // [{ 
    //     color:rgb(34, 36, 40),
    //     points:[
    //         {time:1693329046377,x:166.85714721679688,y:223.85714721679688},
    //         {time:1693329046447,x:158.33636474609375,y:200.60574340820312},
    //         {time:1693329046463,x:153.73081970214844,y:178.84652709960938}
    //     ]
    // }]

    var points = _.flatten(_.map(signatureData, (p1) => { return p1.points }))
    
    const x_s = _.map(points, 'x')
    const y_s = _.map(points, 'y')
    const min_x = _.min(x_s), max_x = _.max(x_s)
    const min_y = _.min(y_s), max_y = _.max(y_s)
    const scale_factor_x = _.round(height / (max_x - min_x + 2*margin_x), 1)
    const scale_factor_y = _.round(height / (max_y - min_y + 2*margin_y), 1)
    const scale_factor = _.min([scale_factor_x, scale_factor_y])
    


    _.each(signatureData, (sd) => {
      var currPoints = _.map(sd.points, (p1) => {
          return {
              x: _.round(margin_x + (p1.x - min_x) * scale_factor, 1),
              y: _.round(margin_y + (p1.y - min_y) * scale_factor, 1)
          }
      })

      ctx.beginPath();
      ctx.moveTo(currPoints[0].x, currPoints[0].y);

      for (let i = 1; i < currPoints.length; i++) {
        ctx.lineTo(currPoints[i].x, currPoints[i].y);
      }

      ctx.stroke();
    })
  }, [signatureData]);

  return (
    <Span sx={{position: 'relative'}}>
        <canvas
          id="signatureCanvas"
          width={width} // ajusta el ancho del lienzo según tus necesidades
          height={height} // ajusta el alto del lienzo según tus necesidades
          style={{ border: '1px solid grey', borderRadius: 4 }}
        />
        { caption && <Span sx={{position: 'absolute', bottom: 4, right: 4}}>{caption}</Span> }
    </Span>
  );
};

export default SignatureCanvas;