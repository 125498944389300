import React, { useEffect, useRef, useState } from 'react';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { Card, CardContent, CircularProgress, Grid, Typography, useTheme, List, Stack, IconButton, AvatarGroup, Avatar, Tooltip, Divider, CardActions, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from "@mui/material";
import { Search, SearchIconWrapper, StyledInputBase } from "app/shared/SearchGlobal/style";
import _ from "lodash";
import Div from "@jumbo/shared/Div";
import SearchIcon from "@mui/icons-material/Search";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import taskAuthorizationServices from 'app/services/task-authorizacion-services';
import TaskAuthorizationsGroup from './TaskAuthorizationsGroup';
import { LoadingButton } from '@mui/lab';
// import UserEditModal from './UserEditModal';


const TaskAuthorizationsIndex = () => {
    // const Swal = useSwalWrapper();
    // const theme = useTheme();
    const [searchQuery, setSearchQuery] = useState('')
    const [recordsListA, setRecordsListA] = useState([])
    const [recordsListB, setRecordsListB] = useState([])
    const [currentPageA, setCurrentPageA] = useState(1)
    const [currentPageB, setCurrentPageB] = useState(1)
    const [totalPagesA, setTotalPagesA] = useState(1)
    const [totalPagesB, setTotalPagesB] = useState(1)
    const [totalCountA, setTotalCountA] = useState(0)
    const [totalCountB, setTotalCountB] = useState(0)
    const [listLoadingA, setListLoadingA] = useState(false)
    const [listLoadingB, setListLoadingB] = useState(false)
    const [currentRecord, setCurrentRecord] = useState(null)
    const [saving, setSaving] = useState(false)
    const [openRecordConfirmationModal, setOpenRecordConfirmationModal] = useState(false)
    const [authObservation, setAuthObservation] = useState(null)

    useEffect(() => {
        if(listLoadingA){
            loadRecordsA()
        }
    }, [listLoadingA])

    useEffect(() => {
        if(listLoadingB){
            loadRecordsB()
        }
    }, [listLoadingB])

    useEffect(() => {
        setListLoadingA(true)
        setListLoadingB(true)
    }, [])

    const buildRecordParams = (extra) => {
        let par = []
        if(extra) par.push(extra)

        if(searchQuery && searchQuery !== 0) {
            par.push(`q%5Brequested_by_first_name_or_requested_by_last_name_or_authorized_by_first_name_or_authorized_by_last_name_or_delivery_code_or_delivery_order_code_cont%5D=${searchQuery}`)
        }
        par.push(`include=delivery.order,delivery.delivery_details.order_detail,requested_by,authorized_by`)

        par = _.join(par, '&')

        return `?${par}`;
    }

    const loadRecordsA = () => {
        taskAuthorizationServices.getAuthorizations(buildRecordParams(`q%5Bstate_eq%5D=pending&page=${currentPageA}`))
            .then(resp => {
                setRecordsListA(resp.data)
                setTotalPagesA(resp.meta.total_pages)
                setTotalCountA(resp.meta.total_count)
            })
            .catch(function (error) {})
            .finally(() => {
                setListLoadingA(false)
            });
    }

    const loadRecordsB = () => {
        taskAuthorizationServices.getAuthorizations(buildRecordParams(`q%5Bstate_not_eq%5D=pending&q%5Bs%5D=authorized_at+desc&page=${currentPageB}`))
            .then(resp => {
                setRecordsListB(resp.data)
                setTotalPagesB(resp.meta.total_pages)
                setTotalCountB(resp.meta.total_count)
            })
            .catch(function (error) {})
            .finally(() => {
                setListLoadingB(false)
            });
    }

    const handleSearchQueryChange = (newVal) => {
        setCurrentPageA(1)
        setCurrentPageB(1)
        setSearchQuery(newVal)
        setListLoadingA(true)
        setListLoadingB(true)
    }

    const handleAuth = (record) => {
        setCurrentRecord(record)
        setOpenRecordConfirmationModal(true)
    }

    const saveAuth = (authAction) => {
        setSaving(true)
        taskAuthorizationServices.saveAuthorization(currentRecord.id, { task_authorization: { authorize: authAction, authorization_observation: authObservation }})
            .then(resp => {
                setOpenRecordConfirmationModal(false)
                setCurrentRecord(null)
                setListLoadingA(true)
                setListLoadingB(true)
            })
            .catch(function (error) {})
            .finally(() => {
                setSaving(false)
            });        
    }

    const onConfirmationModalClose = () => {
        setCurrentRecord(null)
        setAuthObservation(null)
        setOpenRecordConfirmationModal(false)
    }

    // const authActionToText = (action) => {
    //     switch(action){
    //         case 'authorized':
    //             return 'Autorizar'
    //         case 'partially_authorized':
    //             return 'Autorizar parcial'
    //         case 'unauthorized':
    //             return 'Rechazar'
    //         default:
    //             return ''
    //     }
    // }

    const debounceSearchQueryChange = _.debounce(handleSearchQueryChange, 450)

    const renderConfirmationModal = () => {
        if(!currentRecord) return null
        return (
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={openRecordConfirmationModal}
                onClose={onConfirmationModalClose}>
                <DialogTitle>
                    <Typography variant={"h2"} sx={{mb: 0}} color="primary">Solicitud {currentRecord.delivery?.code} {currentRecord.delivery?.order && `(P: ${currentRecord.delivery?.order?.code})`}</Typography>
                </DialogTitle>
                <IconButton
                aria-label="close"
                onClick={onConfirmationModalClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    { currentRecord ?
                        <React.Fragment>
                            <Typography variant={"h4"} color="primary">Detalle picking</Typography>
                            <List sx={{ width: '100%' }} dense={true}>
                                {currentRecord.delivery?.delivery_details && currentRecord.delivery.delivery_details.map((d) => (
                                    <ListItem
                                    key={d.id}
                                    alignItems="flex-start"
                                    id={d.id}>
                                        <ListItemText id={d.id} 
                                            primary={
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    spacing={1}
                                                    // sx={{ maxWidth: 500 }}
                                                >
                                                    <Typography>{d.product}</Typography>
                                                    <Stack direction="row" alignItems="center" style={{width: 100}}>
                                                        <Inventory2OutlinedIcon fontSize="small" color={"primary"} />
                                                        <Tooltip title="Cantidad original / Cantidad pickeada" arrow>
                                                            <Typography variant="overline" style={{lineHeight: 1, cursor: 'default'}}>{d.qty?.toLocaleString('es-CL')}/{d.picked_qty?.toLocaleString('es-CL')}</Typography>
                                                        </Tooltip>
                                                    </Stack>
                                                </Stack>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>

                            <Typography variant={"h4"} color="primary">Observaciones</Typography>
                            <TextField
                                autoFocus
                                fullWidth
                                multiline
                                rows={2}
                                sx={{mt: 1}}
                                placeholder="(Opcional)"
                                value={authObservation || ''}
                                onChange={(e) => setAuthObservation(e.target.value)}
                            />
                        </React.Fragment>
                    :
                        <Div sx={{ textAlign: 'center' }}>
                            <CircularProgress />
                        </Div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={onConfirmationModalClose}>Cerrar</Button>
                    {/* <LoadingButton
                        loading={saving}
                        variant="contained"
                        color="success"
                        size="small"
                        endIcon={<SaveOutlinedIcon/>}
                        loadingPosition="end"
                        type="submit"
                        onClick={saveAuth}>
                            { saving ? 'Guardando' : 'Guardar' }
                    </LoadingButton> */}
                    <Button variant={"contained"} color={"success"} size={"small"} onClick={() => { saveAuth('authorized') }}>Autorizar</Button>
                    <Button variant={"contained"} color={"warning"} size={"small"} onClick={() => { saveAuth('partially_authorized') }}>Autorizar y modificar</Button>
                    <Button variant={"contained"} color={"error"} size={"small"} onClick={() => { saveAuth('unauthorized') }}>Rechazar</Button>
                </DialogActions>
            </Dialog>
        )
    }
    
    return (
        <div>
            { renderConfirmationModal() }
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={3}
                sx={{mb: 2}}>
                <Typography variant="h2">Autorizaciones</Typography>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={3}>
                    
                    <Search sx={{maxWidth: { sm: 200, md: 350 }}}>
                        <SearchIconWrapper>
                            <SearchIcon/>
                        </SearchIconWrapper>

                        <StyledInputBase
                            placeholder="Buscar"
                            inputProps={{'aria-label': 'search'}}
                            sx={{background: 'white'}}
                            onChange={(e) => {
                                debounceSearchQueryChange(e.target.value)
                            }}
                            onKeyDown={(e) => {
                                if (e.key == 'Enter') {
                                    handleSearchQueryChange(e.target.value)
                                }
                            }}
                        />
                    </Search>
                </Stack>
            </Stack>

            <Grid container spacing={3.75}>
                <Grid item xs={12} lg={6}>
                    <TaskAuthorizationsGroup
                        title={`Solicitudes pendientes (${totalCountA})`}
                        records={recordsListA}
                        currentPage={currentPageA}
                        totalPages={totalPagesA}
                        loading={listLoadingA}
                        onFocus={handleAuth}
                        onPageChange={(page) => { setCurrentPageA(page); setListLoadingA(true); }}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TaskAuthorizationsGroup
                        title={`Resueltos (${totalCountB})`}
                        records={recordsListB}
                        currentPage={currentPageB}
                        totalPages={totalPagesB}
                        loading={listLoadingB}
                        onPageChange={(page) => { setCurrentPageB(page); setListLoadingB(true); }}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default TaskAuthorizationsIndex;