import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TextField } from "@mui/material";
import _ from "lodash";
import Div from "@jumbo/shared/Div";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import deliveryServices from 'app/services/delivery-services';

const DeliveryScheduleForm = props => {
    const [saving, setSaving] = useState(false)
    const [newStart, setNewStart] = useState(null)
    const [newEnd, setNewEnd] = useState(null)

    useEffect(() => {
        setNewStart(props.delivery?.preferred_arrival_start)
        setNewEnd(props.delivery?.preferred_arrival_end)
    }, [])

    const {delivery} = props;

    const saveDelivery = () => {
        setSaving(true);
        deliveryServices.updateDelivery(delivery?.id, {
            delivery: {
                preferred_arrival_start: newStart,
                preferred_arrival_end: newEnd
            }
        }).then((data) => {
            delivery.preferred_arrival_start = newStart;
            delivery.preferred_arrival_end = newEnd;
            delivery.meets_preferred_time_window = data?.meets_preferred_time_window;
            setSaving(false);
            props.onSave(data)
        }).catch((err) => {
            setSaving(false);
        })
    }

    const handleScheduleChange = (field, newVal) => {
        // setCanSave(true)
        if(newVal) {
            newVal = `${_.padStart(newVal.getHours(), 2, '0')}:${_.padStart(newVal.getMinutes(), 2, '0')}`
        }
        if(field === 'preferred_arrival_start') {
            setNewStart(newVal)
        } else {
            setNewEnd(newVal)
        }
    }

    const timeStringToDate = (timeStr) => {
        // Time string in format "HH:MM"
        if(timeStr) {
            var d = new Date()
            d.setHours(timeStr.substr(0,timeStr.indexOf(":")));
            d.setMinutes(timeStr.substr(timeStr.indexOf(":")+1));
            d.setSeconds(0);
            return d;
        } else {
            return null;
        }
    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth={false}
                maxWidth={"sm"}
                open={props.isOpen}
                onClose={() => {
                    if(saving){ return; }
                    props.onClose()
                }}>
                <DialogTitle>
                    <Typography variant={"h2"} color="primary">Fijar horario recomendado de entrega</Typography>
                </DialogTitle>
                <DialogContent>
                    <Div sx={{ maxWidth: '100%', pt: 3 }}>
                        <Div sx={{display: 'flex', alignItems: 'start', justifyContent: 'space-between', mb: 2}}>
                            <LocalizationProvider size="small" dateAdapter={AdapterDateFns}>
                                <TimePicker
                                    label="Desde"
                                    ampm={false}
                                    slotProps={{ actionBar: { actions: ['clear', 'accept'] } }}
                                    value={timeStringToDate(newStart)}
                                    onChange={(newValue) => { handleScheduleChange('preferred_arrival_start', newValue) }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <TimePicker
                                    label="Hasta"
                                    ampm={false}
                                    slotProps={{ actionBar: { actions: ['clear', 'accept'] } }}
                                    value={timeStringToDate(newEnd)}
                                    onChange={(newValue) => { handleScheduleChange('preferred_arrival_end', newValue) }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Div>
                    </Div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        if(saving){ return; }
                        props.onClose()
                    }}>Cerrar</Button>
                    <LoadingButton
                        loading={saving}
                        variant="contained"
                        color='primary'
                        size='small'
                        endIcon={<SaveOutlinedIcon/>}
                        loadingPosition="end"
                        onClick={() => saveDelivery()}>
                            { saving ? 'Guardando' : 'Guardar' }
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default DeliveryScheduleForm;