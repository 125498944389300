import React, { useEffect, useState } from 'react';
import { Card, CardContent, CircularProgress, Grid, Typography, List, Stack, IconButton, AvatarGroup, Avatar, Tooltip } from "@mui/material";
import { Search, SearchIconWrapper, StyledInputBase } from "app/shared/SearchGlobal/style";
import _ from "lodash";
import { getAvatarInitials } from "app/utils/appHelpers";
import Div from "@jumbo/shared/Div";
import userServices from "app/services/user-services";
import SearchIcon from "@mui/icons-material/Search";
import CustomPagination from 'app/widgets/Shared/Pagination/CustomPagination';
import { HOST } from 'app/utils/constants/paths';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import UserEditModal from './UserEditModal';


const UsersIndex = () => {
    // const Swal = useSwalWrapper();
    // const theme = useTheme();
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [usersList, setUsersList] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [listLoading, setListLoading] = useState(false)
    const [currentUserId, setCurrentUserId] = useState(null)
    const [openUserEditModal, setOpenUserEditModal] = useState(false)

    useEffect(() => {
        if(listLoading){
            loadUsers()
        }
    }, [listLoading])

    useEffect(() => {
        setListLoading(true)
    }, [])

    const buildUsersParams = () => {
        let par = []
        let pos = 0
        par.push(encodeURI(`q[m]=and`))
        if(searchQuery && searchQuery !== 0) {
            par.push(encodeURI(`q[g][${pos}][first_name_or_last_name_or_email_or_code_cont]=${searchQuery}`))
            pos += 1
        }

        par.push(`page=${currentPage}`)
        par.push(`include=companies`)

        par = _.join(par, '&')

        return `?${par}`;
    }

    const loadUsers = () => {
        userServices.getUsers(buildUsersParams())
            .then(resp => {
                setUsersList(resp.data)
                setTotalPages(resp.meta.total_pages)
            })
            .catch(function (error) {})
            .finally(() => {
                setListLoading(false)
            });
    }

    const handleSearchQueryChange = (newVal) => {
        setCurrentPage(1)
        setSearchQuery(newVal)
        setListLoading(true)
    }

    const handleUserChange = (newUser) => {
        setUsersList(_.map(usersList, (u) => { return u.id == newUser.id ? newUser : u }))
    }

    const debounceSearchQueryChange = _.debounce(handleSearchQueryChange, 450)

    const renderUsersList = () => {
        if(usersList){
            return (
                <React.Fragment>
                    <UserEditModal userID={currentUserId} isOpen={openUserEditModal} onClose={(changed, userOutput) => { setOpenUserEditModal(false) }} onUserChange={handleUserChange} />
                    <List disablePadding>
                        {_.map(usersList, (u, i) => {
                            return (
                                <React.Fragment key={u.id}>
                                    <Card sx={{mb: 2}}>
                                        <CardContent sx={{display: 'flex'}}>
                                            <Grid container spacing={5}>
                                                <Grid item xs={12} md={4}>
                                                    <Stack
                                                        direction="column"
                                                        justifyContent="start"
                                                        alignItems="left"
                                                        spacing={0.5}>
                                                        <Typography variant={"overline"} color="primary">{u.email}</Typography>
                                                        <Typography variant={"button"} color="primary">{u.first_name} {u.last_name}</Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Stack
                                                        direction="column"
                                                        justifyContent="start"
                                                        alignItems="left"
                                                        spacing={0.5}>

                                                        <Typography variant={"overline"}>Empresas asociadas</Typography>
                                                        { u.companies?.length <= 0 && <Typography variant={"body"}>-</Typography> }
                                                        <AvatarGroup max={3} sx={{justifyContent: 'flex-end', cursor: 'pointer', '.MuiAvatarGroup-avatar': { backgroundColor: '#EEEEEE' }}}>
                                                            { _.map(_.uniqBy(u.companies, 'id'), (c) => {
                                                                return (
                                                                    <Tooltip title={c.name} key={`${u.id}_${c.id}`}>
                                                                        { c.logo_url ?
                                                                            <Avatar src={`${HOST}${c.logo_url}`} />
                                                                            :
                                                                            <Avatar alt={c.name}>
                                                                                {getAvatarInitials(c.name)}
                                                                            </Avatar>
                                                                        }
                                                                    </Tooltip>
                                                                )
                                                            })}
                                                        </AvatarGroup>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} md={3} style={{height: '100%'}}>
                                                </Grid>
                                                <Grid item xs={12} md={1} style={{height: '100%'}}>
                                                    <IconButton onClick={() => { setCurrentUserId(u.id); setOpenUserEditModal(true) }}>
                                                        <EditOutlinedIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </React.Fragment>
                            )
                        })}
                    </List>
                </React.Fragment>
            )
        } else {
            return(
                <Typography>
                    No se encontraron datos
                </Typography>
            )
        }
    }
    
    return (
        <div>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={3}
                sx={{mb: 2}}>
                <Typography variant="h2">Usuarios</Typography>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={3}>
                    
                    <Search sx={{maxWidth: { sm: 200, md: 350 }}}>
                        <SearchIconWrapper>
                            <SearchIcon/>
                        </SearchIconWrapper>

                        <StyledInputBase
                            placeholder="Buscar"
                            inputProps={{'aria-label': 'search'}}
                            sx={{background: 'white'}}
                            onChange={(e) => {
                                debounceSearchQueryChange(e.target.value)
                            }}
                            onKeyDown={(e) => {
                                if (e.key == 'Enter') {
                                    handleSearchQueryChange(e.target.value)
                                }
                            }}
                        />
                    </Search>
                </Stack>
            </Stack>

            <Div sx={{display: listLoading ? 'block' : 'none', textAlign: 'center', my: 5}}>
                <CircularProgress color="warning"/>
            </Div>
            <Grid container spacing={3.75}>
                <Grid item xs={12}>
                    {renderUsersList()}
                </Grid>
            </Grid>
            <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageSelect={(page) => { setCurrentPage(page); setListLoading(true)}}/>
        </div>
    );
};

export default UsersIndex;