import React, { useState } from 'react';
import {Card, CardContent, Typography} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
// import Stack from "@mui/material/Stack";
// import {Facebook, Google, Twitter} from "@mui/icons-material";
import {alpha} from "@mui/material/styles";
import Div from "@jumbo/shared/Div";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import * as yup from 'yup';
import {Form, Formik} from "formik";
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { LoadingButton } from '@mui/lab';
import authServices from 'app/services/auth-services';
import {useNavigate, useSearchParams} from "react-router-dom";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { defaultSwalPreferences } from "app/utils/appHelpers";
import Logo from 'app/shared/Logo';

const validationSchema = yup.object({
    password: yup
        .string('Ingresa tu contraseña')
        .required('Debes ingresar una contraseña'),
    password_confirm: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Contraseñas deben ser iguales')
});

const ResetPassword = () => {
    const Swal = useSwalWrapper();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [restorePasswordEnded, setRestorePasswordEnded] = useState(false)

    const onResetPassword = (resetOps) => {
        authServices.resetPassword({ ...resetOps, reset_token: searchParams.get("reset_token")})
            .then((data) => {
                Swal.mixin(defaultSwalPreferences(Swal)).fire({
                    icon: 'success',
                    title: 'Tu contraseña ha cambiado.',
                    background: 'success',
                });
                navigate("/auth/login");
            });
    };

    return (
        <Div sx={{
            flex: 1,
            flexWrap: 'wrap',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: theme => theme.spacing(4),
        }}>
            <Logo sx={{mb: 3}} />
            <Card sx={{maxWidth: '100%', width: 360, mb: 4}}>
                <Div sx={{position: 'relative', height: '200px'}}>
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="200"
                        image={`${ASSET_IMAGES}/banner-1.jpg`}
                    />
                    <Div sx={{
                        flex: 1,
                        inset: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: theme => alpha(theme.palette.common.black, .5),
                        p: theme => theme.spacing(3),
                    }}>
                        <Typography
                            variant={"h2"}
                            sx={{
                                color: 'common.white',
                                fontSize: '1.5rem',
                                mb: 0
                            }}>
                            Restablecer contraseña
                        </Typography>
                    </Div>
                </Div>
                <CardContent>
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            password: '',
                            password_confirm: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={onResetPassword}
                    >
                        {({isSubmitting}) => (
                            <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>
                                <Typography variant={"body1"} mb={3}>Ingresa tu nueva contraseña</Typography>
                                <Div sx={{mb: 2, mt: 1}}>
                                    <JumboTextField
                                        fullWidth
                                        name="password"
                                        label="Nueva contraseña"
                                        type="password"
                                    />
                                </Div>
                                <Div sx={{mb: 3, mt: 1}}>
                                    <JumboTextField
                                        fullWidth
                                        name="password_confirm"
                                        label="Confirmar contraseña"
                                        type="password"
                                    />
                                </Div>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{mb: 3}}
                                    loading={isSubmitting}
                                >Restablecer</LoadingButton>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        </Div>
    );
};

export default ResetPassword;
