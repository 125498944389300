import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardContent, CircularProgress, FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import Div from "@jumbo/shared/Div";
import moment from 'moment';
import companyServices from 'app/services/company-services';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import JumboScrollbar from '@jumbo/components/JumboScrollbar';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Logo from 'app/shared/Logo';
import ImageOrBlank from 'app/widgets/Images/ImageOrBlank';
import { LoadingButton } from '@mui/lab';
import { API_HOST, ASSET_IMAGES, HOST } from 'app/utils/constants/paths';

const SettingsCard = ({title, children, minScrollHeight = 200, isLoading = false}) => {
    return (
        <JumboCardQuick
            title={title}
            headerSx={{
                pb: 1,
                '& .MuiCardHeader-action': {
                    my: -.75
                }
            }}
            sx={{mb: 2}}>
            <JumboScrollbar
                autoHeight
                autoHeightMin={minScrollHeight}>
                <Div sx={{display: isLoading ? 'block' : 'none', textAlign: 'center', my: 5}}>
                    <CircularProgress color="warning"/>
                </Div>
                {children}
            </JumboScrollbar>
        </JumboCardQuick>
    )
}

const EditCompany = (props) => {
    const {company_id} = props;
    const [settings, setSettings] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [selectedLogo, setSelectedLogo] = useState()
    const [previewLogo, setPreviewLogo] = useState()
    const [saving, setSaving] = useState(false)
    const inputCompanyLogo = useRef(null);

    useEffect(() => {
        if(isLoading){
            loadCompanySettings()
        }
    }, [isLoading])

    useEffect(() => {
        setIsLoading(true)
    }, [])

    useEffect(() => {
        if (!selectedLogo) {
            setPreviewLogo(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(selectedLogo)
        setPreviewLogo(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedLogo])

    const loadCompanySettings = () => {
        if(company_id) {
            companyServices.getCompanySettings(company_id)
                .then(data => {
                    setSettings(data)
                    if(data?.logo_url) {
                        setPreviewLogo(`${HOST}${data.logo_url}`)
                    }
                })
                .catch(function (error) {})
                .finally(() => {
                    setIsLoading(false)
                });
        }
    }

    const saveCompanySettings = () => {        
        if(settings) {
            setSaving(true)
            
            companyServices.saveCompanySettings(company_id, {...settings, new_logo: selectedLogo})
                .then(data => {
                    setSettings(data)
                    if(data?.logo_url) {
                        setPreviewLogo(`${HOST}${data.logo_url}`)
                    }
                })
                .catch(function (error) {})
                .finally(() => {
                    setSaving(false)
                    window.location.reload();
                });
        }
    }

    const renderSelectOpts = (opts) => {
        return opts.map((item, index) => (<MenuItem value={item}>{item}</MenuItem>))
    }

    const onSelectLogo = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedLogo(undefined)
            return
        }

        setSelectedLogo(e.target.files[0])
    }

    const renderCompanySettings = () => {
        if(settings) {
            return (
                <React.Fragment>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={2} textAlign={"center"}>
                            <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
                                <Button onClick={() => { inputCompanyLogo.current.click(); } }>Cambiar logo</Button>
                                <ImageOrBlank src={previewLogo} size="100" style={{maxWidth: 150}} />
                                <input type='file' id='company-logo' ref={inputCompanyLogo} style={{display: 'none'}} onChange={onSelectLogo}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography variant="h6" sx={{mb: 2}}>Datos de la empresa</Typography>

                            <Grid container spacing={3} alignItems={"baseline"} sx={{mb: 2}}>
                                <Grid item xs={6} lg={4}>
                                    <FormLabel>Nombre</FormLabel>
                                </Grid>
                                <Grid item xs={6} lg={8}>
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        error={false}
                                        variant="standard"
                                        value={settings.name}
                                        onChange={(e) => setSettings({...settings, name: e.target.value})}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} alignItems={"baseline"} sx={{mb: 2}}>
                                <Grid item xs={6} lg={4}>
                                    <FormLabel>TIN</FormLabel>
                                </Grid>
                                <Grid item xs={6} lg={8}>
                                    <TextField
                                        fullWidth
                                        error={false}
                                        variant="standard"
                                        helperText="Tax Identification Number"
                                        value={settings.tin}
                                        onChange={(e) => setSettings({...settings, tin: e.target.value})}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <Typography variant="h6" sx={{mb: 2}}>Datos de contacto</Typography>

                            <Grid container spacing={3} alignItems={"baseline"} sx={{mb: 2}}>
                                <Grid item xs={6} lg={4}>
                                    <FormLabel>Email</FormLabel>
                                </Grid>
                                <Grid item xs={6} lg={8}>
                                    <TextField
                                        fullWidth
                                        error={false}
                                        variant="standard"
                                        value={settings.contact_email}
                                        onChange={(e) => setSettings({...settings, contact_email: e.target.value})}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} alignItems={"baseline"} sx={{mb: 2}}>
                                <Grid item xs={6} lg={4}>
                                    <FormLabel>Teléfono</FormLabel>
                                </Grid>
                                <Grid item xs={6} lg={8}>
                                    <TextField
                                        fullWidth
                                        error={false}
                                        variant="standard"
                                        value={settings.contact_phone}
                                        onChange={(e) => setSettings({...settings, contact_phone: e.target.value})}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} alignItems={"baseline"} sx={{mb: 2}}>
                                <Grid item xs={6} lg={4}>
                                    <FormLabel>Whatsapp</FormLabel>
                                </Grid>
                                <Grid item xs={6} lg={8}>
                                    <TextField
                                        fullWidth
                                        error={false}
                                        variant="standard"
                                        value={settings.whatsapp_number}
                                        onChange={(e) => setSettings({...settings, whatsapp_number: e.target.value})}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        }
    }

    const renderPlanificationSettings = () => {
        if(settings) {
            return (
                <React.Fragment>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography variant="h6" sx={{mb: 2}}>Campos obligatorios para crear planificación</Typography>

                            <Grid container spacing={3} alignItems={"baseline"} sx={{mb: 2}}>
                                <Grid item xs={6} lg={5}>
                                    <FormLabel>Vehículo</FormLabel>
                                </Grid>
                                <Grid item xs={6} lg={7}>
                                    <RadioGroup row>
                                        <FormControlLabel value={true} control={<Radio checked={settings.plan_require_vehicle === true} onClick={(e) => setSettings({...settings, plan_require_vehicle: true})}/>} label="Si"/>
                                        <FormControlLabel value={false} control={<Radio checked={settings.plan_require_vehicle === false} onClick={(e) => setSettings({...settings, plan_require_vehicle: false})}/>} label="No"/>
                                    </RadioGroup>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} alignItems={"baseline"} sx={{mb: 2}}>
                                <Grid item xs={6} lg={5}>
                                    <FormLabel>Conductor</FormLabel>
                                </Grid>
                                <Grid item xs={6} lg={7}>
                                    <RadioGroup row>
                                        <FormControlLabel value={true} control={<Radio checked={settings.plan_require_driver === true} onClick={(e) => setSettings({...settings, plan_require_driver: true})}/>} label="Si"/>
                                        <FormControlLabel value={false} control={<Radio checked={settings.plan_require_driver === false} onClick={(e) => setSettings({...settings, plan_require_driver: false})}/>} label="No"/>
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                </React.Fragment>
            )
        }
    }

    const renderNotifSettings = () => {
        if(settings) {
            return (
                <React.Fragment>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography variant="h6" sx={{mb: 2}}>Asuntos de correos</Typography>

                            <Grid container spacing={3} alignItems={"baseline"} sx={{mb: 2}}>
                                <Grid item xs={6} lg={5}>
                                    <FormLabel>Pedido programado</FormLabel>
                                </Grid>
                                <Grid item xs={6} lg={7}>
                                    <TextField
                                        fullWidth
                                        error={false}
                                        variant="standard"
                                        value={settings.notif_scheduled_subject}
                                        onChange={(e) => setSettings({...settings, notif_scheduled_subject: e.target.value})}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} alignItems={"baseline"} sx={{mb: 2}}>
                                <Grid item xs={6} lg={5}>
                                    <FormLabel>Pedido en camino</FormLabel>
                                </Grid>
                                <Grid item xs={6} lg={7}>
                                    <TextField
                                        fullWidth
                                        error={false}
                                        variant="standard"
                                        value={settings.notif_shipped_subject}
                                        onChange={(e) => setSettings({...settings, notif_shipped_subject: e.target.value})}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} alignItems={"baseline"} sx={{mb: 2}}>
                                <Grid item xs={6} lg={5}>
                                    <FormLabel>Pedido entregado</FormLabel>
                                </Grid>
                                <Grid item xs={6} lg={7}>
                                    <TextField
                                        fullWidth
                                        error={false}
                                        variant="standard"
                                        value={settings.notif_delivered_subject}
                                        onChange={(e) => setSettings({...settings, notif_delivered_subject: e.target.value})}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <Typography variant="h6" sx={{mb: 2}}>Configuración correo SMTP</Typography>

                            <Grid container spacing={3} alignItems={"center"} sx={{mb: 4}}>
                                <Grid item xs={6} lg={6}>
                                    <FormLabel>Usar mi propio servidor de correo</FormLabel>
                                </Grid>
                                <Grid item xs={6} lg={6}>
                                    <RadioGroup row>
                                        <FormControlLabel value={true} control={<Radio checked={settings.use_custom_mailer === true} onClick={(e) => setSettings({...settings, use_custom_mailer: true})}/>} label="Si"/>
                                        <FormControlLabel value={false} control={<Radio checked={settings.use_custom_mailer === false} onClick={(e) => setSettings({...settings, use_custom_mailer: false})}/>} label="No"/>
                                    </RadioGroup>
                                </Grid>
                            </Grid>

                            {settings.use_custom_mailer && (
                                <React.Fragment>
                                    <Grid container spacing={3} alignItems={"baseline"} sx={{mb: 2}}>
                                        <Grid item xs={6} lg={4}>
                                            <FormLabel>Servidor de correo saliente</FormLabel>
                                        </Grid>
                                        <Grid item xs={6} lg={8}>
                                            <TextField
                                                fullWidth
                                                error={false}
                                                variant="standard"
                                                helperText="Ej: smtp.gmail.com"
                                                value={settings.mailer_smtp_address}
                                                onChange={(e) => setSettings({...settings, mailer_smtp_address: e.target.value})}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3} alignItems={"baseline"} sx={{mb: 2}}>
                                        <Grid item xs={6} lg={4}>
                                            <FormLabel>Puerto</FormLabel>
                                        </Grid>
                                        <Grid item xs={6} lg={8}>
                                            <TextField
                                                fullWidth
                                                error={false}
                                                variant="standard"
                                                helperText="Ej: 587"
                                                value={settings.mailer_smtp_port}
                                                onChange={(e) => setSettings({...settings, mailer_smtp_port: e.target.value})}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3} alignItems={"baseline"} sx={{mb: 2}}>
                                        <Grid item xs={6} lg={4}>
                                            <FormLabel>Dominio</FormLabel>
                                        </Grid>
                                        <Grid item xs={6} lg={8}>
                                            <TextField
                                                fullWidth
                                                error={false}
                                                variant="standard"
                                                helperText={`Ej: ${_.kebabCase(settings.name) || 'tudominio' }.com`}
                                                value={settings.mailer_smtp_domain}
                                                onChange={(e) => setSettings({...settings, mailer_smtp_domain: e.target.value})}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3} alignItems={"baseline"} sx={{mb: 2}}>
                                        <Grid item xs={6} lg={4}>
                                            <FormLabel>Autenticación</FormLabel>
                                        </Grid>
                                        <Grid item xs={6} lg={8}>
                                            <Select
                                                value={settings.mailer_smtp_authentication}
                                                fullWidth
                                                variant="standard"
                                                onChange={(e) => setSettings({...settings, mailer_smtp_authentication: e.target.value})}>
                                                    {renderSelectOpts(['plain','login', 'cram-md5', 'digest-md5', 'ntlm', 'gssapi', 'xoauth', 'xoauth2'])}
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3} alignItems={"center"}>
                                        <Grid item xs={6} lg={4}>
                                            <FormLabel>StartTLS Auto</FormLabel>
                                        </Grid>
                                        <Grid item xs={6} lg={8}>
                                            <RadioGroup row>
                                                <FormControlLabel value={true} control={<Radio checked={settings.mailer_smtp_enable_starttls_auto === true} onClick={(e) => setSettings({...settings, mailer_smtp_enable_starttls_auto: true})}/>} label="Si"/>
                                                <FormControlLabel value={false} control={<Radio checked={settings.mailer_smtp_enable_starttls_auto === false} onClick={(e) => setSettings({...settings, mailer_smtp_enable_starttls_auto: false})}/>} label="No"/>
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3} alignItems={"baseline"} sx={{mb: 2}}>
                                        <Grid item xs={6} lg={4}>
                                            <FormLabel>Usuario</FormLabel>
                                        </Grid>
                                        <Grid item xs={6} lg={8}>
                                            <TextField
                                                fullWidth
                                                error={false}
                                                variant="standard"
                                                helperText={`Ej: contacto@${_.kebabCase(settings.name) || 'tudominio' }.com`}
                                                value={settings.mailer_smtp_user_name}
                                                onChange={(e) => setSettings({...settings, mailer_smtp_user_name: e.target.value})}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3} alignItems={"baseline"} sx={{mb: 2}}>
                                        <Grid item xs={6} lg={4}>
                                            <FormLabel>Contraseña</FormLabel>
                                        </Grid>
                                        <Grid item xs={6} lg={8}>
                                            <TextField
                                                fullWidth
                                                error={false}
                                                type="password"
                                                variant="standard"
                                                helperText="Tu contraseña será almacenada encriptada en nuestra base de datos"
                                                value={settings.mailer_smtp_password}
                                                onChange={(e) => setSettings({...settings, mailer_smtp_password: e.target.value})}
                                            />
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            )}
                            

                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        }
    }
    
    return (
        <React.Fragment>
            <SettingsCard title="Empresa" isLoading={isLoading}>
                {renderCompanySettings()}
            </SettingsCard>

            <SettingsCard title="Planificación de pedidos" isLoading={isLoading}>
                {renderPlanificationSettings()}
            </SettingsCard>

            <SettingsCard title="Notificaciones y correos electrónicos" isLoading={isLoading} minScrollHeight={395}>
                {renderNotifSettings()}
            </SettingsCard>
            
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                <LoadingButton
                    loading={saving}
                    variant="contained"
                    color="success"
                    size='small'
                    endIcon={<SaveOutlinedIcon />}
                    loadingPosition="end"
                    onClick={() => saveCompanySettings()}>
                        { saving ? 'Guardando' : 'Guardar' }
                </LoadingButton>
            </Stack>

        </React.Fragment>
    );
};

export default EditCompany;