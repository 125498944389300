import React from "react";
import Page from "@jumbo/shared/Page";
import ResetPassword from "app/pages/auth/reset-password/ResetPassword";


const appsRoutes = [
    {
        path: [
            "/api/v1/password/edit",
        ],
        element: <Page component={ResetPassword}/>,
    },
];

export default appsRoutes;
