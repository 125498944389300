import jwtAuthAxios from "./auth/jwtAuth";

const fileTaskServices = {};

fileTaskServices.getFileTasks = async (params) => {
    const {data} = await jwtAuthAxios.get(`/file_tasks${ params ?? ""}`);
    return data;
};

fileTaskServices.downloadTemplate = async (category) => {
    const {data} = await jwtAuthAxios.get(`/file_tasks/download_template?category=${category}`, { responseType: 'arraybuffer' });
    return data;
}

fileTaskServices.bulkImport = async (category, files) => {
    const formData = new FormData();
    formData.append('category', category);
    for (const file of files) {
        formData.append('files[]', file);
    }

    const {data} = await jwtAuthAxios.post(`/file_tasks/bulk_import`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return data;
}

export default fileTaskServices;