import React, { useState } from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography} from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import _ from 'lodash';
import FileUploader from './FileUploader';
import { LoadingButton } from '@mui/lab';
import fileTaskServices from 'app/services/file-task-services';
import { downloadBuffer } from 'app/utils/appHelpers';
import Div from '@jumbo/shared/Div';
import moment from 'moment';


const FileUploaderModal = ({
    kind='default_bulk_order_import',
    fileUploaderOptions={},
    open=false,
    onClose=()=>{},
    canSave=true,
    onSave=()=>{},
    saving=false,
    showDownloadTemplate=true
}) => {
    const [downloadingTemplate, setDownloadingTemplate] = useState(false);

    const downloadTemplate = () => {
        setDownloadingTemplate(true)
        fileTaskServices.downloadTemplate(kind)
            .then(data => {
                setDownloadingTemplate(false)
                downloadBuffer(data, `${kind}_template_${moment().format('YYYYMMDDHHmmss')}.xlsx`)
            })
            .catch((err) => {
                setDownloadingTemplate(false)
            });
    }
    
    return (
        <Dialog
            fullWidth={true}
            open={open}
            onClose={onClose}>
            <DialogTitle>
                <Stack direction="row" spacing={2} justifyContent={"space-between"}>
                    <Typography variant={"h2"} color="primary">Elige un archivo</Typography>
                    {showDownloadTemplate && <Div>
                        <LoadingButton
                            loading={downloadingTemplate}
                            variant="contained"
                            color="success"
                            size='small'
                            endIcon={<DownloadOutlinedIcon/>}
                            disabled={downloadingTemplate}
                            loadingPosition="end"
                            onClick={downloadTemplate}>
                                { downloadingTemplate ? 'Descargando' : 'Ejemplo' }
                        </LoadingButton>
                    </Div>}
                </Stack>
            </DialogTitle>
            <DialogContent>
                <FileUploader {...fileUploaderOptions} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cerrar</Button>
                <LoadingButton
                    loading={saving}
                    variant="contained"
                    color="success"
                    size='small'
                    endIcon={<SaveOutlinedIcon/>}
                    disabled={!canSave}
                    loadingPosition="end"
                    onClick={onSave}>
                        { saving ? 'Guardando' : 'Guardar' }
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default FileUploaderModal;