import React, { useState } from 'react';
import {useField} from "formik";
import TextField from "@mui/material/TextField";
import { IconButton, InputAdornment } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

//todo: to see how to define prop-types for this component

const JumboTextField = (props) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    const [showPassword, setShowPassword] = useState(false);

    return (
        <TextField
            {...props}
            {...field}
            helperText={errorText}
            error={!!errorText}
            type={props.type === 'password' && showPassword ? 'text' : props.type}
            InputProps={{
                endAdornment: props.type === 'password' && (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="Mostrar contraseña"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    );
};

export default JumboTextField;