import React from 'react';
import Avatar from "@mui/material/Avatar";
import {ListItemIcon, ListItemText, ThemeProvider, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import LogoutIcon from '@mui/icons-material/Logout';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import {useNavigate} from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import _ from 'lodash';
import userServices from 'app/services/user-services';

const AuthUserDropdown = () => {
    const navigate = useNavigate();
    const {theme} = useJumboTheme();
    const {setAuthToken, authUser} = useJumboAuth();

    const onLogout = () => {
        setAuthToken(null);
        navigate("auth/login");
    };

    const onCompanyChange = (company) => {
        userServices.setCurrentCompany(company.id)
            .then(() => {
                window.location.reload();
            })
    }

    return (
        <ThemeProvider theme={theme}>
            <JumboDdPopover
                triggerButton={
                    <Avatar sizes={"small"} sx={{boxShadow: 25, cursor: 'pointer'}}>
                        {authUser?.first_name ? `${_.toUpper(authUser?.first_name[0])}${authUser?.last_name ? _.toUpper(authUser?.last_name[0]) : ''}` : 'SN'}
                    </Avatar>
                }
            >
                <Div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    minWidth: 200,
                    p: theme => theme.spacing(2.5),
                }}>
                    <Avatar alt={authUser?.first_name} sx={{width: 60, height: 60, mb: 2}}>
                        {authUser?.first_name ? `${_.toUpper(authUser?.first_name[0])}${authUser?.last_name ? _.toUpper(authUser?.last_name[0]) : ''}` : 'SN'}
                    </Avatar>
                    <Typography variant={"h5"}>{authUser?.first_name}</Typography>
                    <Typography variant={"h5"}>{authUser?.last_name}</Typography>
                    <Typography variant={"body1"} color="text.secondary">{authUser?.handle}</Typography>
                </Div>
                <Divider/>
                <nav>
                    <List disablePadding sx={{pb: 1}}>
                        { _.filter(authUser?.companies, (e) => e.id !== authUser?.current_company?.id).map((company, index) => (
                            <ListItemButton key={index} onClick={(ev) => { onCompanyChange(company) }}>
                                <ListItemIcon sx={{minWidth: 36}}>
                                    <BusinessOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText primary={`Ir a ${company.name}`} sx={{my: 0}}/>
                            </ListItemButton>
                        ))}
                        <ListItemButton onClick={onLogout}>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <LogoutIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Cerrar sesión" sx={{my: 0}}/>
                        </ListItemButton>
                    </List>
                </nav>
            </JumboDdPopover>
        </ThemeProvider>
    );
};

export default AuthUserDropdown;
