import React from 'react';
import { Button, Typography, ListItem, ListItemText, Divider, ListItemAvatar, LinearProgress, Alert } from "@mui/material";
import _ from "lodash";
import Div from "@jumbo/shared/Div";
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import JumboScrollbar from '@jumbo/components/JumboScrollbar';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import Span from '@jumbo/shared/Span';
import moment from 'moment';
import CustomPagination from 'app/widgets/Shared/Pagination/CustomPagination';

const TaskAuthorizationsGroup = props => {
    const {records, title, scrollHeight, currentPage, loading, totalPages, onFocus=()=>{}, onPageChange=()=>{}} = props;

    const authDesc = (state) => {
        switch(state){
            case 'pending':
                return ""
            case 'authorized':
                return "ha autorizado la entrega"
            case 'partially_authorized':
                return "ha autorizado parcialmente la entrega"
            case 'unauthorized':
                return "ha rechazado la entrega"
            default:
                return ""
        }
    }

    const authIcon = (state) => {
        switch(state){
            case 'pending':
                return <CircleTwoToneIcon fontSize="large" />
            case 'authorized':
                return <CheckCircleTwoToneIcon fontSize="large" color={"success"}/>
            case 'partially_authorized':
                return <CheckCircleTwoToneIcon fontSize="large" color={"warning"}/>
            case 'unauthorized':
                return <CancelTwoToneIcon fontSize="large" color={"error"}/>
            default:
                return <CircleTwoToneIcon fontSize="large" />
        }
    }

    return (
        <JumboCardQuick
            title={title}
            action={
                <CustomPagination
                    compact={true}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageSelect={(page) => { onPageChange(page) }}/>
            }
            noWrapper
        >
            <Div sx={{width: '100%', display: loading ? 'block' : 'none'}}>
                <LinearProgress  />
            </Div>
            <JumboScrollbar
                autoHeight
                autoHeightMin={scrollHeight ? scrollHeight : 498}
                autoHide
                autoHideDuration={200}
                autoHideTimeout={500}
            >

                { records.length === 0 && 
                    <ListItem alignItems={"center"} sx={{px: 3}}>
                        <ListItemText
                            primary={"No hay registros"}
                        />
                    </ListItem>
                }
                { records.map((r, index) => (
                    <ListItem
                        alignItems={"center"}
                        sx={{px: 3}}
                        secondaryAction={
                            r.state === 'pending' &&
                                <Button variant={"contained"} color={"success"} size={"small"} onClick={() => { onFocus(r) }}>Ver</Button>
                        }
                    >
                        <ListItemAvatar sx={{minWidth: 50}}>
                            {authIcon(r.state)}
                        </ListItemAvatar>
                        <ListItemText
                            primary={''}
                            secondary={
                                <Typography component={"div"}>
                                    { r.authorized_at && <Typography variant={"caption"} color={"text.secondary"}>{moment(r.authorized_at).format('DD MMM HH:mm')}</Typography> }
                                    { r.state !== 'pending' &&
                                        <Typography color={"text.primary"}>
                                            <Span sx={{color: 'primary.main'}}>{`${r.authorized_by.first_name}${r.authorized_by.last_name && ` ${r.authorized_by.last_name}`}`} </Span>
                                            {authDesc(r.state)}
                                            <Span sx={{color: 'primary.main'}}> {r.delivery.code} (P: {r.delivery.order.code})</Span>
                                        </Typography>
                                    }
                                    { r.requested_by && <Typography color={"text.secondary"}>Solicitado por {`${r.requested_by.first_name}${r.requested_by.last_name && ` ${r.requested_by.last_name}`}`} {moment(r.requested_at).format('DD MMM HH:mm')}</Typography> }
                                    { (r.request_observation || r.authorization_observation) &&
                                        <Alert severity="info" icon={<CommentOutlinedIcon fontSize="small" />} sx={{mt: 1, ['&.MuiAlert-root']: { display: 'inline-flex', px: 0.5, py: 0 } }}>
                                            {r.request_observation &&
                                                <Div>Sol: {r.request_observation}</Div>
                                            }
                                            {r.authorization_observation &&
                                                <Div>Res: {r.authorization_observation}</Div>
                                            }
                                        </Alert>
                                    }
                                </Typography>
                            }
                        />
                    </ListItem>
                ))}
            </JumboScrollbar>
            <Divider/>
        </JumboCardQuick>
    )
}
export default TaskAuthorizationsGroup;