import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import companyServices from 'app/services/company-services';
import React, { createContext, useState, useContext, useEffect } from 'react';

// Crea el contexto
const CompanySettingsContext = createContext();

// Crea un Hook personalizado para acceder al contexto más fácilmente
export function useCompanySettings() {
  return useContext(CompanySettingsContext);
}

// Define el Provider
export function CompanySettingsProvider({ children }) {
  const {authUser} = useJumboAuth();
  // const authUser = null;
  const [companySettings, setCompanySettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!authUser?.current_company) return;

    companyServices.getCompanySettings(authUser.current_company.id)
      .then(data => {
        // console.log('COMPANY SETTINGS PROVIDER', data)
        setCompanySettings(data);
      })
      .catch(function (error) {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [authUser]); // Ejecuta esto solo una vez al montar el componente

  return (
    <CompanySettingsContext.Provider value={{ companySettings, loading, error }}>
      {children}
    </CompanySettingsContext.Provider>
  );
}