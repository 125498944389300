import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TextField, Radio, InputAdornment } from "@mui/material";
import _ from "lodash";
import Div from "@jumbo/shared/Div";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { LoadingButton } from '@mui/lab';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import driverServices from 'app/services/driver-services';

const DriverForm = props => {
    const [saving, setSaving] = useState(false)
    const [driver, setDriver] = useState(null)
    const [canSave, setCanSave] = useState(false)
    const [showForce, setShowForce] = useState(false)

    useEffect(() => {
        if(props.trip?.driver) {
            setDriver(props.trip.driver);
        }
    }, [])

    const saveDriver = () => {
        setSaving(true);
        
        let par = _.pick(driver, ['id', 'phone_primary', 'phone_secondary', 'phone_in_use'])
        if(showForce) {
            par['force'] = true
        }
        driverServices.updateDriver(driver?.id, par)
                .then((data) => {
                    setDriver(data)
                    props.onSave(data)
                    setSaving(false)
                    setShowForce(false)
                })
                .catch((err) => {
                    setSaving(false)
                    if(err?.response?.data?.try_force == true) {
                        setShowForce(true)
                    }
                });
    }

    const [selectedValue, setSelectedValue] = React.useState('a');

    const handleChange = (event) => {
        setShowForce(false)
        if(driver) {
            setDriver({ ...driver, phone_in_use: event.target.value })
        }
    };

    const controlProps = (item) => ({
        checked: driver?.phone_in_use === item,
        onChange: handleChange,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: {'aria-label': item},
    });

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={props.isOpen}
                onClose={props.onClose}>
                <DialogTitle>
                    <Typography variant={"h2"} color="primary">{ driver?.first_name }{ driver?.last_name && ` ${driver?.last_name}` } (Nº{ driver?.code })</Typography>
                    <Div sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1}}>
                    </Div>
                </DialogTitle>
                <DialogContent>
                    <Div sx={{ maxWidth: '100%', pt: 3 }}>
                        <Div sx={{display: 'flex', alignItems: 'start', justifyContent: 'space-between', mb: 2}}>
                            <Radio {...controlProps('primary')} size="small" sx={{pt: 2}}/>
                            <TextField
                                fullWidth
                                label="Teléfono primario" id="primary"
                                helperText="Ej: 56912345678"
                                defaultValue={driver?.phone_primary}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><LocalPhoneIcon /></InputAdornment>,
                                }}
                                onChange={(ev) => { setShowForce(false); setDriver({ ...driver, phone_primary: ev.target.value }) }}/>
                        </Div>
                        <Div sx={{display: 'flex', alignItems: 'start', justifyContent: 'space-between', mb: 2}}>
                            <Radio {...controlProps('secondary')} size="small" sx={{pt: 2}}/>
                            <TextField
                                fullWidth
                                label="Teléfono secundario" id="secondary"
                                helperText="Ej: 56912345678"
                                defaultValue={driver?.phone_secondary}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><LocalPhoneIcon /></InputAdornment>,
                                }}
                                onChange={(ev) => { setShowForce(false); setDriver({ ...driver, phone_secondary: ev.target.value }) }}/>
                        </Div>
                    </Div>

                    {/* {JSON.stringify(_.map(newDeliveries, 'code'))} */}
                    {/* {!deliveriesLoading ?
                        <React.Fragment>
                            <Typography variant={"h4"}>Planificación de entregas</Typography>
                            {renderMap()}
                            <Deliveries deliveries={newDeliveries} onSortEnd={onSortEnd} useDragHandle={true} _selectedDelivery={selectedDelivery} />
                        </React.Fragment>
                    :
                        <Div sx={{textAlign: 'center', my: 5}}>
                            <CircularProgress color="warning"/>
                        </Div>
                    } */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setCanSave(false); props.onClose() } }>Cerrar</Button>
                    {showForce ?
                        <LoadingButton
                            loading={saving}
                            variant="contained"
                            color={ canSave ? 'success' : 'warning' }
                            size='small'
                            endIcon={<SaveOutlinedIcon/>}
                            loadingPosition="end"
                            onClick={() => saveDriver()}>
                                { saving ? 'Guardando' : 'Sobreescribir' }
                        </LoadingButton>
                        :
                        <LoadingButton
                            loading={saving}
                            variant="contained"
                            color={ canSave ? 'success' : 'primary' }
                            size='small'
                            endIcon={<SaveOutlinedIcon/>}
                            loadingPosition="end"
                            onClick={() => saveDriver()}>
                                { saving ? 'Guardando' : 'Guardar' }
                        </LoadingButton>
                    }
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default DriverForm;