import jwtAuthAxios from "./auth/jwtAuth";

const taskAuthorizationServices = {};

// taskAuthorizationServices.getNewVehicle = async (params) => {
//     const {data} = await jwtAuthAxios.get(`/vehicles/new${params ?? ""}`);
//     return data;
// }

// taskAuthorizationServices.createVehicle = async (params, body) => {
//     const {data} = await jwtAuthAxios.post(`/vehicles${params ?? ""}`, body);
//     return data;
// }

taskAuthorizationServices.getAuthorizations = async (params=null) => {
    const {data} = await jwtAuthAxios.get(`/task_authorizations${params || ""}`);
    return data;
};

// taskAuthorizationServices.getVehicle = async (id, params=null) => {
//     const {data} = await jwtAuthAxios.get(`/vehicles/${id}${params || ""}`);
//     return data;
// };

// taskAuthorizationServices.getEditOptions = async () => {
//     const {data} = await jwtAuthAxios.get(`/vehicles/edit_options`);
//     return data;
// };

taskAuthorizationServices.saveAuthorization = async (id, body) => {
    const {data} = await jwtAuthAxios.post(`/task_authorizations/${id}/authorize`, body);
    return data;
}

// taskAuthorizationServices.saveCustomerGeofence = async (id, params) => {
//     const {data} = await jwtAuthAxios.post(`/customers/${id}/geofence`, params);
//     return data;
// };

// taskAuthorizationServices.getReceiptsPins = async (id) => {
//     const {data} = await jwtAuthAxios.get(`/customers/${id}/receipts_pins`);
//     return data;
// };

export default taskAuthorizationServices;