import React, { useEffect, useState } from 'react';
import {Autocomplete, Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, TextField, ToggleButton, Tooltip, Typography, styled} from "@mui/material";
import _, { set } from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import OrdersDND from './OrdersDND';
import deliveryZoneServices from 'app/services/delivery-zone-services';
import ZonesMapEdit from '../delivery_zones/ZonesMapEdit';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import centreServices from 'app/services/centre-services';
import { StyledDatePicker } from 'app/widgets/Shared/StyledDatePicker/StyledDatePicker';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { LoadingButton } from '@mui/lab';
import OrderDeliveryDetailsPopover from 'app/widgets/Deliveries/OrderDeliveryDetailsPopover';
import planificationServices from 'app/services/planification-services';
import HexagonOutlinedIcon from '@mui/icons-material/HexagonOutlined';
import InsertCommentOutlinedIcon from '@mui/icons-material/InsertCommentOutlined';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import LocationSearchingOutlinedIcon from '@mui/icons-material/LocationSearchingOutlined';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { optimizerNames } from 'app/utils/appHelpers';

const PlanificationIndex = () => {
    const {authUser} = useJumboAuth();
    const [pendingOrders, setPendingOrders] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [listLoading, setListLoading] = useState(false)
    const [drawingMode, setDrawingMode] = useState({ mode: 'polygon', cancel: false })
    const [deliveryZones, setDeliveryZones] = useState([])
    const [currDeliveryZone, setCurrDeliveryZone] = useState(null)
    const [shouldSaveCurrDeliveryZone, setShouldSaveCurrDeliveryZone] = useState(false)
    const [loadingZones, setLoadingZones] = useState(false)
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [centres, setCentres] = useState([])
    const [currCentre, setCurrCentre] = useState(null)
    const [trips, setTrips] = useState([])
    const [saving, setSaving] = useState(false)
    const [itemOnHover, setItemOnHover] = useState(null)
    const [currDisplayCentre, setCurrDisplayCentre] = useState(null)
    const [showZones, setShowZones] = useState(true)
    const [showProductDetails, setShowProductDetails] = useState(true)
    const [showCustomerLocationOnHover, setShowCustomerLocationOnHover] = useState(true)
    const [routes, setRoutes] = useState([])
    const [hiddenTripsRoutes, setHiddenTripsRoutes] = useState([])
    const [drivers, setDrivers] = useState([])
    const [vehicles, setVehicles] = useState([])
    const [loadingCentreOptions, setLoadingCentreOptions] = useState(false)
    const [selectedBoxes, setSelectedBoxes] = useState([])
    const [currMapCenter, setCurrMapCenter] = useState(null)
    const [currOptimizer, setCurrOptimizer] = useState(null)
    const [optimizing, setOptimizing] = useState(false)
    const [moveDestination, setMoveDestination] = useState(null)

    // [
    //     { droppableId: 'pendingOrders', index: 2 },
    //     { droppableId: 'pendingOrders', index: 4 },
    //     ...
    // ]

    useEffect(() => {
        console.warn = console.error = () => {};
        setShowZones(localStorage.getItem('showZones') === 'false' ? false : true)
        setShowProductDetails(localStorage.getItem('showProductDetails') === 'false' ? false : true)
        setShowCustomerLocationOnHover(localStorage.getItem('showCustomerLocationOnHover') === 'false' ? false : true)
        loadCentres()
    }, [])

    useEffect(() => {
        if(listLoading){
            loadPlanificationData()
        }
    }, [listLoading])

    useEffect(() => {
        if(loadingZones){
            loadDeliveryZones()
        }
    }, [loadingZones])

    useEffect(() => {
        if(loadingCentreOptions){
            loadCentreOptions()
        }
    }, [loadingCentreOptions])

    useEffect(() => {
        if(currCentre) {
            setListLoading(true)
        }
    }, [startDate, endDate])

    useEffect(() => {
        if(currCentre) {
            setListLoading(true)
            setLoadingZones(true)
            setLoadingCentreOptions(true)
            localStorage.setItem(`currCentre${authUser?.current_company?.id}`, JSON.stringify(currCentre))
        }
    }, [currCentre])

    useEffect(() => {
        refreshRoutes()
    }, [trips])

    useEffect(() => {
        if(optimizing && selectedBoxes.length > 0) {
            var DNDEvent = {
                source: { droppableId: 'pendingOrders', index: 0 },
                destination: moveDestination
            }
            onDragEnd(DNDEvent)
        }
    }, [selectedBoxes])

    useEffect(() => {
        localStorage.setItem('showZones', showZones)
    }, [showZones])

    useEffect(() => {
        localStorage.setItem('showProductDetails', showProductDetails)
    }, [showProductDetails])

    useEffect(() => {
        localStorage.setItem('showCustomerLocationOnHover', showCustomerLocationOnHover)
    }, [showCustomerLocationOnHover])

    const printDuration = (_secs) => {
        var hhs = _.floor(_secs/3600)
        var mms = _.floor((_secs - hhs*3600)/60)
        return `${hhs > 0 ? `${hhs}h y ` : ''}${mms}min`
    }

    const buildOrderParams = () => {
        let par = []
        if(searchQuery && searchQuery !== 0) {
            par.push(`q%5Bcode_or_locatable_of_Customer_type_name_or_locatable_of_Place_type_name_cont%5D=${searchQuery}`)
        }
        if(startDate) {
            par.push(`q%5Bship_date_gteq%5D=${startDate}`)
        }
        if(endDate) {
            par.push(`q%5Bship_date_lteq%5D=${endDate}`)
        }
        par.push(`q%5Bcentre_id_eq%5D=${currCentre?.id}`)

        par = _.join(par, '&')

        return `?${par}`;
    }

    const loadPlanificationData = () => {
        planificationServices.getPlanification(buildOrderParams())
            .then(data => {
                setPendingOrders(data.pending_orders)
                setTrips(data.trips)
                setCurrDisplayCentre({
                    id: data.centre?.id,
                    code: data.centre?.code,
                    name: data.centre?.name,
                    location: data.centre?.main_centre_zone?.location
                })
                setCurrMapCenter({ lat: data.centre?.main_centre_zone?.location?.lat, lng: data.centre?.main_centre_zone?.location?.lng })
                setListLoading(false)
            })
            .catch((err) => {
                setListLoading(false)
            });
    }

    const refreshRoutes = () => {
        if(trips.length === 0) {
            setRoutes([])
            return
        }
        planificationServices.getRoutes({ centre_id: currCentre?.id, trips: trips })
            .then(data => {
                setRoutes(data)
            })
            .catch((err) => {

            });
    }

    const loadDeliveryZones = () => {
        deliveryZoneServices.getDeliveryZones("include=location")
            .then(data => {
                setDeliveryZones(data.data)
                setLoadingZones(false)
            })
            .catch((err) => {
                setLoadingZones(false)
            });
    }

    const loadCentres = () => {
        centreServices.getCentres()
            .then(data => {
                setCentres(data.data)
                if(data?.data && currCentre === null) {
                    if(!currCentre){
                        var lsCentre = JSON.parse(localStorage.getItem(`currCentre${authUser?.current_company?.id}`))
                        if(lsCentre) {
                            setCurrCentre(lsCentre)
                        } else {
                            setCurrCentre(data.data[0])
                        }
                    }
                }
            });
    }

    const selectionsInGroup = (droppableId) => {
        return _.filter(selectedBoxes, { droppableId: droppableId });
    };

    const selectionIndex = (droppableId, idx) => {
        return _.findIndex(selectedBoxes, { droppableId: droppableId, index: idx });
    };

    const isSelected = (droppableId, idx) => {
        return selectionIndex(droppableId, idx) > -1;
    };

    const toggleSelection = (droppableId, idx) => {
        const oldSelectedBoxes = selectedBoxes;
        const wasSelected = isSelected(droppableId, idx);
    
        const newSelectedBoxes = (() => {
          // Box was not previously selected
          // now will be the only selected item
          if (!wasSelected) {
            return [{ droppableId: droppableId, index: idx }];
          }
    
          // Box was part of a selected group
          // will now become the only selected item
          if (oldSelectedBoxes.length > 1) {
            return [{ droppableId: droppableId, index: idx }];
          }
    
          // Box was previously selected but not in a group
          // we will now clear the selection
          return [];
        })();

        setSelectedBoxes(newSelectedBoxes)
    };

    const toggleSelectionInGroup = (droppableId, idx) => {
        const oldSelectedBoxes = selectedBoxes
        const index = selectionIndex(droppableId, idx)
    
        // if not selected - add it to the selected items
        const newSelected = [...oldSelectedBoxes]

        if (index === -1) {
            newSelected.push({ droppableId: droppableId, index: idx })
            setSelectedBoxes(newSelected)
        } else {
            // it was previously selected and now needs to be removed from the group
            newSelected.splice(index, 1)
        }
        
        setSelectedBoxes(newSelected)
    };

    const loadCentreOptions = () => {
        centreServices.getCentreOptions(currCentre?.id)
            .then(data => {
                setDrivers(data?.data?.drivers)
                setVehicles(data?.data?.vehicles)
                setLoadingCentreOptions(false)
            })
            .catch((err) => {
                setLoadingCentreOptions(false)
            });
    }

    const onOverlayComplete = o => {
        if(drawingMode.cancel) {
            o.overlay.setMap(null)
            setDrawingMode({ mode: 'polygon', cancel: false })
            return
        }

        var coordStr = _.join(_.map(o.overlay.getPath().getArray(), (c) => `${c.toUrlValue()}`), ';')
        var newDz = { id: null, name: 'Nueva zona', location: { path: coordStr } }

        setDeliveryZones([...deliveryZones, newDz])
        setCurrDeliveryZone(newDz)
        setShouldSaveCurrDeliveryZone(true)
        o.overlay.setMap(null)
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };

    const executeMovement = async(selecteds, result) => {
        if (!result.destination) return;
        
        var src   = { droppableId: result.source?.droppableId,      index: result.source?.index }
        var dest  = { droppableId: result.destination?.droppableId, index: result.destination?.index }
        
        var newTripIdx = null
        
        var tripsToSet = _.cloneDeep(trips)
        var pendingOrdersToSet = _.cloneDeep(pendingOrders)

        _.each(selecteds, (sb) => {
            src = { droppableId: sb.droppableId, index: sb.index }

            if (src.droppableId === dest.droppableId && src.index === dest.index) return;

            if(src.droppableId === 'pendingOrders') {
                // Box origin is pending order
                if(dest.droppableId === 'newTrip') {
                    var ord = pendingOrders[src.index]
                    if(newTripIdx === null) {
                        // Create new trip
                        var trip = {
                            id: null,
                            ship_date: moment().startOf('day'),
                            driver: {},
                            vehicle: {},
                            orders: [ord]
                        }
                        newTripIdx = tripsToSet.length
                        tripsToSet.push(trip)
                    } else {
                        // Add to already created new trip
                        tripsToSet[newTripIdx].orders = [...tripsToSet[newTripIdx].orders, ord]
                    }
                    pendingOrdersToSet = _.filter(pendingOrdersToSet, (el, i) => !_.isEqual(el, pendingOrders[src.index]))
                } else if(src.droppableId === dest.droppableId) {
                    // Reorder inside same group
                    pendingOrdersToSet = reorder(
                        pendingOrders,
                        src.index,
                        dest.index
                    );
                } else if(_.includes(dest.droppableId, 'plannedOrders')) {
                    // Move to existing trip
                    var tripIndex = parseInt(dest.droppableId.split('_')[1])
                    tripsToSet[tripIndex].orders.splice(dest.index, 0, pendingOrders[src.index])
                    pendingOrdersToSet = _.filter(pendingOrdersToSet, (el, i) => !_.isEqual(el, pendingOrders[src.index]))
                }
            } else {
                // Box origin is a trip
                var sourceTripIndex = parseInt(src.droppableId.split('_')[1])
                
                if(dest.droppableId === 'newTrip') {

                    var ord = trips[sourceTripIndex].orders[src.index]
                    if(newTripIdx === null) {
                        // Create new trip
                        var trip = {
                            id: null,
                            ship_date: moment().startOf('day'),
                            driver: {},
                            vehicle: {},
                            orders: [ord]
                        }
                        newTripIdx = tripsToSet.length
                        tripsToSet.push(trip)
                    } else {
                        // Add to already created new trip
                        tripsToSet[newTripIdx].orders = [...tripsToSet[newTripIdx].orders, ord]
                    }
                    tripsToSet[sourceTripIndex].orders = _.filter(tripsToSet[sourceTripIndex].orders, (el, i) => !_.isEqual(el, trips[sourceTripIndex].orders[src.index]))
                } else if(src.droppableId === dest.droppableId) {
                    // Reorder inside same trip
                    tripsToSet[sourceTripIndex].orders = reorder(
                        tripsToSet[sourceTripIndex].orders,
                        src.index,
                        dest.index
                    )
                } else if(_.includes(dest.droppableId, 'plannedOrders')) {
                    // Move to an other trip
                    var tripIndex = parseInt(dest.droppableId.split('_')[1])
                    tripsToSet[tripIndex].orders.splice(dest.index, 0, trips[sourceTripIndex].orders[src.index])
                    tripsToSet[sourceTripIndex].orders = _.filter(tripsToSet[sourceTripIndex].orders, (el, i) => !_.isEqual(el, trips[sourceTripIndex].orders[src.index]))
                } else if(dest.droppableId === 'pendingOrders') {
                    // Move to pending orders
                    pendingOrdersToSet.splice(dest.index, 0, trips[sourceTripIndex].orders[src.index])
                    tripsToSet[sourceTripIndex].orders = _.filter(tripsToSet[sourceTripIndex].orders, (el, i) => !_.isEqual(el, trips[sourceTripIndex].orders[src.index]))
                }
            }
        })
        setTripsAndRemoveEmpties(tripsToSet)
        setPendingOrders(pendingOrdersToSet)
    }

    const onDragEnd = (result) => {
        console.log(selectedBoxes, result)
        executeMovement(selectedBoxes, result)
        setSelectedBoxes([])
    }

    const setTripsAndRemoveEmpties = (newVals, sourceTrip, sourceTripIndex) => {
        var valsToSet = _.filter(newVals, (el) => el.orders?.length > 0)
        setTrips(valsToSet, () => refreshRoutes())
    }

    const onDragStart = (e) => {
        if(!isSelected(e.source.droppableId, e.source.index)) {
            console.log(e.source.droppableId, e.source.index)
            setSelectedBoxes([{ droppableId: e.source.droppableId, index: e.source.index }])
        }
    }

    const handleDeliveryZoneNameChange = (e) => {
        var newDz = {...currDeliveryZone, name: e.target.value}
        var deliveryZonesCopy = _.filter(deliveryZones, (el) => el != currDeliveryZone)
        deliveryZonesCopy.push(newDz)
        setDeliveryZones(deliveryZonesCopy)
        setCurrDeliveryZone(newDz)
        setShouldSaveCurrDeliveryZone(true)
    }

    const handleOnCloseInfoWindow = () => {
        if(currDeliveryZone.id === null) {
            var deliveryZonesCopy = _.filter(deliveryZones, (el) => el.location?.path !== currDeliveryZone.location?.path)
            setDeliveryZones(deliveryZonesCopy)
        }
        setCurrDeliveryZone(null)
    }

    const deleteDeliveryZone = () => {
        if(currDeliveryZone.id === null) {
            handleOnCloseInfoWindow()
        } else {
            deliveryZoneServices.destroyDeliveryZone(currDeliveryZone.id)
                .then(data => {
                    var deliveryZonesCopy = _.filter(deliveryZones, (el) => el.id !== currDeliveryZone.id)
                    setDeliveryZones(deliveryZonesCopy)
                    setCurrDeliveryZone(null)
                })
                .catch((err) => {
                    setCurrDeliveryZone(null)
                });
        }
    }

    const saveDeliveryZone = (dz) => {
        if(shouldSaveCurrDeliveryZone && currDeliveryZone?.id) {
            // Update
            setLoadingZones(true)
            deliveryZoneServices.updateDeliveryZone(currDeliveryZone.id, "include=location", { name: currDeliveryZone.name })
                .then(data => {
                    var deliveryZonesCopy = _.filter(deliveryZones, (el) => el.id !== data.id)
                    deliveryZonesCopy.push(data)
                    setDeliveryZones(deliveryZonesCopy)
                    setLoadingZones(false)
                })
                .catch((err) => {
                    setLoadingZones(false)
                });
        } else if(shouldSaveCurrDeliveryZone) {
            // Create
            var selDZ = currDeliveryZone
            setLoadingZones(true)
            deliveryZoneServices.createDeliveryZone("include=location", { name: currDeliveryZone.name, location: { path: currDeliveryZone.location.path } })
                .then(data => {
                    var deliveryZonesCopy = _.filter(deliveryZones, (el) => el.location.path !== selDZ.location.path)
                    deliveryZonesCopy.push(data)
                    setDeliveryZones(deliveryZonesCopy)
                    setLoadingZones(false)
                })
                .catch((err) => {
                    setLoadingZones(false)
                });
        }
        setCurrDeliveryZone(null)
    }

    const savePlanification = (_tripsIndexes=null, endPlanification = false) => {

        setSaving(true)
        
        if(_tripsIndexes) {
            var _trips = _.filter(trips, (t,i) => (_.includes(_tripsIndexes, i)))
        } else {
            var _trips = [...trips]
        }

        var tripsToSave = [...trips]
        
        planificationServices.savePlanification({ centre_id: currCentre?.id, trips: _trips, end_planification: endPlanification })
            .then(data => {
                setSaving(false)
                var tripsToRemove = []

                if(endPlanification) {
                    // Ending planification
                    _.forOwn(data, (v,k) => {
                        if(v?.saved) {
                            // If any trip was saved, then we remove it from the list
                            tripsToRemove.push(k)
                        }
                    })
                } else {
                    // Saving planification
                    _.forOwn(data, (v,k) => {
                        if(v?.saved && v?.id) {
                            // If any trip was saved, then we remove it from the list
                            tripsToSave[k].id = v?.id
                        }
                    })
                }
                _.each(tripsToRemove.sort().reverse(), (i) => {
                    tripsToSave.splice(i, 1)
                })
                setTrips(tripsToSave)
            })
            .catch((err) => {
                setSaving(false)
            });
    }

    const optimizeTrips = () => {
        setOptimizing(true)
        planificationServices.optimize({ centre_id: currCentre?.id, plugin_id: currOptimizer?.id, start_date: startDate, end_date: endDate })
            .then(data => {
                var existingTripsCount = trips.length
                var _pendingOrders = _.cloneDeep(pendingOrders)
                var newTrips = []

                // Build a list with the movements to be done
                _.each(data, (group, i) => {
                    _.each(group, (oid, j) => {
                        var orderidx = _.findIndex(_pendingOrders, { id: oid })
                        if(orderidx === -1) return
                        newTrips.push({ trip_group: i, order_idx: orderidx, planned_orders_pos: null })
                    })
                })

                // Sort the list by order_idx desc to not mess with the indexes
                newTrips = _.reverse(_.sortBy(newTrips, ['order_idx']))
                var currGroupAssignation = existingTripsCount - 1

                // Execute the movements
                _.each(newTrips, (nt, i) => {
                    if(newTrips[i].planned_orders_pos !== null) {
                        setTimeout(() => {
                            setMoveDestination({ droppableId: `plannedOrders_${newTrips[i].planned_orders_pos}`, index: 0 })
                            setSelectedBoxes([{ droppableId: 'pendingOrders', index: newTrips[i].order_idx }])
                            if(i === newTrips.length - 1) {
                                setOptimizing(false)
                            }
                        }, 300*i)
                    } else {
                        currGroupAssignation += 1
                        newTrips = _.map(newTrips, (nt2, j) => {
                            if(newTrips[i].trip_group === nt2.trip_group) {
                                return { ...nt2, planned_orders_pos: currGroupAssignation }
                            }
                            return nt2
                        })
                        
                        setTimeout(() => {
                            setMoveDestination({ droppableId: `newTrip`, index: 0 })
                            setSelectedBoxes([{ droppableId: 'pendingOrders', index: newTrips[i].order_idx }])
                            if(i === newTrips.length - 1) {
                                setOptimizing(false)
                            }
                        }, 300*i)
                    }
                })
            })
            .catch((err) => {
                setOptimizing(false)
            });
    }

    const renderFilters = () => {
        return (
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{mb: 2}}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={3}>
                        <LocalizationProvider size="small" dateAdapter={AdapterDateFns}>
                            <StyledDatePicker
                                label="Desde"
                                format="dd/MM/yyyy"
                                sx={{ background: 'white' }}
                                value={startDate}
                                maxDate={endDate}
                                closeOnSelect={true}
                                slotProps={{
                                    actionBar: {
                                        actions: ['clear', 'accept'],
                                    },
                                }}
                                onAccept={(newValue) => setStartDate(newValue)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <StyledDatePicker
                                label="Hasta"
                                format="dd/MM/yyyy"
                                sx={{ background: 'white' }}
                                value={endDate}
                                minDate={startDate}
                                closeOnSelect={true}
                                slotProps={{
                                    actionBar: {
                                        actions: ['clear', 'accept'],
                                    },
                                }}
                                onAccept={(newValue) => setEndDate(newValue)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <Autocomplete
                            id="centre-select"
                            size="small"
                            freeSolo
                            sx={{
                                width: 200,
                                boxShadow: (theme) => theme.shadows[25],
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderWidth: 0,
                                }
                            }}
                            options={centres}
                            getOptionLabel={(option) => `${option.code} - ${option.name}`}
                            value={currCentre}
                            onChange={(e, v) => { if(v) { setCurrCentre(v) } }}
                            renderInput={(params) => <TextField {...params} label="Centro" sx={{background: 'white'}} />}
                        />
                        { renderOptimizingOptions() }
                        <Paper
                            sx={{
                                px: 1,
                                boxShadow: (theme) => theme.shadows[25],
                            }}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}>
                                <Typography variant="body2">Opciones</Typography>
                                <Tooltip title={ `${showZones ? 'Ocultar' : 'Mostrar'} zonas de despacho en mapa` }>
                                    <IconButton
                                        value="check"
                                        variant="contained"
                                        color={ showZones ? 'primary' : '#888' }
                                        size="small"
                                        selected={showZones}
                                        onClick={() => { setShowZones(!showZones); }}>
                                        <HexagonOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={ `${showProductDetails ? 'Ocultar' : 'Mostrar'} detalle de productos cuando se posiciona sobre una caja` }>
                                    <IconButton
                                        value="check"
                                        variant="contained"
                                        color={ showProductDetails ? 'primary' : '#888' }
                                        size="small"
                                        selected={showProductDetails}
                                        onClick={() => { setShowProductDetails(!showProductDetails); }}>
                                        <InsertCommentOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={ `${showCustomerLocationOnHover ? 'Ocultar' : 'Mostrar'} ubicación de clientes en mapa cuando se posiciona sobre una caja` }>
                                    <IconButton
                                        value="check"
                                        variant="contained"
                                        color={ showCustomerLocationOnHover ? 'primary' : '#888' }
                                        size="small"
                                        selected={showCustomerLocationOnHover}
                                        onClick={() => { setShowCustomerLocationOnHover(!showCustomerLocationOnHover); }}>
                                        <LocationSearchingOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Paper>
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}>
                        <LoadingButton
                            loading={saving}
                            variant="contained"
                            color="success"
                            size='small'
                            endIcon={<SaveOutlinedIcon />}
                            loadingPosition="end"
                            onClick={() => { savePlanification() }}>
                                { saving ? 'Guardando' : 'Guardar' }
                        </LoadingButton>
                    </Stack>
            </Stack>
        )
    }

    const renderTripsDND = () => {
        return (
            <React.Fragment>
                {_.map(trips, (trip,i) => {
                    var showRoute = _.includes(hiddenTripsRoutes, i) ? false : true
                    return (
                        <OrdersDND
                            droppableId={`plannedOrders_${i}`}
                            title="Ruta"
                            items={trip.orders}
                            leftActions={
                                <React.Fragment>
                                    {pinsColors[i] &&
                                        <Tooltip title={ `${showRoute ? 'Ocultar' : 'Mostrar'} zonas de despacho` }>
                                            <IconButton
                                                value="check"
                                                variant="contained"
                                                size="small"
                                                onClick={() => { showRoute ? setHiddenTripsRoutes([...hiddenTripsRoutes, i]) : setHiddenTripsRoutes(_.without(hiddenTripsRoutes, i)) }}>
                                                <RouteOutlinedIcon sx={{ fontSize: 16, color: showRoute ? pinsColors[i] : '#DDD' }} />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {(routes.length > i && routes[i].error) ? <Typography variant="body2" color="error">{routes[i].error}</Typography> : ((routes.length > i && routes[i].duration) && <Typography variant="body2">{printDuration(routes[i].duration)}</Typography>)}
                                </React.Fragment>
                            }
                            rightActions={null}
                            groupColor={pinsColors[i]}
                            listLoading={listLoading}
                            isPlanification={true}
                            groupNumber={i+1}
                            drivers={drivers}
                            vehicles={vehicles}
                            selShipDate={trip.ship_date ? (new Date(trip.ship_date)) : new Date()}
                            selDriver={_.find(drivers, { id: trip.driver_id })}
                            selVehicle={_.find(vehicles, { id: trip.vehicle_id })}
                            selectedBoxes={selectionsInGroup(`plannedOrders_${i}`)}
                            onShipDateChange={(v) => { var tempTrips = [...trips]; tempTrips[i].ship_date = v; setTrips(tempTrips) }}
                            onSelDriverChange={(e, v) => { var tempTrips = [...trips]; tempTrips[i].driver_id = v?.id; setTrips(tempTrips) }}
                            onSelVehicleChange={(e, v) => { var tempTrips = [...trips]; tempTrips[i].vehicle_id = v?.id; setTrips(tempTrips) }}
                            onItemMouseOver={(e, order) => setItemOnHover({ item: order, kind: 'order', anchorElement: e.currentTarget })}
                            onItemMouseLeave={(e, order) => setItemOnHover(null)}
                            onEndPlanification={(e) => { savePlanification([i], true) }}
                            onToggleSelection={(droppableId, idx) => { toggleSelection(droppableId, idx) }}
                            onToggleSelectionInGroup={(droppableId, idx) => { toggleSelectionInGroup(droppableId, idx) }} />
                    )
                })}
            </React.Fragment>
        )
    }
    // Map pins color palette
    const pinsColors = [
        '#ab47bc', // Púrpura Orquídea
        '#2196f3', // Azul Cobalto
        '#f44336', // Rojo Carmesí
        '#ff9800', // Naranja Mandarina
        '#ec407a', // Rosa Coral
        '#cddc39', // Verde Lima
        '#4caf50', // Verde Esmeralda
        '#ffc107', // Amarillo Ámbar
        '#00bcd4', // Turquesa
        '#4fc3f7', // Azul Cielo
    ];

    const pinsData = _.flatten(_.map(trips, (t, i) => {
        return (
            {
                position: i+1,
                color: pinsColors[i],
                pins: _.map(t.orders, (o, j) => {
                    return (
                        {
                            lat: o.locatable?.location?.lat,
                            lng: o.locatable?.location?.lng,
                            position: j+1,
                            info: `${o.code} - ${o.locatable?.name}`
                        }
                    )
                })
            }
        )
    }))

    const renderOptimizingOptions = () => {
        const routingPlgs = _.filter(authUser?.current_company?.enabled_plugins, (p) => p.category === 'Routing')
        if(routingPlgs.length === 0) return null
        if(!currOptimizer) setCurrOptimizer(routingPlgs[0])

        return(
            <Paper sx={{
                    px: 1,
                    boxShadow: (theme) => theme.shadows[25],
                }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={0.5}>
                    <Typography variant="body2">Optimizar por</Typography>
                    <FormControl
                        // variant="filled"
                        size="small"
                        sx={{
                            minWidth: 120,
                            '& .MuiInputBase-input': {
                                paddingTop: 0.5,
                                paddingBottom: 0.5,
                                color: (theme) => theme.palette.primary.main,
                                borderWidth: 0,
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderWidth: 0,
                            }
                        }}>
                        <Select
                            labelId="optimize-by"
                            id="optimize-by"
                            value={currOptimizer?.id}
                            size="small"
                            autoWidth
                            onChange={(e) => { setCurrOptimizer(_.find(routingPlgs, { id: e.target.value })) }}
                        >
                            {_.map(routingPlgs, (p) => {
                                return (
                                    <MenuItem value={p.id}>{optimizerNames(p.slug)}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <Tooltip title={ `Recalcular` }>
                        <IconButton
                            value="check"
                            variant="contained"
                            color="primary"
                            size="small"
                            disabled={optimizing}
                            onClick={optimizeTrips}>
                            <CalculateOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Paper>
        )
    }

    return (
        <div>
            <Typography variant="h2">Planificación</Typography>
            { renderFilters() }
            { showProductDetails && <OrderDeliveryDetailsPopover item={itemOnHover?.item} anchorElement={itemOnHover?.anchorElement} /> }
            <DragDropContext
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <OrdersDND
                            droppableId="pendingOrders"
                            title={`Por planificar (${pendingOrders.length})`}
                            items={pendingOrders}
                            listLoading={listLoading}
                            isPlanification={false}
                            selectedBoxes={selectionsInGroup('pendingOrders')}
                            showSearch={true}
                            onItemMouseOver={(e, order) => setItemOnHover({ item: order, kind: 'order', anchorElement: e.currentTarget })}
                            onItemMouseLeave={(e, order) => setItemOnHover(null)}
                            onToggleSelection={(droppableId, idx) => { toggleSelection(droppableId, idx) }}
                            onToggleSelectionInGroup={(droppableId, idx) => { toggleSelectionInGroup(droppableId, idx) }} />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <OrdersDND
                            droppableId="newTrip"
                            title="Nueva ruta"
                            isPlanification={false}
                            listLoading={listLoading}
                            newGroupContainer={true} />
                        {renderTripsDND()}
                    </Grid>

                    <Grid item xs={12} md={4} sx={{height: "80vh"}}>
                        <ZonesMapEdit
                            zones={deliveryZones}
                            loading={loadingZones}
                            currentZone={currDeliveryZone}
                            mapCenter={currMapCenter}
                            onCenterChanged={(c) => setCurrMapCenter(c)}
                            onPolygonClick={(dz) => { setCurrDeliveryZone(dz); setDrawingMode({ mode: null, cancel: false })  }}
                            onCloseInfoWindow={handleOnCloseInfoWindow}
                            onSaveZoneClick={saveDeliveryZone}
                            onDeleteZoneClick={deleteDeliveryZone}
                            onZoneNameChange={handleDeliveryZoneNameChange}
                            onOverlayComplete={onOverlayComplete}
                            onDrawCancel={() => { setDrawingMode({ mode: null, cancel: true }) }}
                            routesPins={pinsData}
                            routes={routes}
                            hiddenRoutes={hiddenTripsRoutes}
                            centres={[currDisplayCentre]}
                            showZones={showZones}
                            preview={(itemOnHover && showCustomerLocationOnHover) && { title: itemOnHover.item?.locatable?.name, lat: itemOnHover.item?.locatable?.location?.lat, lng: itemOnHover.item?.locatable?.location?.lng }}
                        />
                    </Grid>
                </Grid>
            </DragDropContext>
        </div>
    );
};

export default PlanificationIndex;