const layoutConfig = {
    sidebar: {
        hide: true,
        fixed: false,
        width: 0,
    },
    content: {
        // sx: {
        //     p: 0,
        //     ot
        //     px: {lg: '50px', xs: '10px'}
        // }
    },
    header: {
        hide: true,
    },
    footer: {
        hide: false,
    }
};

export default layoutConfig;