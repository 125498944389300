import jwtAuthAxios from "./auth/jwtAuth";

const vehicleServices = {};

vehicleServices.getNewVehicle = async (params) => {
    const {data} = await jwtAuthAxios.get(`/vehicles/new${params ?? ""}`);
    return data;
}

vehicleServices.createVehicle = async (params, body) => {
    const {data} = await jwtAuthAxios.post(`/vehicles${params ?? ""}`, body);
    return data;
}

vehicleServices.getVehicles = async (params=null) => {
    const {data} = await jwtAuthAxios.get(`/vehicles${params || ""}`);
    return data;
};

vehicleServices.getVehicle = async (id, params=null) => {
    const {data} = await jwtAuthAxios.get(`/vehicles/${id}${params || ""}`);
    return data;
};

vehicleServices.getEditOptions = async () => {
    const {data} = await jwtAuthAxios.get(`/vehicles/edit_options`);
    return data;
};

vehicleServices.saveVehicle = async (id, params, body) => {
    const {data} = await jwtAuthAxios.put(`/vehicles/${id}${params ?? ""}`, body);
    return data;
}

// vehicleServices.saveCustomerGeofence = async (id, params) => {
//     const {data} = await jwtAuthAxios.post(`/customers/${id}/geofence`, params);
//     return data;
// };

// vehicleServices.getReceiptsPins = async (id) => {
//     const {data} = await jwtAuthAxios.get(`/customers/${id}/receipts_pins`);
//     return data;
// };

export default vehicleServices;