import jwtAuthAxios from "./auth/jwtAuth";

const pluginServices = {};

pluginServices.getPlugins = async (params) => {
    const {data} = await jwtAuthAxios.get(`/plugins${params}`);
    return data;
};

pluginServices.togglePluginInstallation = async (id) => {
    const {data} = await jwtAuthAxios.post(`/plugins/${id}/toggle_installation`);
    return data;
};

pluginServices.togglePluginActivation = async (id) => {
    const {data} = await jwtAuthAxios.post(`/plugins/${id}/toggle_activation`);
    return data;
};

pluginServices.saveConfig = async (id, params) => {
    const {data} = await jwtAuthAxios.post(`/plugins/${id}/set_config`, params);
    return data;
};

export default pluginServices;