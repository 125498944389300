import React from "react";
import Page from "@jumbo/shared/Page";
import TripsIndex from "app/pages/trips/TripsIndex";
import CustomersIndex from "app/pages/customers/CustomersIndex";
import Logs from "app/pages/Logs";
import UsersIndex from "app/pages/users/UsersIndex";
import CompanySettings from "app/pages/companies/CompanySettings";
import OrdersList from "app/pages/orders/OrdersList";
import PlanificationIndex from "app/pages/planification/PlanificationIndex";
import PluginsIndex from "app/pages/plugins/PluginsIndex";
import VehiclesIndex from "app/pages/vehicles/VehiclesIndex";
import SurveysIndex from "app/pages/surveys/SurveysIndex";
import TaskAuthorizationsIndex from "app/pages/task_authorizations/TaskAuthorizationsIndex";
import FileTasksIndex from "app/pages/file_tasks/FileTasksIndex";

const mainRoutes = [
    {
        path: "/",
        element: <Page component={TripsIndex} />
    },
    {
        path: "/company_settings",
        element: <Page component={CompanySettings} />
    },
    {
        path: "/customers",
        element: <Page component={CustomersIndex} />
    },
    {
        path: "/task_authorizations",
        element: <Page component={TaskAuthorizationsIndex} />
    },
    {
        path: "/logs",
        element: <Page component={Logs} />
    },
    {
        path: "/orders",
        element: <Page component={OrdersList} />
    },
    {
        path: "/planification",
        element: <Page component={PlanificationIndex} />
    },
    {
        path: "/plugins",
        element: <Page component={PluginsIndex} />
    },
    {
        path: "/trips",
        element: <Page component={TripsIndex} />
    },
    {
        path: "/users",
        element: <Page component={UsersIndex} />
    },
    {
        path: "/vehicles",
        element: <Page component={VehiclesIndex} />
    },
    {
        path: "/surveys",
        element: <Page component={SurveysIndex} />
    },
    {
        path: "/file_tasks",
        element: <Page component={FileTasksIndex} />
    },
];

export default mainRoutes;
