import { List, ListItem, ListItemText, Popover, Typography } from '@mui/material';
import React, { useState } from 'react';

const OrderDeliveryDetailsPopover = ({item, anchorElement}) => {
    const open = Boolean(anchorElement);

    if(item) {
        return (
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={!!anchorElement}
                anchorEl={anchorElement}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: -15,
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                disableRestoreFocus
            >
                <List sx={{ width: '100%', maxWidth: 240 }} dense={true}>
                    {item.order_details && item.order_details.map((value) => (
                        <ListItem
                            alignItems="flex-start"
                            key={value}
                            secondaryAction={
                                <Typography>{value.pending_qty} {value.meassure_unit}</Typography>
                            }
                        >
                            <ListItemText primary={`${value.product_code} - ${value.product}`} />
                        </ListItem>
                    ))}
                </List>
            </Popover>
        );
    } else {
        return null;
    }
};

export default OrderDeliveryDetailsPopover;